import { useEffect, useState } from "react";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";

import useNotifications from "./useNotifications";

export const useWebSocket = () => {
  const userData = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );
  const token: string | undefined = userData.accessToken;
  const username: string | undefined = userData.username;

  const updateNotifications = useNotifications(token);

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (token) {
      var socket = new SockJS(
        "https://cp.loylabs.ru/api/loyalty-portal/api/stomp"
      );
      const stompClient = Stomp.over(socket);
      stompClient.debug = (f) => f; //disable messages in browser console

      stompClient.connect(
        { Authentication: `Bearer ${token}` },
        function (frame: any) {
          stompClient.subscribe("/topic/status", function (greeting: any) {
            setData(greeting.body);
          });
          stompClient.subscribe(
            `/user/${username}/queue/notifications`,
            updateNotifications
          );
        }
      );

      return () => {
        if (stompClient != null) {
          stompClient.disconnect();
          setData(null);
        }
      };
    }
    /* eslint-disable */
  }, [token]);

  return data;
};

export default useWebSocket;
