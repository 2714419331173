import React, { useContext, useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons'
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper'
import { ReviewContext } from '../RewievContextProvider/ReviewContextProvider'
import { AppContext } from '../../shared/AppContextProvider'
import EditObservers from './EditObservers'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { reviewThunks } from '../../../reducer/review'
import { useSelectedReviewers } from '../../../hooks/useSelectedReviewers'
import { decodeBase64 } from '../utils/decodeId'
import DatePickerCustom from '../../common/Inputs/DatePickerCustom/DatePickerCustom'
import moment from 'moment'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { reviewListSelect } from '../../../reducer/selectors/reviewSelectors'

const styleDatePicker = {
  width: '100%',
  mr: '15px',
  pr: '8px',
  '.MuiButtonBase-root.MuiIconButton-root': {
    p: { mobile: '4px', tablet: '8px' },
  },
}

const EditReviewModal = () => {
  const { isMobile } = useContext(AppContext)
  const reviewList = useAppSelector(reviewListSelect)
  const { handleEditModal, openEditModal, reviewId } = useContext(ReviewContext)

  const currentReview = reviewList.find((el) => el.id === reviewId)

  const [selectedEndReviewDate, setSelectedEndReviewDate] = useState('')
  const { selectedReviewers, votedReviewers, setSelectedReviewers } = useSelectedReviewers()
  const dispatch = useAppDispatch()

  useEffect(() => {
    currentReview && setSelectedEndReviewDate(moment(currentReview.endReview).format('YYYY-MM-DD'))
  }, [currentReview])

  const handleEditReviewSend = () => {
    if (selectedReviewers) {
      const selectedReviewersId = selectedReviewers.map((el) => decodeBase64(el.id))
      const date = moment(selectedEndReviewDate).format('YYYY-MM-DD')
      const body = {
        reviewersId: selectedReviewersId,
        endReview: date,
      }
      dispatch(reviewThunks.editReview({ reviewId, body }))
      handleModal()
    }
  }

  const handleDateChange = (date: string) => {
    setSelectedEndReviewDate(date)
    const isAfterToday = moment(date).isAfter(moment())
    if (!isAfterToday) {
      setSelectedEndReviewDate('Invalid date')
    }
  }

  const handleModal = () => {
    handleEditModal()
  }

  return (
    <DialogWrapper
      openModal={openEditModal}
      handleModal={handleModal}
      width='432px'
      height={!isMobile ? '308px' : undefined}
      buttons={
        <ModalButtons
          justifyContent={'right'}
          handleSend={handleEditReviewSend}
          handleCancel={handleModal}
          disableSend={selectedEndReviewDate === 'Invalid date'}
          nameButtonSend={'Сохранить'}
        />
      }
    >
      <Stack gap={3}>
        <DatePickerCustom
          value={selectedEndReviewDate}
          label={'Изменить дату окончания ревью'}
          onChange={handleDateChange}
          disableFuture={false}
          styles={styleDatePicker}
        />
        <EditObservers
          selectedReviewers={selectedReviewers}
          setSelectedReviewers={setSelectedReviewers}
          votedReviewers={votedReviewers}
        />
      </Stack>
    </DialogWrapper>
  )
}

export default EditReviewModal
