import React, { FC, useState } from 'react'

import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import CustomButton from '../../common/Buttons/CustomButton/CustomButton'
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper'
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons'
import InputLine from '../../common/Inputs/InputLine/InputLine'
import { ShowPasswordAndornment } from '../../common/ShowPasswordAndornment/ShowPasswordAndornment'
import { regEx } from '../../../constants/regEx'
import { personController } from '../../../services/person.controller'
import Snackbars, { SnackBarType } from '../../common/Snackbars/Snackbars'
import { textSnackbars } from '../../../constants/snackBarNotifications'

interface ChangePasswordButtonProps {
  userId: string
}

type ChangePasswordStates = {
  oldPassword: string
  showOldPassword: boolean
  newPassword: string
  showNewPassword: boolean
  repeatPassword: string
  showRepeatPassword: boolean
}

export const ChangePasswordButton: FC<ChangePasswordButtonProps> = ({ userId }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('laptop'))
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleModal = () => {
    setOpenModal(!openModal)
  }

  const [passwords, setPassword] = useState<ChangePasswordStates>({
    oldPassword: '',
    showOldPassword: false,
    newPassword: '',
    showNewPassword: false,
    repeatPassword: '',
    showRepeatPassword: false,
  })

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [snackbarType, setSnackbarType] = useState<SnackBarType>('success')

  const handleChangePasswordState = (item: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    item.onChange()
    setPassword({ ...passwords, [item.keyPasswordName]: event.target.value })
  }

  const handleClickShowPassword = (prop: keyof ChangePasswordStates) => {
    setPassword({
      ...passwords,
      [prop]: !passwords[prop],
    })
  }

  const [verifyOldPassword, setVerifyOldPassword] = useState<boolean>(false)
  const [verifyNewPassword, setVerifyNewPassword] = useState<boolean>(false)
  const [verifyRepeatPassword, setVerifyRepeatPassword] = useState<boolean>(false)

  const newPasswordVerification = () => {
    if (passwords.newPassword !== passwords.repeatPassword && passwords.repeatPassword) {
      return setVerifyRepeatPassword(true)
    }
    return setVerifyRepeatPassword(false)
  }

  const inputsInfo = [
    {
      title: 'Старый пароль',
      placeholder: 'Введите старый пароль',
      password: passwords.oldPassword,
      showPassword: passwords.showOldPassword,
      keyPasswordName: 'oldPassword',
      keyShowPasswordName: 'showOldPassword',
      helperText: 'Неверный пароль',
      error: verifyOldPassword,
      onChange: () => setVerifyOldPassword(false),
    },
    {
      title: 'Новый пароль',
      placeholder: 'Не менее 8 символов',
      password: passwords.newPassword,
      showPassword: passwords.showNewPassword,
      keyPasswordName: 'newPassword',
      keyShowPasswordName: 'showNewPassword',
      helperText:
        'Пароль должен содержать не менее 8-ми символов из каждой категории: верхний регистр, нижний регистр, цифра, знак (-, _, +, =, !)',
      error: verifyNewPassword,
      onBlur: () =>
        !regEx.isComplexPassword.test(passwords.newPassword)
          ? setVerifyNewPassword(true)
          : (newPasswordVerification(), setVerifyNewPassword(false)),
      onChange: () => setVerifyNewPassword(false),
    },
    {
      title: 'Повторите новый пароль',
      placeholder: '',
      password: passwords.repeatPassword,
      showPassword: passwords.showRepeatPassword,
      keyPasswordName: 'repeatPassword',
      keyShowPasswordName: 'showRepeatPassword',
      helperText: 'Пароли не совпадают',
      error: verifyRepeatPassword,
      onBlur: () => newPasswordVerification(),
      onChange: () => setVerifyRepeatPassword(false),
    },
  ]

  const handleCloseModal = () => {
    handleModal()
    setPassword({
      oldPassword: '',
      showOldPassword: false,
      newPassword: '',
      showNewPassword: false,
      repeatPassword: '',
      showRepeatPassword: false,
    })
    setVerifyNewPassword(false)
    setVerifyOldPassword(false)
    setVerifyRepeatPassword(false)
  }

  const handleChangePassword = () => {
    personController
      .password(userId, passwords.newPassword, passwords.oldPassword)
      .then(() => {
        setSnackbarType('success')
        setOpenSnackbar(true)
        handleCloseModal()
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setVerifyOldPassword(true)
        } else {
          setSnackbarType('error')
          setOpenSnackbar(true)
        }
      })
  }

  const buttons = (
    <ModalButtons
      handleCancel={handleCloseModal}
      handleSend={handleChangePassword}
      fullWidth
      nameButtonSend='Применить'
      disableSend={
        !passwords.oldPassword ||
        !passwords.newPassword ||
        !passwords.repeatPassword ||
        verifyOldPassword ||
        verifyNewPassword ||
        verifyRepeatPassword
      }
      justifyContent='flex-end'
    />
  )

  const passInputStyle = {
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus': {
      border: 'none',
      WebkitTextFillColor: 'rgba(230, 237, 245, 1)',
      WebkitBoxShadow: 'none',
      transition: 'background-color 5000s ease-in-out 0s',
      caretColor: 'rgba(230, 237, 245, 1)',
    },
  }

  return (
    <>
      <CustomButton
        fullWidth={isMobile ?? false}
        text='Сменить пароль'
        color='secondary'
        startIcon={<VpnKeyOutlinedIcon />}
        onClick={handleModal}
      />
      <DialogWrapper
        openModal={openModal}
        handleModal={handleCloseModal}
        buttons={buttons}
        contentDividers
        width={'532px'}
      >
        <Typography mb={4} variant='h4'>
          Сменить пароль
        </Typography>
        {inputsInfo.map((item: any, index: number) => (
          <Box key={index} mb={index === 2 ? 2 : 4}>
            <Typography mb={1} variant='body1' color='rgba(229, 235, 242, 0.65)'>
              {item.title}
            </Typography>
            <InputLine
              value={item.password}
              placeholder={item.placeholder}
              onChange={handleChangePasswordState(item)}
              endAdornment={
                <ShowPasswordAndornment
                  showPassword={item.showPassword}
                  handleShowPassword={() => handleClickShowPassword(item.keyShowPasswordName)}
                />
              }
              type={item.showPassword ? 'text' : 'password'}
              error={item.error}
              helperText={item.helperText}
              id={item.keyPasswordName}
              onBlur={item.onBlur}
              styles={passInputStyle}
            />
          </Box>
        ))}
      </DialogWrapper>
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type={snackbarType}
        position='left'
        message={snackbarType === 'success' ? textSnackbars.changePassword : undefined}
      />
    </>
  )
}

export default ChangePasswordButton
