import React, { FC, useContext, useState } from "react";

import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import EditPopover from "../../../common/Modals/EditPopover/EditPopover";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import { AppContext } from "../../../shared/AppContextProvider";
import SectionSelectionModal from "../../SectionSelectionModal/SectionSelectionModal";
import SectionModal from "../../SectionModal/SectionModal";
import CreateArticleModal from "../../CreateArticleModal/CreateArticleModal";
import { roles } from "../../../../constants/role";

export const CreateKnowledgeButton: FC = () => {
  const { isMobile, user } = useContext(AppContext);
  const notUserRole = user.role !== roles.user;

  const wrapperButtonStyle = {
    mt: 4,
    display: "flex",
    justifyContent: "center",
  };

  const button = (
    <Box sx={isMobile ? wrapperButtonStyle : undefined}>
      <CustomButton color="secondary" text="Создать" startIcon={<AddIcon />} />
    </Box>
  );

  const [createArticleModal, setCreateArticleModal] = useState<boolean>(false);
  const handleCreateArticleModal = () => {
    setCreateArticleModal((prev) => !prev);
  };

  const [createSectionModal, setCreateSectionModal] = useState<boolean>(false);
  const handleCreateSectioneModal = () => {
    setCreateSectionModal((prev) => !prev);
  };

  const [editorModal, setEditorModal] = useState<boolean>(false);
  const handleEditorModal = () => {
    setEditorModal((prev) => !prev);
  };

  const popoverButtons = [
    { name: "Статью", action: handleCreateArticleModal },
    { name: "Раздел", action: handleCreateSectioneModal },
  ];

  return (
    <>
      {notUserRole && (
        <EditPopover customButton={button} buttonsData={popoverButtons} />
      )}
      {createArticleModal && (
        <SectionSelectionModal
          open={createArticleModal}
          handleModal={handleCreateArticleModal}
          handleEditorModal={handleEditorModal}
          type="create"
        />
      )}
      {createSectionModal && (
        <SectionModal
          open={createSectionModal}
          handleModal={handleCreateSectioneModal}
          type="create"
        />
      )}
      {editorModal && (
        <CreateArticleModal
          open={editorModal}
          handleModal={handleEditorModal}
          type="create"
        />
      )}
    </>
  );
};

export default CreateKnowledgeButton;
