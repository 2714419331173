import { CommentView } from "../models/comment.models";
import { CommunityRecordView } from "../models/community.models";

export const adaptComments = (
  comments: CommentView[] | CommunityRecordView[]
) => {
  //for correct render in Comment component,
  //determine color for comment and the name of the author is required or not
  return comments
    ?.map((item, key, array) => {
      if (item.author?.id === array[key + 1]?.author?.id) {
        return { ...item, isIdenticalUser: true };
      }
      return item;
    })
    .map((item, key, array) => {
      if (
        (array[key - 1] && !("isIdenticalUser" in array[key - 1])) ||
        key === 0
      ) {
        return { ...item, lastIdenticalMessage: true };
      }
      return item;
    });
};
