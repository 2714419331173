import React, { FC, useState, MouseEvent, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import PopoverWrapper from "../../../common/Modals/PopoverWrapper/PopoverWrapper";
import { AchievContext } from "../../AchievContextProvider/AchievContextProvider";
import ObserversContent from "../../ObserversContent/ObserversContent";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { PersonView } from "../../../../models/profile.models";
import { RecordModificationType } from "../../../../types/RecordModification.type";
import { achievementController } from "../../../../services/achievement.controller";
import { achievementsInitial } from "../../../../actions/achievements";
import { AppContext } from "../../../shared/AppContextProvider";

interface ObserversPopoverProps {
  type: RecordModificationType;
}
export const ObserversPopover: FC<ObserversPopoverProps> = ({ type }) => {
  const dispatch = useDispatch();
  const { isMobile } = useContext(AppContext);

  const isCreate = type === "create";
  const achievement = useAppSelector(
    (state) => state.achievementsReducer.achievement
  );
  const task = useAppSelector(
    (state) => state.achievementsReducer.achievementTask
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "tags-popover" : undefined;

  const { setObserverList, observerList, handleSnackbar, handleTasks } =
    useContext(AchievContext);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (!isCreate) {
      const body = {
        content: null,
        header: null,
        observerIds: observerList.map((user) => user.id),
      };
      achievementController
        .editTask(achievement.id, task.id, body)
        .then((res) => {
          dispatch(achievementsInitial.achievementTask(res.data));
          achievementController
            .tasks(achievement.id)
            .then((res) => handleTasks(res.data.items));
        })
        .catch(() => handleSnackbar("error"));
    }
    setAnchorEl(null);
  };

  const handleList = (checked: boolean, user: PersonView) => {
    if (checked) {
      setObserverList((prev) => [...prev, user]);
    } else {
      setObserverList((prev) => prev.filter((item) => item.id !== user.id));
    }
  };

  useEffect(() => {
    if (isCreate) {
      setObserverList(achievement?.observers);
    } else {
      setObserverList(task?.observers);
    }

    return () => setObserverList([]);
    /* eslint-disable-next-line */
  }, []);

  const cardStyle = {
    width: "307px",
    height: "316px",
    overflowY: "auto",
  };

  return (
    <Box>
      <CustomButton
        text="Изменить наблюдателей"
        color="secondary"
        fullWidth
        onClick={handleClick}
      />
      <PopoverWrapper
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        cardStyle={cardStyle}
        anchorOriginHorizontal={isMobile ? "center" : "left"}
        transformOriginHorizontal={isMobile ? "center" : "left"}
        popoverStyle={{ mt: 1 }}
      >
        <ObserversContent
          type="task"
          handleList={handleList}
          memberInfoSize="compact"
          handleClose={handleClose}
          selectedUsers={isCreate ? achievement?.observers : task.observers}
        />
      </PopoverWrapper>
    </Box>
  );
};

export default ObserversPopover;
