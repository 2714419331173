import React, { FC, useEffect, useState, useContext } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import Stack from "@mui/material/Stack";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import MainInfo from "./MainInfo/MainInfo";
import PersonalData from "./PersonalData/PersonalData";
import Contacts from "./Contacts/Contacts";
import { PersonView } from "../../models/profile.models";
import { profilesInitial } from "../../actions/profiles";
import { personController } from "../../services/person.controller";
import { AppContext } from "../shared/AppContextProvider";
import { LoadProgress } from "../common/LoadProgress/LoadProgress";
import CustomButton from "../common/Buttons/CustomButton/CustomButton";
import Achievements from "../Achievements/Achievements";
import { companyInitialState } from "../Company/CompanyContext/CompanyContextProvider";
import { BackButton } from "../common/Buttons/BackButton/BackButton";
import Review from "../Review/Review";

export const Profile: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const pathParts = path.split("/");
  const userId = pathParts[pathParts.indexOf("profile") + 1];

  const currentUserInfo = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );
  const isCurrentUser = userId === currentUserInfo.id;
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { logout, isMobile } = useContext(AppContext);

  useEffect(() => {
    localStorage.setItem("userId", userId);
    personController.person(userId).then((res) => {
      dispatch(profilesInitial.editProfile(res.data));
      setShowLoader(false);
    });
    /* eslint-disable */
  }, [userId]);

  const userData: PersonView = useAppSelector(
    (state) => state.profileReducer.editProfile
  );

  const handleExitButton = () => {
    logout();
  };

  const handleCancel = () => {
    if (companyInitialState.isForUserEditing) {
      companyInitialState.isPreserved = true;
      companyInitialState.shouldGetData = false;
    }
    navigate(-1);
  };

  useEffect(() => () => {
    companyInitialState.isForUserEditing = false;
  }, []);


  return (
    <>
      {showLoader && <LoadProgress />}
      {!showLoader && (
        <>
          <BackButton
            handleNavigateBack={handleCancel}
            isTopRight={true}
          />
          <MainInfo userInfo={userData} isCurrentUser={isCurrentUser} />
          <Stack spacing={4} mx={isMobile ? 2 : 0} mt={4}>
            <Achievements />
            <Review />
            <PersonalData userInfo={userData} />
            <Contacts userInfo={userData} />
            {isCurrentUser && (
              <CustomButton
                color="secondary"
                fullWidth
                startIcon={<ExitToAppIcon />}
                onClick={handleExitButton}
                text="Выйти из профиля"
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default Profile;
