import React, { FC } from "react";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import AttachmentButton from "../AttachmentButton/AttachmentButton";
import CustomButton from "../CustomButton/CustomButton";

interface ModalButtonsProp {
  handleCancel: () => void;
  handleSend: () => void;
  handleAttache?: (event: any) => void;
  disableSend?: boolean;
  nameButtonSend?: string;
  nameButtonCancel?: string;
  justifyContent?: string;
  fullWidth?: boolean;
  additionalStyles?: any;
}

export const ModalButtons: FC<ModalButtonsProp> = ({
  handleSend,
  handleCancel,
  handleAttache,
  disableSend,
  nameButtonSend = "Отправить",
  nameButtonCancel = "Отмена",
  justifyContent = "space-between",
  fullWidth,
  additionalStyles,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleButton = {
    borderRadius: "60px",
    padding: "12px 24px",
    height: "auto",
  };

  const styleFlex = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  };

  const fullWidthButton = fullWidth ? isMobile ?? false : false;

  return (
    <Box display="flex" justifyContent={justifyContent} width="100%">
      {handleAttache && (
        <AttachmentButton onChange={handleAttache} withBackground />
      )}
      <Box sx={isMobile ? styleFlex : additionalStyles}>
        <CustomButton
          fullWidth={fullWidthButton}
          color="secondary"
          onClick={handleCancel}
          styles={{
            ...styleButton,
            mr: "16px",
          }}
          text={nameButtonCancel}
        />
        <CustomButton
          fullWidth={fullWidthButton}
          color="primary"
          onClick={handleSend}
          styles={styleButton}
          text={nameButtonSend}
          disabled={disableSend}
        />
      </Box>
    </Box>
  );
};

export default ModalButtons;
