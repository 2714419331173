import React, { FC } from "react";

import CalendarContextProvider from "./CalendarContext/CalendarContextProvider";
import CalendarContent from "./CalendarContent/CalendarContent";

export const Calendar: FC = () => {
	return (
		<CalendarContextProvider>
			<CalendarContent />
		</CalendarContextProvider>
	);
};

export default Calendar;