import React, { FC } from "react";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import MemberItem from "../../layout/RightSidebar/Members/MemberItem/MemberItem";
import { PersonView } from "../../../models/profile.models";
import BlockUserButton from "../BlockUserButton/BlockUserButton";

interface UserHeaderProps {
  profileInfo: PersonView;
}
export const UserHeader: FC<UserHeaderProps> = ({ profileInfo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <MemberItem userInfo={profileInfo} withPopover={false} />
      {!isMobile && !profileInfo.blocked && <BlockUserButton userId={profileInfo.id} />}
    </Box>
  );
};

export default UserHeader;
