import { ACHIEVEMENTS } from "../constants/actions";

export const achievementsInitial = {
  achievement: (payload: any) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT, payload };
  },
  achievementTask: (payload: any) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT_TASK, payload };
  },
  achievementsList: (payload: any) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENTS_LIST, payload };
  },
  achievementTags: (payload: any) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT_TAGS, payload };
  },
  achievementComments: (payload: any) => {
    return { type: ACHIEVEMENTS.ACHIEVEMENT_COMMENTS, payload };
  },
};
