import React, { FC } from "react";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

interface BadgeProp {
  label: string | number;
}

export const CustomBadge: FC<BadgeProp> = ({ label }) => {
  const styleBadge = {
    position: "absolute",
    bottom: "8px",
    right: "8px",
    bgcolor: "rgba(19, 23, 34, 1)",
  };

  return (
    <Chip
      label={<Typography variant="caption">{label}</Typography>}
      sx={styleBadge}
    />
  );
};
export default CustomBadge;
