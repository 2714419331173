import React, { FC, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import UserAvatar from "../../common/User/UserAvatar/UserAvatar";
import InputBase from "@mui/material/InputBase";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";

import PreviewFiles from "../../common/Attachments/PreviewFiles/PreviewFiles";
import AttachmentButton from "../../common/Buttons/AttachmentButton/AttachmentButton";
import { secondaryButton } from "../../../constants/buttonStyle";
import { feedController } from "../../../services/feed.controller";
import { dataInitial } from "../../../actions/data";
import Snackbars from "../../common/Snackbars/Snackbars";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import { PersonView } from "../../../models/profile.models";
import { roles } from "../../../constants/role";
import { regEx } from "../../../constants/regEx";

interface CreateFeedMessageProps {
  important: boolean;
}
export const CreateFeedMessage: FC<CreateFeedMessageProps> = ({
  important,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const profile: PersonView = useAppSelector(
    (state) => state.profileReducer.profile
  );
  const [message, setMessage] = useState<string>("");
  const handleMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const adminRole = profile?.role?.role === roles.admin;
  const availabe = important ? adminRole : true;

  const [isButtons, setIsButtons] = useState<boolean>(false);
  const openButton = () => setIsButtons(true);
  const handleCloseButton = () => {
    setIsButtons(false);
    setMessage("");
    setSelectedFiles([]);
  };

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = (event: any) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    event.target.value = "";
  };

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleSentButton = () => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: message,
            important: important,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    [...selectedFiles].forEach((image) => {
      formData.append("files", image);
    });
    feedController
      .newFeed(formData)
      .then(() => {
        feedController.feed(undefined, important).then((result) => {
          dispatch(dataInitial.feed(result.data.items));
        });
        handleCloseButton();
      })
      .catch(() => setOpenSnackbar(true))
      .finally(() => setShowLoader(false));
  };

  const styleActions = {
    borderTop: "1px solid rgba(241, 247, 255, 0.1)",
  };

  const styleButton = {
    borderRadius: "60px",
    padding: isMobile ? "10px 16px" : "12px 24px",
  };

  return (
    <>
      {showLoader && <LoadProgress />}
      {!showLoader && availabe && (
        <Card sx={{ borderRadius: "12px" }}>
          <CardContent
            sx={{
              padding: isMobile ? "16px" : "24px",
              display: "flex",
              gap: "16px",
            }}
          >
            <UserAvatar userInfo={profile} />
            <Box sx={{ width: "100%" }}>
              <InputBase
                fullWidth
                multiline
                placeholder="Что нового?"
                value={message}
                onChange={handleMessage}
                sx={{ mb: 3 }}
                onFocus={openButton}
              />
              {selectedFiles?.length > 0 && (
                <PreviewFiles
                  files={selectedFiles}
                  setFiles={setSelectedFiles}
                  type="upload"
                />
              )}
              {isButtons && (
                <Box
                  mt={selectedFiles.length > 0 ? 3 : undefined}
                  pt={2}
                  display="flex"
                  gap={2}
                  sx={styleActions}
                  justifyContent="space-between"
                >
                  <AttachmentButton
                    onChange={handleAttachmentButton}
                    withBackground
                  />
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={handleCloseButton}
                      sx={{
                        ...styleButton,
                        ...secondaryButton,
                        mr: "16px",
                      }}
                    >
                      Отмена
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={regEx.isEmpty.test(message)}
                      sx={styleButton}
                      size="small"
                      onClick={handleSentButton}
                    >
                      Отправить
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </>
  );
};

export default CreateFeedMessage;
