import React, { FC, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import { filesize } from "filesize";

import { ReactComponent as Pdf } from "../../../../assets/icons/documentRed.svg";
import { ReactComponent as Doc } from "../../../../assets/icons/documentBlue.svg";
import { ReactComponent as Xls } from "../../../../assets/icons/documentGreen.svg";
import { ReactComponent as OtherFile } from "../../../../assets/icons/documentYellow.svg";
import {
  iconButtonBackground,
  iconButton,
} from "../../../../constants/buttonStyle";
import { theme } from "../../../../constants/theme";
import { downloadController } from "../../../../services/download.controller";
import { AttachmentView } from "../../../../models/attachment.model";
import { manageDataInitial } from "../../../../actions/manageData";

const fileIcon = (type: string) => {
  if (type.includes("sheet") || type.includes("xlsx") || type.includes("xls")) {
    return <SvgIcon component={Xls} />;
  }
  if (
    type.includes("msword") ||
    type.includes("docx") ||
    type.includes("doc")
  ) {
    return <SvgIcon component={Doc} />;
  }
  if (type.includes("pdf") || type.includes("image") || type.includes("png")) {
    return <SvgIcon component={Pdf} />;
  }
  return <SvgIcon component={OtherFile} />;
};

interface PreviewFilesProps {
  files: any;
  setFiles?: SetStateAction<any>;
  type: "upload" | "download";
  size?: "compact" | "full";
  saveDeleteId?: boolean;
}
export const PreviewFiles: FC<PreviewFilesProps> = ({
  files,
  setFiles,
  type,
  size = "full",
  saveDeleteId,
}) => {
  const upload = type === "upload";
  const download = type === "download";
  const compactSize = size === "compact";
  const dispatch = useDispatch();
  const deleteFiles = useAppSelector(
    (state) => state.manageDataReducer.deleteFiles
  );

  const handleDeleteButton = (id: number) => (event: any) => {
    setFiles([...files].filter((item: any, index: number) => index !== id));

    if (saveDeleteId) {
      const deleteId = [...files].find(
        (item: any, index: number) => index === id
      ).id;
      dispatch(manageDataInitial.deleteFiles([...deleteFiles, deleteId]));
    }
  };

  const compactText = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "200px",
  };

  const stylePaper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "12px",
    py: "4px",
    borderRadius: compactSize ? "16px" : "12px",
    cursor: download ? "pointer" : undefined,
    boxShadow: "none",
    "&:last-child": {
      mb: "8px",
    },
  };

  const styleIcon = {
    width: compactSize ? "14px" : "16px",
    height: "auto",
    color: "rgba(229, 235, 242, 0.65)",
  };

  const getFile = (
    item: AttachmentView | { name: string; extension: string }
  ) => {
    downloadController.download(item.name + item.extension).then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item.name + item.extension);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    });
  };

  const name = (item: any) => {
    return (
      <>
        <Typography
          variant="body2"
          sx={compactSize ? compactText : { wordBreak: "break-all" }}
        >
          {item.name}
        </Typography>
        {item.size && (
          <Typography variant="caption" color={theme?.palette?.text?.secondary}>
            {
              filesize(item.size, {
                base: 2,
                standard: "jedec",
              }) as string
            }
          </Typography>
        )}
      </>
    );
  };

  return (
    <Stack direction={compactSize ? "row" : "column"} flexWrap="wrap" gap={1}>
      {[...files].map((item: any, index: number) => (
        <Paper
          sx={stylePaper}
          key={index}
          onClick={() => (download ? getFile(item) : undefined)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: compactSize ? "8px" : "10px",
            }}
          >
            {fileIcon(item.type || item.extension)}
            {compactSize ? name(item) : <Box>{name(item)}</Box>}
          </Box>
          {upload && (
            <Box ml={compactSize ? 1 : undefined}>
              <Button
                variant="contained"
                color="secondary"
                aria-label="close"
                component="label"
                sx={{ ...iconButtonBackground, ...iconButton }}
                startIcon={<CloseIcon sx={styleIcon} />}
                onClick={handleDeleteButton(index)}
              />
            </Box>
          )}
        </Paper>
      ))}
    </Stack>
  );
};

export default PreviewFiles;
