import React, { FC, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { MenuListItems } from "../../../../../types/MenuListItems.types";
import { MenuList } from "../../../../common/MenuList/MenuList";
import { theme } from "../../../../../constants/theme";
import { communityController } from "../../../../../services/community.controller";
import { communitiesInitial } from "../../../../../actions/communities";
import { CommunityListView } from "../../../../../models/community.models";
import CreateCommunity from "./../CreateCommunity/CreateCommunity";
import ShowMoreButton from "../../../../KnowledgeBase/ShowMoreButton/ShowMoreButton";
import { AppContext } from "../../../../shared/AppContextProvider";

interface MyCommunitiesProps {
  forRemainingMenu?: boolean;
  communitiesItems: (comm: CommunityListView[]) => MenuListItems;
  onClickCommunity: (id: string) => void;
}

export const MyCommunities: FC<MyCommunitiesProps> = ({
  forRemainingMenu,
  communitiesItems,
  onClickCommunity,
}) => {
  const dispatch = useDispatch();
  const { totalGroups, setTotalGroups, pageGroups, setPageGroups } =
    useContext(AppContext);

  const myCommunities: CommunityListView[] = useAppSelector(
    (state) => state.communitiesReducer.communitiesByPerson
  );

  useEffect(() => {
    if (pageGroups > 0) {
      communityController.communitiesByPerson(pageGroups).then((res) => {
        dispatch(
          communitiesInitial.communitiesByPerson([
            ...myCommunities,
            ...res.data.items,
          ])
        );
        setTotalGroups(res.data.totalItems);
      });
    } else {
      communityController.communitiesByPerson().then((res) => {
        dispatch(communitiesInitial.communitiesByPerson(res.data.items));
        setTotalGroups(res.data.totalItems);
      });
    }
    /* eslint-disable */
  }, [pageGroups]);
  /* eslint-enable */

  useEffect(() => {
    setPageGroups(0);
    communityController.communitiesByPerson().then((res) => {
      dispatch(communitiesInitial.communitiesByPerson(res.data.items));
      setTotalGroups(res.data.totalItems);
    });
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  const showMore = totalGroups > myCommunities.length;

  const getNextCommunities = () => {
    setPageGroups((prev) => prev + 1);
  };

  return (
    <Box>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        mt="20px"
      >
        <Typography
          variant="body1"
          component="span"
          textAlign="center"
          color={theme?.palette?.text?.secondary}
        >
          Мои группы
        </Typography>
        <CreateCommunity />
      </Box>
      <MenuList
        items={communitiesItems(myCommunities)}
        forRemainingMenu={forRemainingMenu ?? false}
        onClick={onClickCommunity}
      />
      {showMore && (
        <ShowMoreButton
          onClick={getNextCommunities}
          style={{ mb: "12px", display: "inline-block" }}
        />
      )}
    </Box>
  );
};

export default MyCommunities;
