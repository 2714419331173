import React, {
  FC,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

import { SnackBarType } from "../../common/Snackbars/Snackbars";
import Snackbars from "../../common/Snackbars/Snackbars";

interface CommunityContextProps {
  children: React.ReactNode;
}

interface CommunityContextValue {
  handleSnackbar: (type: SnackBarType, message?: string) => void;
  newAvatar: File | null;
  setNewAvatar: Dispatch<SetStateAction<File | null>>;
  messagesTotalItems: number;
  setMessagesTotalItems: Dispatch<SetStateAction<number>>;
  membersTotalItems: number;
  setMembersTotalItems: Dispatch<SetStateAction<number>>;
  messagesPage: number;
  setMessagesPage: Dispatch<SetStateAction<number>>;
}

let defaultValue: CommunityContextValue = {
  handleSnackbar: () => {},
  newAvatar: null,
  setNewAvatar: () => {},
  messagesTotalItems: 0,
  setMessagesTotalItems: () => {},
  membersTotalItems: 0,
  setMembersTotalItems: () => {},
  messagesPage: 0,
  setMessagesPage: () => {},
};
export const CommunityContext = createContext(defaultValue);

export const CommunityContextProvider: FC<CommunityContextProps> = ({
  children,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<SnackBarType>("error");
  const [snackbarText, setSnackbarText] = useState<string | undefined>(
    undefined
  );
  const [newAvatar, setNewAvatar] = useState<File | null>(null);

  const handleSnackbar = (type: SnackBarType, message?: string) => {
    setSnackbarType(type);
    setOpenSnackbar(true);
    if (message) {
      setSnackbarText(message);
    } else {
      setSnackbarText(undefined);
    }
  };

  const [messagesTotalItems, setMessagesTotalItems] = useState<number>(0);
  const [membersTotalItems, setMembersTotalItems] = useState<number>(0);

  const [messagesPage, setMessagesPage] = useState<number>(0);

  return (
    <CommunityContext.Provider
      value={{
        handleSnackbar,
        newAvatar,
        setNewAvatar,
        messagesTotalItems,
        setMessagesTotalItems,
        membersTotalItems,
        setMembersTotalItems,
        messagesPage,
        setMessagesPage,
      }}
    >
      {children}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type={snackbarType}
        message={snackbarText}
        position="center"
        sx={{ bottom: "100px" }}
      />
    </CommunityContext.Provider>
  );
};

export default CommunityContextProvider;
