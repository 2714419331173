import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import PageLayout from './components/layout/PageLayout/PageLayout';
import { theme } from './constants/theme';
import AppContextProvider from './components/shared/AppContextProvider';

import './App.css';

export const App = () => {
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContextProvider>
          <PageLayout />
        </AppContextProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
