import React, { FC, useContext, useState } from "react";

import { Typography } from "@mui/material";

import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import CommentTask from "../../common/CommentTask/CommentTask";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import { AppContext } from "../../shared/AppContextProvider";
import { regEx } from "../../../constants/regEx";
import { bugReportController } from "../../../services/bugReport.controller";
import { SnackBarType } from "../../common/Snackbars/Snackbars";

interface BugReportModalProps {
  openModal: boolean;
  handleModal: () => void;
  handleSnackbar: (type: SnackBarType, message?: string) => void;
}
export const BugReportModal: FC<BugReportModalProps> = ({
  openModal,
  handleModal,
  handleSnackbar,
}) => {
  const { isMobile } = useContext(AppContext);
  const [content, setContent] = useState<string>("");
  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = (event: any) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    event.target.value = "";
  };

  const handleCancel = () => {
    setContent("");
    setSelectedFiles([]);
    handleModal();
  };

  const handleSend = () => {
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: content,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    [...selectedFiles].forEach((file) => {
      formData.append("files", file);
    });

    bugReportController
      .send(formData)
      .then((res) => {
        handleSnackbar("success", "Сообщение об ошибке отправлено");
        handleCancel();
      })
      .catch((err) => {
        handleSnackbar("error");
      });
  };

  const buttons = (
    <ModalButtons
      disableSend={regEx.isEmpty.test(content)}
      handleCancel={handleCancel}
      handleSend={handleSend}
      handleAttache={!isMobile ? handleAttachmentButton : undefined}
      fullWidth
    />
  );

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleModal}
      buttons={buttons}
      contentDividers
      width="862px"
    >
      <Typography variant={isMobile ? "h4" : "h3"}>
        Сообщить об ошибке
      </Typography>
      <CommentTask
        comment={content}
        handleComment={handleComment}
        files={selectedFiles}
        setFiles={setSelectedFiles}
        handleAttache={isMobile ? handleAttachmentButton : undefined}
        fileLocation="bottom"
        placeholder="Опишите, что случилось"
      />
    </DialogWrapper>
  );
};

export default BugReportModal;
