import React, { FC } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Stack from "@mui/material/Stack";
import UserAvatar from "../../../common/User/UserAvatar/UserAvatar";
import { PersonView } from "../../../../models/profile.models";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";

interface EditAvatarProp {
  avatarHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  newAvatar: File | null;
}

export const EditAvatar: FC<EditAvatarProp> = ({
  avatarHandler,
  newAvatar,
}) => {
  const userData: PersonView = useAppSelector(
    (state) => state.profileReducer.editProfile
  );

  return (
    <Stack spacing="12px" direction="row">
      <UserAvatar
        withBorder={false}
        userInfo={userData}
        newAvatar={newAvatar}
      />
      <CustomButton
        color="secondary"
        attachButton
        onChange={avatarHandler}
        acceptFile="image/*"
        text="Заменить"
      />
    </Stack>
  );
};

export default EditAvatar;
