export type Story = {
  content: string;
  header: string;
  background: any;
  backgroundURL: string;
  id?: string;
};

export type EditedStoryIds = {
  delete: string[];
  attachment: string[];
};

export const defaultStory = {
  content: "",
  header: "",
  background: undefined,
  backgroundURL: "",
};

export const defaultEditedStoryIds = {
  delete: [],
  attachment: [],
};
