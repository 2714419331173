import {PersonView} from "../../../models/profile.models";
import {decodeBase64} from "./decodeId";
import {personController} from "../../../services/person.controller";

export const mergeReviewerInfo = async (reviewers:PersonView[]) => {
    if (reviewers.length === 1 && reviewers[0].name === null && reviewers[0].surname === null) {
        const decodedId = decodeBase64(reviewers[0].id);
        const reviewerDetails = await personController.person(decodedId);
        return [{
            ...reviewers[0],
            name: reviewerDetails.data.name,
            surname: reviewerDetails.data.surname,
            avatar: reviewerDetails.data.avatar
        }];
    } else {
        return reviewers.map(reviewer => (reviewer));
    }
}