import React, { Dispatch, FC, SetStateAction } from "react";

import Stack from "@mui/material/Stack";
import InputMask from "react-input-mask";

import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../common/Inputs/InputLine/InputLine";
import { regEx } from "../../../constants/regEx";
import { UserInfo } from "../../../types/UserInfo.types";

interface ContactsProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  setIsSaveDisabled: Dispatch<SetStateAction<boolean>>;
}
export const Contacts: FC<ContactsProps> = ({
  personData,
  setPersonData,
  setIsSaveDisabled,
}) => {
  const checkEmail = (email: string) => {
    return !regEx.isEmail.test(email) && email?.length > 0;
  };

  const checkTelegram = (name: string) => {
    return !regEx.isTelegram.test(name) && name?.length > 0;
  };

  const handleChange =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === "telegram") {
        setIsSaveDisabled(checkTelegram(event.target.value));
      }
      if (prop === "email") {
        setIsSaveDisabled(checkEmail(event.target.value));
      }
      setPersonData({ ...personData, [prop]: event.target.value });
    };

  const data = [
    {
      name: "Контактный е-mail",
      element: (
        <InputLine
          value={personData?.email}
          onChange={handleChange("email")}
          placeholder="example@example.com"
          error={checkEmail(personData?.email)}
          helperText={"Введите email в виде - ___@___.__"}
        />
      ),
    },
    {
      name: "Телефон",
      element: (
        <InputMask
          mask="+7\ (999) 999 99 99"
          value={personData?.phone}
          onChange={handleChange("phone")}
        >
          <InputLine placeholder="+7 (___) ___ __ __" />
        </InputMask>
      ),
    },
    {
      name: "WhatsApp",
      element: (
        <InputMask
          mask="+7\ (999) 999 99 99"
          value={personData?.phone}
          onChange={handleChange("phone")}
        >
          <InputLine placeholder="+7 (___) ___ __ __" />
        </InputMask>
      ),
    },
    {
      name: "Telegram",
      element: (
        <InputLine
          value={personData?.telegram}
          onChange={handleChange("telegram")}
          placeholder="@name"
          error={checkTelegram(personData?.telegram || "")}
          helperText={"Введите имя, начиная с @"}
        />
      ),
    },
  ];
  return (
    <Stack spacing={4}>
      {data.map((item, index) => (
        <InputWrapper key={index} title={item.name} element={item.element} />
      ))}
    </Stack>
  );
};

export default Contacts;
