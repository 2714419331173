import React, { FC } from "react";

import AchievContextProvider from "./AchievContextProvider/AchievContextProvider";
import AchievPage from "./AchievPage/AchievPage";

export const Achievements: FC = () => {
  return (
    <AchievContextProvider>
      <AchievPage />
    </AchievContextProvider>
  );
};

export default Achievements;
