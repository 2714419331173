import { useState, useEffect, useContext } from "react";
import { PersonView } from "../models/profile.models";
import { personController } from "../services/person.controller";
import { searchFIO } from "../operation/searchFIO";
import { AchievContext } from "../components/Achievements/AchievContextProvider/AchievContextProvider";
import {useDebounce} from "./useDebounce";

export const useUserList = (excludedIds?: string) => {
  const [users, setUsers] = useState<PersonView[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  const debounceSearch = useDebounce(search)

  const { handleSnackbar } = useContext(AchievContext);
  const currentUserId = localStorage.getItem("userId");
  const exclideCurrentUser = `id!:${currentUserId}`;

  useEffect(() => {
    personController
      .allPersons(
        searchFIO(
          search,
          `blocked:false,${
            search ? exclideCurrentUser : `${excludedIds},${exclideCurrentUser}`
          }`
        ),
        undefined,
        undefined,
        5
      )
      .then((res) => {
        setUsers(res.data.items);
        setTotalItems(res.data.totalItems);
      })
      .catch(() => handleSnackbar("error"));

    if (!search) {
      setPage(0);
    }
    /* eslint-disable-next-line */
  }, [debounceSearch]);

  useEffect(() => {
    if (page) {
      personController
        .allPersons(
          `blocked:false,${excludedIds},${exclideCurrentUser}`,
          undefined,
          page,
          5
        )
        .then((res) => {
          setUsers((prev) => [...prev, ...res.data.items]);
          setTotalItems(res.data.totalItems);
        })
        .catch(() => handleSnackbar("error"));
    }
    /* eslint-disable-next-line */
  }, [page]);

  return { users, totalItems, setPage, search, setSearch };
};
