import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceoptor from "./jwtInterceptor";

export const departmentController = {
  departments: async (page?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/departments?page=${page ?? ""}`
    );
  },
};
