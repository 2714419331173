import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector";

import { achievementController } from "../services/achievement.controller";
import { achievementsInitial } from "../actions/achievements";
import { AchievContext } from "../components/Achievements/AchievContextProvider/AchievContextProvider";
import { AchievementView } from "../models/achievement.models";

export const useAchievementList = ({
  archived,
  updStatus,
}: {
  archived: boolean;
  updStatus: () => void;
}) => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const [isLoadList, setIsLoadList] = useState<boolean>(false);
  const [isAddLoadList, setIsAddLoadList] = useState<boolean>(false);

  const { handleSnackbar, setListSize, listPage } = useContext(AchievContext);

  const achievs = useAppSelector(
    (state) => state.achievementsReducer.achievementsList
  );

  useEffect(() => {
    updStatus();
    /* eslint-disable-next-line */
  }, [userId]);

  const getData = (
    finishLoading: () => void,
    storeData: (data: AchievementView[]) => void
  ) => {
    achievementController
      .byUser(userId, archived, listPage)
      .then((res) => {
        storeData(res.data.items);
        setListSize(res.data.totalItems);
      })
      .catch(() => handleSnackbar("error"))
      .finally(() => finishLoading());
  };

  useEffect(() => {
    const finishLoading = () => setIsLoadList(false);
    const storeData = (data: AchievementView[]) =>
      dispatch(achievementsInitial.achievementsList(data));

    setIsLoadList(true);
    getData(finishLoading, storeData);
    /* eslint-disable-next-line */
  }, [userId, archived]);

  useEffect(() => {
    if (listPage) {
      const finishLoading = () => setIsAddLoadList(false);
      const storeData = (data: AchievementView[]) =>
        dispatch(achievementsInitial.achievementsList([...achievs, ...data]));

      setIsAddLoadList(true);
      getData(finishLoading, storeData);
    }
    /* eslint-disable-next-line */
  }, [listPage]);

  return { isLoadList, isAddLoadList };
};
