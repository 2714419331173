import React, { FC, useState } from "react";

import { Box } from "@mui/material";

import CustomButton from "../common/Buttons/CustomButton/CustomButton";
import BugReportIcon from "@mui/icons-material/BugReport";
import BugReportModal from "./BugReportModal/BugReportModal";
import { snackbarFunction } from "../../operation/snackbarFunction";
import Snackbars, { SnackBarType } from "../common/Snackbars/Snackbars";

interface BugReportProp {
  type: "mobile" | "desktop";
}
export const BugReport: FC<BugReportProp> = ({ type }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<SnackBarType>("error");
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(
    undefined
  );

  const handleSnackbar = (type: SnackBarType, message?: string) => {
    snackbarFunction(
      setSnackbarOpen,
      type,
      setSnackbarType,
      message,
      setSnackbarMessage
    );
  };

  const buttonWrapper =
    type === "desktop"
      ? {
          position: "fixed",
          bottom: 0,
          left: 0,
          padding: "24px",
          zIndex: 11,
        }
      : undefined;

  return (
    <>
      <Box sx={buttonWrapper}>
        <CustomButton
          color="secondary"
          text="Сообщить об ошибке"
          startIcon={<BugReportIcon />}
          fullWidth
          onClick={handleModal}
        />
      </Box>
      {openModal && (
        <BugReportModal
          openModal={openModal}
          handleModal={handleModal}
          handleSnackbar={handleSnackbar}
        />
      )}
      <Snackbars
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        position="center"
      />
    </>
  );
};

export default BugReport;
