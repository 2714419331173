import { PROFILES } from "../constants/actions";

export const profilesInitial = {
  profile: (payload: any) => {
    return { type: PROFILES.PROFILE, payload };
  },
  filteredProfiles: (payload: any) => {
    return { type: PROFILES.FILTERED_PROFILES, payload };
  },
  editProfile: (payload: any) => {
    return { type: PROFILES.EDIT_PROFILE, payload };
  },
  allProfiles: (payload: any) => {
    return { type: PROFILES.ALL_PROFILES, payload };
  },
  totalCount: (payload: any) => {
    return { type: PROFILES.TOTAL_COUNTS, payload } },
};
