import React, { FC, useState, useContext } from "react";

import { IconButton } from "@mui/material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

import CustomButton from "../../../../common/Buttons/CustomButton/CustomButton";
import PopoverWrapper from "../../../../common/Modals/PopoverWrapper/PopoverWrapper";
import { AchievContext } from "../../../AchievContextProvider/AchievContextProvider";
import EditableContent from "./EditableContent/EditableContent";
import { AppContext } from "../../../../shared/AppContextProvider";

interface EditableTagPopoverProps {
  type: "create" | "edit";
  onClick?: () => void;
}

export const EditableTagPopover: FC<EditableTagPopoverProps> = ({
  type,
  onClick,
}) => {
  const { tagCompRef } = useContext(AchievContext);
  const { isMobile } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "editable-tags-popover" : undefined;

  const handleClick = () => {
    setAnchorEl(tagCompRef.current);
    onClick?.();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cardStyle = {
    width: "307px",
    height: "auto",
  };

  return (
    <div>
      {type === "create" ? (
        <CustomButton
          text="Создать новую"
          color="secondary"
          fullWidth
          onClick={handleClick}
        />
      ) : (
        <IconButton size="small" color="secondary" onClick={handleClick}>
          <ModeEditOutlinedIcon />
        </IconButton>
      )}
      <PopoverWrapper
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        cardStyle={cardStyle}
        anchorOriginHorizontal={isMobile ? "center" : "left"}
        transformOriginHorizontal={isMobile ? "center" : "left"}
        popoverStyle={{ mt: 1 }}
      >
        <EditableContent closePopover={handleClose} type={type} />
      </PopoverWrapper>
    </div>
  );
};

export default EditableTagPopover;
