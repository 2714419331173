import React, { FC, useState, SetStateAction, Dispatch } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";

import UserAvatar from "../../../common/User/UserAvatar/UserAvatar";
import PreviewFiles from "../../../common/Attachments/PreviewFiles/PreviewFiles";
import AttachmentButton from "../../../common/Buttons/AttachmentButton/AttachmentButton";
import { secondaryButton } from "../../../../constants/buttonStyle";

import type { PersonView } from "../../../../models/profile.models";

interface CreateIdeaCommentProps {
  textMessage: string;
  setTextMessage: Dispatch<SetStateAction<string>>;
  handleSend: () => void;
  handleAttach: (props?: any) => void;
  handleCancel: () => void;
  selectedFiles: any[];
  setSelectedFiles: Dispatch<SetStateAction<any[]>>;
}
export const CreateIdeaComment: FC<CreateIdeaCommentProps> = ({
  textMessage,
  setTextMessage,
  handleSend,
  handleAttach,
  handleCancel,
  selectedFiles,
  setSelectedFiles,
}) => {
  const profile: PersonView = useAppSelector(
    (state) => state.profileReducer.profile
  );

  const handleMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextMessage(event.target.value);
  };

  const [isInputOpened, setIsInputOpened] = useState<boolean>(false);
  const openInput = () => setIsInputOpened(true);
  const closeInput = () => {
    setIsInputOpened(false);
    handleCancel();
  };

  const buttonStyle = {
    borderRadius: "60px",
    padding: "12px 24px",
    typography: "body1",
    "@media (max-width: 370px)": {
      padding: "12px",
    },
  };

  return (
    <Card sx={{ backgroundColor: "transparent", m: "32px 16px" }} elevation={0}>
      <CardContent
        sx={{
          display: "flex",
          gap: "16px",
          p: "0px",
          "&:last-child": {
            pb: "0px",
          },
        }}
      >
        <UserAvatar userInfo={profile} styles={{ width: "44px", height: "44px" }}/>
        <Box sx={{ width: "100%" }}>
          <InputBase
            fullWidth
            multiline
            placeholder="Комментарий к идее"
            value={textMessage}
            onChange={handleMessage}
            sx={{
              backgroundColor: "rgba(241, 247, 255, 0.1)",
              minHeight: "44px",
              borderRadius: "24px",
              p: "12px 16px",
              mb: selectedFiles.length > 0 ? "10px" : "0px",
            }}
            onFocus={openInput}
          />
          {selectedFiles.length > 0 && (
            <PreviewFiles
              files={selectedFiles}
              setFiles={setSelectedFiles}
              type="upload"
            />
          )}
          {isInputOpened && (
            <Box
              mt={selectedFiles.length > 0 ? 1 : undefined}
              pt="16px"
              display="flex"
              gap="8px"
              justifyContent="space-between"
            >
              <AttachmentButton
                onChange={handleAttach}
                withBackground
                padding="10px"
              />
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={closeInput}
                  sx={{
                    ...buttonStyle,
                    ...secondaryButton,
                    mr: { mobile: "8px", tablet: "16px" },
                  }}
                >
                  Отмена
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={textMessage ? false : true}
                  sx={buttonStyle}
                  size="small"
                  onClick={handleSend}
                >
                  Отправить
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CreateIdeaComment;