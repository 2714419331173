import React, { FC, SetStateAction } from "react";

import Stack from "@mui/material/Stack";

import DialogWrapper from "../../DialogWrapper/DialogWrapper";
import CustomButton from "../../../Buttons/CustomButton/CustomButton";

interface MobilePopupProps {
  popoverButtons: React.ReactElement;
  openModal: boolean;
  setOpenModal: SetStateAction<any>;
}

export const MobilePopup: FC<MobilePopupProps> = ({
  popoverButtons,
  openModal,
  setOpenModal,
}) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  const modalStyles = { top: "auto" };

  const modalButtons: React.ReactNode = (
    <CustomButton
      color="secondary"
      fullWidth
      onClick={handleClose}
      text="Отмена"
    />
  );

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleClose}
      width="532px"
      buttons={modalButtons}
      styles={modalStyles}
      stylesContent={{ p: "16px 0 0 0" }}
    >
      <Stack>{popoverButtons}</Stack>
    </DialogWrapper>
  );
};
