import React, { FC, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useLocation } from "react-router";

import Box from "@mui/material/Box";

import BottomActions from "../../common/BottomActions/BottomActions";
import Snackbars from "../../common/Snackbars/Snackbars";
import TextMessageInput from "../../common/comments/TextMessageInput/TextMessageInput";
import CommunityHeader from "../CommunityHeader/CommunityHeader";
import Messages from "../Messages/Messages";
import { communityController } from "../../../services/community.controller";
import { communitiesInitial } from "../../../actions/communities";
import { CommunityContext } from "../CommunityContextProvider/CommunityContextProvider";
import { AppContext } from "../../shared/AppContextProvider";
import { CommunityView } from "../../../models/community.models";

export const CommunityContent: FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useContext(AppContext);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const pathId = useLocation().pathname.split("/")[2];
  pathId && localStorage.setItem("groupId", pathId);
  const communityId = localStorage.getItem("groupId") || "";
  const community: CommunityView = useAppSelector(
    (state) => state.communitiesReducer.community
  );

  const [newComment, setNewComment] = useState<string>("");

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = async (event: any) => {
    setSelectedFiles([...event.target.files]);
    event.target.value = "";
  };

  const { setMessagesTotalItems, setMessagesPage } =
    useContext(CommunityContext);

  const postComment = (func?: () => void) => {
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: newComment,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    [...selectedFiles].forEach((image) => {
      formData.append("files", image);
    });
    communityController
      .newMessage(communityId, formData)
      .then(() => {
        communityController.messages(communityId).then((result) => {
          dispatch(communitiesInitial.messages(result.data.items));
          setMessagesTotalItems(result.data.totalItems);
          setMessagesPage(1);
        });
        setNewComment("");
        func?.();
        document.getElementById("main-scroll")?.scroll(0, 0);
      })
      .catch(() => setOpenSnackbar(true));
  };

  return (
    <Box sx={{ px: isMobile ? 2 : 0 }}>
      <CommunityHeader />
      <Messages />
      {community.role && (
        <BottomActions>
          <TextMessageInput
            textMessage={newComment}
            setTextMessage={setNewComment}
            handleSent={postComment}
            handleAttach={(e) => handleAttachmentButton(e)}
            files={selectedFiles}
            setFiles={setSelectedFiles}
          />
        </BottomActions>
      )}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </Box>
  );
};

export default CommunityContent;
