import React, { useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ReviewModal from './ReviewModal/ReviewModal'
import { ReviewContext } from './RewievContextProvider/ReviewContextProvider'
import RatingModal from './GradeModal/RatingModal'
import { useAppSelector } from '../../hooks/useAppSelector'
import { isReviewInitializedSelect, reviewListSelect } from '../../reducer/selectors/reviewSelectors'
import ReviewCardWrapper from './ReviewCard/ReviewCardWrapper'
import { LoadProgress } from '../common/LoadProgress/LoadProgress'
import { CreateReview } from './ReviewCard/CreateReview'
import { reviewThunks } from '../../reducer/review'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useLocation } from 'react-router'
import { getReviewId } from './utils/getReviewId'
import ApprovedModal from './GradeModal/ApprovedModal'

const styleBoxTitleWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
}

const ReviewPage = () => {
  const {
    openReviewModal,
    openRatingModal,
    handleReviewModal,
    handleRatingModal,
    userId,
    setReviewIdFromNotification,
  } = useContext(ReviewContext)

  const showLoader = useAppSelector(isReviewInitializedSelect)
  const reviewList = useAppSelector(reviewListSelect)

  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(reviewThunks.getFetchedReviewList(userId))
      .unwrap()
      .catch((e) => console.log(e))
    dispatch(reviewThunks.getFetchedSkills())
  }, [userId])

  useEffect(() => {
    setReviewIdFromNotification('')
    setReviewIdFromNotification(getReviewId(location.pathname))
  }, [location])

  const handleModal = () => {
    handleReviewModal()
  }

  return (
    <>
      <Box>
        <Box sx={styleBoxTitleWrapper}>
          <Typography variant='h4' mb={2}>
            Ревью
          </Typography>
          {reviewList.length !== 0 && <CreateReview />}
        </Box>
        {showLoader && <LoadProgress />}
        {!showLoader && (
          <Box mt={2}>
            <ReviewCardWrapper reviews={reviewList} />
          </Box>
        )}
      </Box>
      <ReviewModal openModal={openReviewModal} handleModal={handleModal} />
      <RatingModal handleModal={handleRatingModal} openModal={openRatingModal} />
      <ApprovedModal />
    </>
  )
}

export default ReviewPage
