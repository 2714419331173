import React, { FC, useContext, useState } from "react";

import { Box } from "@mui/material";

import ExpandMore from "../../../common/ExpandMore/ExpandMore";
import EditPopover, {
  buttonInfo,
} from "../../../common/Modals/EditPopover/EditPopover";
import Delete from "../../../../assets/icons/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/Done";
import { AchievContext } from "../../AchievContextProvider/AchievContextProvider";
import { achievementController } from "../../../../services/achievement.controller";
import AchievModal from "../../AchievPage/AchievModal/AchievModal";
import ConfirmModal from "../../../common/Modals/ConfirmModal/ConfirmModal";

interface AchievCardProps {
  achivementId: string;
  expanded: boolean;
  handleExpanded: () => void;
  status: string;
  name: string;
  allTasksCompleted: boolean;
}
export const CardActions: FC<AchievCardProps> = ({
  achivementId,
  expanded,
  handleExpanded,
  status,
  name,
  allTasksCompleted,
}) => {
  const { editableAcces, updAchievemnts, handleSnackbar } =
    useContext(AchievContext);
  const userId = localStorage.getItem("userId") || "";
  const archived = status === "ARCHIVED";

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const [editModal, setEditModal] = useState<boolean>(false);
  const handleEditModal = () => {
    setEditModal(!editModal);
  };

  const handleActionWrapper = (promise: Promise<any>) => {
    promise
      .then(() => updAchievemnts(userId, archived))
      .catch(() => handleSnackbar("error"));
  };

  const confirmAchiev = () => handleConfirmModal();
  const editAchiev = (e: any) => {
    handleEditModal();
  };
  const deleteAchiev = () =>
    handleActionWrapper(achievementController.delete(achivementId));
  const backToWorkAchiev = () =>
    handleActionWrapper(achievementController.reassign(achivementId));

  const handleConfirmAchiev = () => {
    handleActionWrapper(achievementController.confirm(achivementId));
    handleConfirmModal();
  };

  const buttonsData = {
    done: {
      name: "Завершить цель",
      action: confirmAchiev,
      startIcon: <DoneIcon />,
    },
    edit: {
      name: "Редактировать",
      action: editAchiev,
      startIcon: <EditOutlinedIcon />,
    },
    delete: {
      name: "Удалить цель",
      action: deleteAchiev,
      startIcon: <Delete color="secondary" />,
    },
    backToWork: {
      name: "Вернуть в работу",
      action: backToWorkAchiev,
      startIcon: <DoneIcon />,
    },
  };
  const actionButtons: buttonInfo[] = archived
    ? [buttonsData.backToWork, buttonsData.delete]
    : status === "DONE"
    ? [buttonsData.backToWork, buttonsData.edit, buttonsData.delete]
    : [buttonsData.done, buttonsData.edit, buttonsData.delete];

  return (
    <>
      <Box display="flex">
        {editableAcces && <EditPopover buttonsData={actionButtons} />}
        <ExpandMore expand={expanded} onClick={handleExpanded} />
      </Box>
      <AchievModal
        type="edit"
        id={achivementId}
        name={name}
        openModal={editModal}
        handleModal={handleEditModal}
      />
      <ConfirmModal
        nameConfirmButton="Завершить"
        openConfirmModal={confirmModal}
        text={
          allTasksCompleted
            ? "Цель будет завершена."
            : "Некоторые задачи ещё не выполнены."
        }
        title="Завершить цель"
        handleModal={handleConfirmModal}
        handleConfirm={handleConfirmAchiev}
      />
    </>
  );
};
