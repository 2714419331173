import React, { FC } from "react";

import Slider from "@mui/material/Slider";
import { useAppSelector } from "../../../../hooks/useAppSelector";

export const ProgressSlider: FC = () => {
  const achievement = useAppSelector(
    (state) => state.achievementsReducer.achievement
  );

  const componentsProps = {
    mark: {
      sx: {
        display: "none",
      },
    },
    thumb: {
      sx: {
        display: "none",
      },
    },
    rail: {
      sx: {
        height: "8px",
        background:
          "linear-gradient(0.25turn,rgba(0, 74, 160, 1),rgba(51, 146, 255, 1))",
      },
    },
    track: {
      sx: {
        background:
          "linear-gradient(0.25turn,rgba(0, 74, 160, 1),rgba(51, 146, 255, 1))",
        border: "none",
        height: "8px",
      },
    },
  };

  return (
    <Slider
      aria-label={achievement.name}
      value={achievement.countOfDoneTasks + 1}
      step={1}
      marks
      min={1}
      max={achievement.tasksSize + 1}
      disableSwap
      sx={{ cursor: "auto", p: "0 !important" }}
      componentsProps={componentsProps}
    />
  );
};

export default ProgressSlider;
