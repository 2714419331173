import React, { FC, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { LocalizationProvider, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CalendarPicker, CalendarPickerProps } from "@mui/x-date-pickers/CalendarPicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

import moment from "moment";
import "moment/locale/ru";

import CalendarButtons from "../CalendarButtons/CalendarButtons";
import CalendarEventCreator from "../CalendarEventCreator/CalendarEventCreator";
import CalendarDay from "../CalendarDay/CalendarDay";
import { calendarController } from "../../../services/calendar.controller";
import { calendarInitial } from "../../../actions/calendar";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";
import CalendarPopup from "../CalendarPopup/CalendarPopup";
import { useAppSelector } from "../../../hooks/useAppSelector";

const CustomCalendarPicker = styled(CalendarPicker) <CalendarPickerProps<any>>`
	margin: 0;
	width: 100%;
`;
export const CalendarContent: FC = () => {
	const [date, setDate] = useState<moment.Moment>(moment());
	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

	const { setYear, onCalendarError: handleError } = useContext(CalendarContext);

	const getCalendarEvents = () => {
		calendarController
			.allEvents()
			.then(res => {
				dispatch(
					calendarInitial.allEvents(res.data)
				);
			})
			.catch(() => {
				handleError();
			});
	};

	useEffect(() => {
		getCalendarEvents();
		/* eslint-disable */
	}, []);

	const [isEventDialogOpen, setIsEventDialogOpen] = useState<boolean>(false);
	const closeNotificationEventDialog = () => {
		setIsEventDialogOpen(false);
		window.history.replaceState(null, "", `/calendar/${date.format("DD-MM-YYYY")}`);
	};
	const eventId = window.location.search.slice(7);

	const dayEvents = useAppSelector(
		(state) => state.calendarReducer.allEvents[date.format("DD-MM-YYYY")]
	);
	const dayEvent = dayEvents?.find(
		(calendarEvent: any) => calendarEvent.id === eventId
	);

	useEffect(() => {
		if (eventId) {
			setDate(moment(window.location.pathname.split("/")[2], "DD-MM-YYYY"));
			setIsEventDialogOpen(true);
		}
	}, [eventId]);

	const calendarStyles = {
		px: { mobile: "0px", laptop: "16px" },
		".MuiPickersCalendarHeader-label": {
			fontSize: isMobile ? "24px" : "32px",
			lineHeight: isMobile ? "28px" : "40px",
			fontWeight: "700",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			"&:first-letter": {
				textTransform: "uppercase",
			},
		},
		".MuiPickersFadeTransitionGroup-root": {
			minWidth: "100px",
			cursor: "default",
		},
		".MuiDayPicker-weekContainer": {
			margin: "0px",
		},
		".MuiPickersCalendarHeader-root": {
			width: "100%",
			gap: { mobile: "0px", laptop: "14px" },
			p: { mobile: "16px", laptop: "0px" },
		},
		".MuiButtonBase-root": {
			width: "20px",
			borderRadius: 500,
			height: "20px",
			margin: "10px",
		},
		".MuiTypography-root": {
			width: "100%",
		},
		".MuiPickersArrowSwitcher-spacer": {
			width: { mobile: "0px", laptop: "8px" },
		},
		".MuiPickersArrowSwitcher-button": {
			width: { mobile: "32px", laptop: "40px" },
			height: { mobile: "32px", laptop: "40px" },
			borderRadius: "20px",
			margin: "0px",
			padding: "0px",
		},
		".MuiContainer-root": {
			height: "100px",
			width: "100%",
			padding: "0px",
			border: "solid 1px rgba(241, 247, 255, 0.12)",
		},
		".MuiCalendarPicker-root": {
			maxHeight: "unset",
			mb: { mobile: "17px", laptop: "-42px" },
		},
		".MuiDayPicker-monthContainer": {
			position: "unset",
			height: "600px",
		},
		".MuiDayPicker-slideTransition": {
			overflow: "hidden",
		},
		".MuiDayPicker-weekDayLabel:nth-of-type(1)": {
			"&::after": {
				content: "'н'",
				display: "inline-block",
			},
		},
		".MuiDayPicker-weekDayLabel:nth-of-type(2)": {
			"&::after": {
				content: "'т'",
				display: "inline-block",
			},
		},
		".MuiDayPicker-weekDayLabel:nth-of-type(3)": {
			"&::after": {
				content: "'р'",
				display: "inline-block",
			},
		},
		".MuiDayPicker-weekDayLabel:nth-of-type(4)": {
			"&::after": {
				content: "'т'",
				display: "inline-block",
			},
		},
		".MuiDayPicker-weekDayLabel:nth-of-type(5)": {
			"&::after": {
				content: "'т'",
				display: "inline-block",
			},
		},
		".MuiDayPicker-weekDayLabel:nth-of-type(6)": {
			"&::after": {
				content: "'б'",
				display: "inline-block",
			},
		},
		".MuiDayPicker-weekDayLabel:nth-of-type(7)": {
			"&::after": {
				content: "'с'",
				display: "inline-block",
			},
		},
	};

	const customDayRenderer = (
		date: any,
		selectedDays: any[],
		pickersDayProps: PickersDayProps<any>,
	) => {
		return (
			<CalendarDay key={date} pickersDayProps={pickersDayProps} date={date.format("DD-MM-YYYY")} />
		);
	};

	return (
		<>
			<CalendarButtons />
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Box sx={calendarStyles} display="flex" justifyContent="space-between">
					<CustomCalendarPicker
						views={["day"]}
						date={date}
						onChange={dateValue => {
							window.history.replaceState(null, "", `/calendar/${dateValue.format("DD-MM-YYYY")}`);
							setDate(dateValue);
						}}
						onMonthChange={date => setYear(Math.min(date.year(), moment().year() + 1))}
						showDaysOutsideCurrentMonth
						renderDay={customDayRenderer}
					/>
				</Box>
			</LocalizationProvider>
			<Dialog
				open={isEventDialogOpen}
				onClose={closeNotificationEventDialog}
				fullScreen={isMobile}
			>
				<CalendarPopup calendarDayEvents={dayEvent ? [dayEvent] : []} onClose={closeNotificationEventDialog} />
			</Dialog>
			<CalendarEventCreator selectedDate={date.format("DD-MM-YYYY")} />
		</>
	);
};

export default CalendarContent;