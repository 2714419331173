import { SOCKETS } from "../constants/actions";
import { ReturnActionsTypes } from "../store";
import { socketsInitial } from "../actions/sockets";

type SocketsInfo = {
  loggedUsers: string;
};

const initialState: SocketsInfo = { loggedUsers: "" };

export type socketsType = ReturnActionsTypes<typeof socketsInitial>;

export const socketsReducer = (state = initialState, action: socketsType) => {
  switch (action.type) {
    case SOCKETS.LOGGED_USERS:
      return { ...state, loggedUsers: action.payload };
    default:
      return state;
  }
};
