import React, { FC, useEffect } from "react";
import { useLocation } from "react-router";

interface ScrollToTopProp {
  children: React.ReactNode;
  scrollRef: React.MutableRefObject<HTMLElement | null>;
}
export const ScrollToTop: FC<ScrollToTopProp> = ({ children, scrollRef }) => {
  const location = useLocation();

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [location, scrollRef]);

  return <>{children}</>;
};

export default ScrollToTop;
