import React, { FC, useContext, useState, useRef } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";

import { AppContext } from "../../../shared/AppContextProvider";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import DialogWrapper from "../../../common/Modals/DialogWrapper/DialogWrapper";
import Snackbars from "../../../common/Snackbars/Snackbars";

import { ReactComponent as MailIcon } from "../../../../assets/icons/mail.svg";
import { ReactComponent as TelegramIcon } from "../../../../assets/icons/telegram.svg";
import { ReactComponent as VkIcon } from "../../../../assets/icons/vk.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/link.svg";

import type { Idea as IdeaView } from "../../../../types/Idea.type";

type buttonInfo = {
  Icon: any;
  name: string;
  action: (title: string, text?: string) => void;
};

interface ShareIdeaPopoverProps {
  ideaData: IdeaView;
  button: React.ReactElement;
}

export const ShareIdeaPopover: FC<ShareIdeaPopoverProps> = ({
  ideaData,
  button,
}) => {
  const shareIdea = (text: string) => {
    const sharedData = {
      title: "Хочу поделиться идеей",
      text,
      url: window.location.href,
    };
    if (navigator.share) {
      navigator.share(sharedData)
        .catch(() => {
          onIdeaError();
        });
    } else {
      window.open(
        `mailto:?subject=${encodeURIComponent(sharedData.title)}&body=${encodeURIComponent(text)}:
        ${encodeURIComponent(sharedData.url)}`,
        "_self",
      );
    }
  };

  const copyIdeaLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(window.location.href).then(
        () => {
          onCopyLinkSuccess();
        },
        () => {
          onIdeaError();
        },
      );
    } else {
      snackbarParams.current = {
        type: "info",
        message: "Скопируйте URL идеи",
      };
      setOpenSnackbar(true);
    }
  };

  const buttonsData: buttonInfo[] = [
    {
      Icon: MailIcon,
      name: "Почта",
      action: shareIdea,
    },
    {
      Icon: TelegramIcon,
      name: "Telegram",
      action: (text: string) => {
        window.open(
          `https://telegram.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(text)}`,
          "_blank",
        );
      },
    },
    {
      Icon: VkIcon,
      name: "VK",
      action: (text: string) => {
        window.open(
          `https://vk.com/share.php?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(text)}`,
          "_blank",
        );
      },
    },
    {
      Icon: LinkIcon,
      name: "Ссылка",
      action: copyIdeaLink,
    },
  ];

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const snackbarParams = useRef<any>();
  const onIdeaError = () => {
    snackbarParams.current = {
      type: "error",
      message: "Поделиться идеей не удалось",
    };
    setOpenSnackbar(true);
  };
  const onCopyLinkSuccess = () => {
    snackbarParams.current = {
      type: "success",
      message: "Ссылка на идею скопирована",
    };
    setOpenSnackbar(true);
  };

  const { isMobile } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const isPopoverOpen = Boolean(anchorEl);
  const id = isPopoverOpen ? "share-idea-popover" : undefined;

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    isMobile ? setIsModalOpen(true) : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setAnchorEl(null);
  };

  const modifiedCustomButton = button ? (
    React.cloneElement(button, {
        onClick: handleClick,
      })
  ) : null;

  const desktopButtonStyle = {
    width: "48px",
    height: "48px",
    padding: "0px",
    margin: "0px",
    minWidth: "0px",
    borderRadius: "24px",
    backgroundColor: "rgba(51, 146, 255, 0.25)",
  };

  const mobileButtonStyle = {
    gap: "16px",
    justifyContent: "start",
    py: "16px",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    color: "rgba(230, 237, 245, 1)",
    borderBottom: "1px solid rgba(241, 247, 255, 0.12)",
    "&:last-child": { borderBottom: "none" },
  };

  const popoverButtons = buttonsData.map(button => (
    <Button
      key={button.name}
      sx={isMobile ? mobileButtonStyle : desktopButtonStyle}
      onClick={() => {
        button.action(ideaData.header);
        handleClose();
      }}
    >
      {<button.Icon />}{isMobile && button.name}
    </Button>
  ));

  const modalButtons: React.ReactNode = (
    <CustomButton
      color="secondary"
      fullWidth
      onClick={handleClose}
      text="Отмена"
    />
  );

  return (
    <>
      {modifiedCustomButton}
      {/* Desktop popover */}
      <Popover
        id={id}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            display: "flex",
            backgroundColor: "rgba(33, 38, 51, 1)",
            borderRadius: "12px",
            padding: "12px",
            gap: "8px",
            mt: "10px",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset",
            backdropFilter: "blur(12.5px)",
          },
        }}
      >
        {popoverButtons}
      </Popover>
      {/* Mobile modal */}
      <DialogWrapper
        openModal={isModalOpen}
        handleModal={handleClose}
        width="532px"
        buttons={modalButtons}
        styles={{ top: "auto" }}
        stylesContent={{ p: "0px 16px 32px" }}
      >
        <Stack>{popoverButtons}</Stack>
      </DialogWrapper>
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type={snackbarParams.current?.type}
        position="center"
        message={snackbarParams.current?.message}
      />
    </>
  );
};

export default ShareIdeaPopover;