import { apiUrlPortal } from "../constants/apiUrl";
import {
  DeleteAttachmentsRequest,
  UpdateRecordRequest,
} from "../models/record.models";
import jwtInterceoptor from "./jwtInterceptor";

export const ideasController = {
  ideas: async (
    page?: number,
    filter?: string,
    pageSize?: number
  ): Promise<any> => {
    return filter !== "FAVORITE" ? (
      await jwtInterceoptor.get(
        `${apiUrlPortal}/ideas?page=${page ?? 0}&search=status:${filter ?? " "}&size=${pageSize ?? 9}&sort=date,desc`
      )
    ) : (
      await jwtInterceoptor.get(
        `${apiUrlPortal}/ideas/favorite?page=${page ?? 0}&size=${pageSize ?? 9}&sort=date,desc`
      )
    );
  },
  newIdea: async (body: any): Promise<any> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/ideas`, body);
  },
  idea: async (id: string) => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/ideas/${id}`);
  },
  ideaComments: async (id: string, page?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/ideas/${id}/comments?sort=date,desc&page=${page ?? 0}`
    );
  },
  newIdeaComment: async (id: string, body: any): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/ideas/${id}/comments`,
      body
    );
  },
  acceptIdea: async (id: string, body: any): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/ideas/${id}/accept`,
      body
    );
  },
  rejectIdea: async (id: string, body: any): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/ideas/${id}/reject`,
      body
    );
  },
  deleteIdea: async (id: string) => {
    return await jwtInterceoptor.delete(`${apiUrlPortal}/ideas/${id}`);
  },
  editIdea: async (id: string, body: any) => {
    return await jwtInterceoptor.put(`${apiUrlPortal}/ideas/${id}`, body);
  },
  addIdeaAttachments: async (id: string, body: any): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/ideas/${id}/attachments`,
      body
    );
  },
  deleteIdeaAttachments: async (id: string, body: any) => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/ideas/${id}/attachments`,
      body
    );
  },
  editIdeaComment: async (
    ideaId: string,
    commentId: string,
    body: UpdateRecordRequest
  ) => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/ideas/${ideaId}/comments/${commentId}`,
      body
    );
  },
  deleteIdeaComment: async (ideaId: string, commentId: string) => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/ideas/${ideaId}/comments/${commentId}`
    );
  },
  addIdeaCommentAttachments: async (
    ideaId: string,
    commentId: string,
    body: FormData
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/ideas/${ideaId}/comments/${commentId}/attachments`,
      body
    );
  },
  deleteIdeaCommentAttachments: async (
    ideaId: string,
    commentId: string,
    body: DeleteAttachmentsRequest
  ) => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/ideas/${ideaId}/comments/${commentId}/attachments`,
      { data: body }
    );
  },
  markIdea: async (id: string) => {
    return await jwtInterceoptor.put(`${apiUrlPortal}/ideas/${id}/favorite/mark`);
  },
  unmarkIdea: async (id: string) => {
    return await jwtInterceoptor.put(`${apiUrlPortal}/ideas/${id}/favorite/unmark`);
  },
};
