import React, { FC, useState } from "react";

import CheckboxMember from "../../../common/CheckboxMember/CheckboxMember";
import { PersonView } from "../../../../models/profile.models";
import { MemberItemSize } from "../../../../types/UserInfo.types";

interface ObserverProps {
  user: PersonView;
  handleList?: (checked: boolean, userId: PersonView) => void;
  memberInfoSize?: MemberItemSize;
  isChecked?: boolean;
}

export const Observer: FC<ObserverProps> = ({
  user,
  handleList,
  memberInfoSize,
  isChecked,
}) => {
  const [checked, setChecked] = useState<boolean>(isChecked ?? false);

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    userInfo: PersonView
  ) => {
    const value = event.target.checked;
    setChecked(value);
    handleList?.(value, userInfo);
  };

  return (
    <CheckboxMember
      userInfo={user}
      checked={checked}
      handleCheckbox={(e) => handleCheckbox(e, user)}
      memberInfoSize={memberInfoSize}
    />
  );
};

export default Observer;
