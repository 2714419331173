import React, { FC, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import UserAvatar from "../../common/User/UserAvatar/UserAvatar";
import bg from "../../../assets/images/backgroudDefault.png";
import { PersonView } from "../../../models/profile.models";
import { personController } from "../../../services/person.controller";
import { profilesInitial } from "../../../actions/profiles";
import Snackbars from "../../common/Snackbars/Snackbars";
import ImageButton from "../../common/Buttons/ImageButton/ImageButton";
import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import { AppContext } from "../../shared/AppContextProvider";
import { roles } from "../../../constants/role";
import RemoveImageButton from "../../common/Buttons/RemoveImageButton/RemoveImageButton";
import ConfirmModal from "../../common/Modals/ConfirmModal/ConfirmModal";

interface MainInfoProps {
  userInfo: PersonView;
  isCurrentUser: boolean;
}
export const MainInfo: FC<MainInfoProps> = ({ userInfo, isCurrentUser }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const dispatch = useDispatch();

  const { user } = useContext(AppContext);
  const isNotUserRole = user.role !== roles.user;

  const handleEditButton = () => {
    localStorage.setItem("userId", userInfo.id);
    navigate("/profile/edit");
  };

  const styleCardContent = {
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    width: "100%",
  };

  const imageButtonsWrapperStyle = {
    p: "7px",
    position: "absolute",
    top: "12px",
    right: "12px",
    display: "flex",
    gap: "6px",
  };

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const handleBackgroundButton = (event: any) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    personController
      .background(localStorage.getItem("userId"), formData)
      .then(() => {
        personController
          .person(localStorage.getItem("userId"))
          .then((result) => {
            dispatch(profilesInitial.editProfile(result.data));
          });
        event.target.value = "";
      })
      .catch(() => setOpenSnackbar(true));
  };

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setIsConfirmModalOpened(prev => !prev);
  };
  const removeBackground = () => {
    personController
      .removeBackground(localStorage.getItem("userId"))
      .then(() => (
        personController
          .person(localStorage.getItem("userId"))
          .then((result) => {
            dispatch(profilesInitial.editProfile(result.data));
            handleConfirmModal();
          })
      ))
      .catch(() => {
        setOpenSnackbar(true);
      });
  };

  const imgSrc = userInfo.background
    ? `/loyalty-portal/api/attachments/${encodeURIComponent(
      userInfo.background.name
    )}${userInfo.background.extension}`
    : bg;

  const bgStyle = {
    width: "100%",
    height: isMobile ? 396 : 320,
    position: "relative",
    backgroundImage: `linear-gradient(to bottom, rgba(19, 23, 34, 0.15) 0%, rgba(19, 23, 34, 0.75) 100%), url("${imgSrc}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <Card sx={{ position: "relative", borderRadius: isMobile ? 0 : "16px" }}>
      <CardMedia component="div" sx={bgStyle} />
      <CardContent sx={styleCardContent}>
        <UserAvatar userInfo={userInfo} />
        <Typography variant="h4" mt={3} mb={1}>
          {userInfo?.name + " " + userInfo?.surname}
        </Typography>
        <Typography variant="body2" color={theme?.palette?.text?.secondary}>
          {userInfo?.job?.title.name}
        </Typography>
        <Typography variant="body2" color={theme?.palette?.text?.secondary}>
          {userInfo?.city?.name}
        </Typography>
        {isCurrentUser && (
          <CustomButton
            color="secondary"
            onClick={handleEditButton}
            text="Редактировать"
            styles={{ mt: 3 }}
          />
        )}
      </CardContent>
      <Box sx={imageButtonsWrapperStyle}>
        {(isCurrentUser || isNotUserRole) && (
          <>
            <ImageButton
              color="secondary"
              onChange={handleBackgroundButton}
            />
            <RemoveImageButton
              color="secondary"
              onClick={handleConfirmModal}
              disabled={!userInfo.background}
            />
          </>
        )}
      </Box>
      <ConfirmModal
        openConfirmModal={isConfirmModalOpened}
        handleModal={handleConfirmModal}
        nameConfirmButton="Удалить"
        handleConfirm={removeBackground}
        text="Обои профиля будут удалены."
      />
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </Card>
  );
};

export default MainInfo;
