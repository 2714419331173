import React, { FC, SetStateAction, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import ListItem from "@mui/material/ListItem";

import { FeedRecordView } from "../../../models/feed.model";
import FeedMessage from "../FeedMessage/FeedMessage";
import CreateFeedMessage from "../CreateFeedMessage/CreateFeedMessage";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import Snackbars from "../../common/Snackbars/Snackbars";
import { reactionController } from "../../../services/reaction.controller";
import { dataInitial } from "../../../actions/data";
import { feedInitialState } from "../Feed";

interface FeedPageProps {
  getData: () => void;
  totalRecords: number;
  isFetching: boolean;
  setIsFetching: SetStateAction<any>;
  tabIndex: number;
  showLoader: boolean;
  updateFeed: () => void;
}

export const FeedPage: FC<FeedPageProps> = ({
  getData,
  totalRecords,
  isFetching,
  setIsFetching,
  tabIndex,
  showLoader,
  updateFeed,
}) => {
  const styleItem = {
    flexDirection: "column",
    padding: "24px 0",
    gap: "24px",
  };

  const [showAdditionalLoader, setShowAdditionalLoader] =
    useState<boolean>(false);

  const feedRecords: FeedRecordView[] = useAppSelector(
    (state) => state.dataReducer.feed
  );

  const important = tabIndex === 0;

  useEffect(() => {
    if (feedInitialState.shouldGetData) {
      getData();
    } else {
      feedInitialState.shouldGetData = true;
      const elementToScrollTo = document.getElementById(feedInitialState.idToScrollTo);
      setTimeout(() => {
        elementToScrollTo?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    }
    /* eslint-disable-next-line */
  }, [isFetching, tabIndex]);

  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const handleError = () => setOpenSnackbar(true);

  useEffect(() => {
    reactionController
      .smiles()
      .then((res) => dispatch(dataInitial.smiles(res.data.items)))
      .catch(() => handleError());
    /* eslint-disable-next-line */
  }, []);

  const scrollHandler = (e: any) => {
    if (
      e.target.scrollHeight -
        (e.target.scrollTop + window.innerHeight) <
        100 &&
      feedRecords.length < totalRecords
    ) {
      setIsFetching(true);
      setShowAdditionalLoader(true);
    }
  };

  useEffect(() => {
    document.getElementById("main-scroll")?.addEventListener("scroll", scrollHandler);
    return function () {
      document.getElementById("main-scroll")?.removeEventListener("scroll", scrollHandler);
      setShowAdditionalLoader(false);
    };
    /* eslint-disable-next-line */
  }, [feedRecords.length, totalRecords]);

  return (
    <>
      <CreateFeedMessage important={important} />
      {showLoader && <LoadProgress />}
      {!showLoader && (
        <ListItem sx={styleItem}>
          {feedRecords.map((item, index) => (
            <FeedMessage
              messageData={item}
              key={index}
              withButton
              handleError={handleError}
              updateFeed={updateFeed}
            />
          ))}
        </ListItem>
      )}
      {showAdditionalLoader && <LoadProgress />}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </>
  );
};

export default FeedPage;
