export const translateTaskStatus = (status: string) => {
  switch (status) {
    case "Ожидает":
      return (status = "PENDING");
    case "В работе":
      return (status = "IN_PROGRESS");
    case "На проверке":
      return (status = "NEED_REVIEW");
    case "Готово":
      return (status = "DONE");
    case "PENDING":
      return (status = "Ожидает");
    case "IN_PROGRESS":
      return (status = "В работе");
    case "NEED_REVIEW":
      return (status = "На проверке");
    case "DONE":
      return (status = "Готово");
    default:
      return status;
  }
};
