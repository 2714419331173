import React, { FC, SetStateAction } from "react";

import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";
import { LoginInput } from "../LoginInput/LoginInput";
import { ShowPasswordAndornment } from "../../common/ShowPasswordAndornment/ShowPasswordAndornment";

interface PasswordProps {
  value: string;
  setValue: SetStateAction<any>;
  showPassword: boolean;
  setShowPassword: SetStateAction<any>;
  label: string;
  withHelpText?: boolean;
  id?: string;
  error?: boolean;
  helperText?: React.ReactNode;
}

export const Password: FC<PasswordProps> = ({
  value,
  setValue,
  showPassword,
  setShowPassword,
  label,
  withHelpText,
  id = "password",
  error,
  helperText,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const endAdornment: React.ReactNode = (
    <ShowPasswordAndornment
      showPassword={showPassword}
      handleShowPassword={handleClickShowPassword}
    />
  );

  return (
    <LoginInput
      type={showPassword ? "text" : "password"}
      htmlFor={id}
      label={label}
      value={value}
      handleChange={handleChange}
      endAdornment={endAdornment}
      helpText={
        withHelpText ? (
          <ForgotPasswordModal />
        ) : helperText ? (
          helperText
        ) : undefined
      }
      error={error}
    />
  );
};
