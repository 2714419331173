import React, { FC } from "react";

import Box from "@mui/material/Box";

interface StoryGroupWrapperProps {
  colorBorder: "primary" | "secondary";
  children: React.ReactNode;
  onClick?: () => void;
}

export const StoryGroupWrapper: FC<StoryGroupWrapperProps> = ({
  colorBorder,
  children,
  onClick,
}) => {
  const primaryColor = colorBorder === "primary";

  const style = {
    border: primaryColor
      ? "2px solid rgba(24, 132, 255, 1)"
      : "2px solid rgba(51, 146, 255, 0.4)",
    borderRadius: "12px",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  };
  return (
    <Box sx={style} onClick={onClick}>
      {children}
    </Box>
  );
};

export default StoryGroupWrapper;
