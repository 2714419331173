import React, { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useAppSelector";

import { Box, Tab, Tabs } from "@mui/material";
import List from "@mui/material/List";

import { dataInitial } from "../../actions/data";
import { feedController } from "../../services/feed.controller";
import FeedPage from "./FeedPage/FeedPage";
import { FeedRecordView } from "../../models/feed.model";
import Stories from "./Stories/Stories";

export const feedInitialState = {
  isPreserved: false,
  shouldGetData: true,
  idToScrollTo: "",
  tabIndex: 0,
  totalRecords: 0,
  currentPage: 0,
};

export const Feed: FC = () => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState<number>(
    feedInitialState.isPreserved ? feedInitialState.tabIndex : 0
  );

  const feedRecordsData: FeedRecordView[] = useAppSelector(
    (state) => state.dataReducer.feed
  );
  const feedRecords: FeedRecordView[] =
    feedInitialState.isPreserved ? feedRecordsData : [];
  const [totalRecords, setTotalRecords] = useState<number>(
    feedInitialState.isPreserved ? feedInitialState.totalRecords : 0
  );
  const [currentPage, setCurrentPage] = useState<number>(
    feedInitialState.isPreserved ? feedInitialState.currentPage : 0
  );
  const [isFetching, setIsFetching] = useState<boolean>(
    !feedInitialState.isPreserved
  );
  const [showLoader, setShowLoader] = useState<boolean>(
    !feedInitialState.isPreserved
  );

  const handleTabChange = (event: any, newTabIndex: number) => {
    setTabIndex(newTabIndex);
    setIsFetching(!isFetching);
    dispatch(dataInitial.feed([]));
    setTotalRecords(0);
    setCurrentPage(0);
    setShowLoader(true);
  };

  const getFeedRecords = () => {
    if (isFetching) {
      feedController
        .feed(currentPage, tabIndex === 0)
        .then((res) => {
          dispatch(dataInitial.feed([...feedRecords, ...res.data.items]));
          setCurrentPage((prevState) => prevState + 1);
          setTotalRecords(res.data.totalItems);
          setShowLoader(false);
        })
        .finally(() => {
          setIsFetching(false);
          setShowLoader(false);
        });
    }
  };

  const updateFeedRecords = () => {
    feedController.feed(0, tabIndex === 0, currentPage * 10).then((res) => {
      dispatch(dataInitial.feed(res.data.items));
      setTotalRecords(res.data.totalItems);
    });
  };

  useEffect(() => () => {
    feedInitialState.tabIndex = tabIndex;
    feedInitialState.totalRecords = totalRecords;
    feedInitialState.currentPage = currentPage;
  }, [tabIndex, totalRecords, currentPage]);

  useEffect(() => {
    feedInitialState.isPreserved = true;
    return () => {
      feedInitialState.isPreserved = false;
    };
  }, []);

  return (
    <Box>
      <Box>
        <Stories />
      </Box>
      <Box mt="12px">
        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Важное" />
          <Tab label="Общение" />
        </Tabs>
      </Box>
      <Box pt={3}>
        <List sx={{ padding: 0 }}>
          <FeedPage
            getData={getFeedRecords}
            totalRecords={totalRecords}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            tabIndex={tabIndex}
            showLoader={showLoader}
            updateFeed={updateFeedRecords}
          />
        </List>
      </Box>
    </Box>
  );
};

export default Feed;
