import React, { FC, SetStateAction } from 'react'

import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { textSnackbars } from '../../../constants/snackBarNotifications'

export type SnackBarType = 'success' | 'error' | 'info'
type closeSnackBar = (value: boolean) => void
interface SnackbarsProps {
  open: boolean
  setOpen: SetStateAction<any> | closeSnackBar
  message?: string
  type: SnackBarType
  position: 'center' | 'left'
  sx?: any
}

export const Snackbars: FC<SnackbarsProps> = ({
  open,
  setOpen,
  message = textSnackbars.default,
  type,
  position,
  sx,
}) => {
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const action = (
    <IconButton aria-label='close' color='inherit' onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  )

  const snackbarStyle = {
    '.MuiPaper-root': {
      gap: '8px',
      alignItems: 'start',
      borderRadius: '12px',
      padding: '12px',
      flexWrap: 'nowrap',
      margin: '8px',
      flexGrow: 0,
      color: 'rgba(230, 237, 245, 1)',
      backgroundColor:
        type === 'success'
          ? 'rgba(55, 189, 93, 1)'
          : type === 'error'
            ? 'rgba(224, 63, 72, 1)'
            : 'rgba(24, 132, 255, 1)',
      '.MuiSnackbarContent-message': {
        padding: '0px 0px 2px',
        alignSelf: 'center',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
      },
      '.MuiSnackbarContent-action': {
        width: '24px',
        height: '24px',
        margin: '0px',
        padding: '0px',
        button: {
          margin: '0px',
          padding: '0px',
          svg: {
            width: '24px',
            height: '24px',
          },
        },
      },
    },
    ...sx,
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      action={action}
      message={message}
      anchorOrigin={{ vertical: 'bottom', horizontal: position }}
      sx={snackbarStyle}
    />
  )
}

export default Snackbars
