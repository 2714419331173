import React, { FC, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";

import UserInfo from "../../../../common/User/UserInfo/UserInfo";
import MemberPopover from "../MemberPopover/MemberPopover";
import { PersonView } from "../../../../../models/profile.models";
import { PersonCommunityView } from "../../../../../models/community.models";
import { MemberItemSize } from "../../../../../types/UserInfo.types";

interface MemberItemProps {
  userInfo: PersonView | PersonCommunityView;
  isJoy?: boolean;
  withPopover?: boolean;
  size?: MemberItemSize;
}

export const MemberItem: FC<MemberItemProps> = ({
  userInfo,
  isJoy,
  withPopover = true,
  size = "regular",
}) => {
  const navigate = useNavigate();
  const [openedPopover, setOpenedPopover] = useState<boolean>(false);
  const popoverAnchor = useRef<HTMLElement | null>(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const styleHover = {
    "&:hover": {
      backgroundColor: "rgba(185, 215, 255, 0.08)",
      borderRadius: "8px",
      cursor: !isJoy ? "pointer" : undefined,
    },
  };

  const handleClickUser = () => {
    localStorage.setItem("userId", userInfo.id);
    navigate(`/profile/${userInfo.id}`);
  };

  const isPersonView = (userInfo: any): userInfo is PersonView => {
    return userInfo && userInfo.hasOwnProperty("contact");
  };

  return (
    <div>
      <Box
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={withPopover ? popoverEnter : undefined}
        onMouseLeave={withPopover ? popoverLeave : undefined}
        sx={withPopover ? styleHover : undefined}
        p={size === "regular" ? 1 : 0}
        onClick={isJoy || !withPopover ? undefined : handleClickUser}
      >
        <UserInfo withEllipsis={false} userInfo={userInfo} />
      </Box>
      {withPopover && isPersonView(userInfo) && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={popoverLeave}
          disableRestoreFocus
          disableScrollLock
          PaperProps={{
            sx: { borderRadius: "12px", pointerEvents: "auto" },
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          }}
        >
          <MemberPopover userInfo={userInfo} isJoy={isJoy ?? false} />
        </Popover>
      )}
    </div>
  );
};

export default MemberItem;
