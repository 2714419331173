import React, { FC, SetStateAction, useState } from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

import DatePickerCustom from "../../../common/Inputs/DatePickerCustom/DatePickerCustom";
import InputWrapper from "../../../common/Inputs/InputWrapper/InputWrapper";
import Roles from "../../../common/Roles/Roles";
import { UserFilter } from "../../../../types/UserFilters.types";
import { useDepartments } from "../../../../hooks/useDepartments";
import CustomSelect from "../../../common/Inputs/CustomSelect/CustomSelect";

interface FiltersModalContentProps {
  filtersValue: UserFilter;
  setFiltersValue: SetStateAction<any>;
}
export const FiltersModalContent: FC<FiltersModalContentProps> = ({
  filtersValue,
  setFiltersValue,
}) => {
  const theme = useTheme();

  const handleChangeDate = (prop: keyof UserFilter) => (newValue: any) => {
    setFiltersValue((prevState: UserFilter) => ({
      ...prevState,
      [prop]: moment(newValue).format("YYYY-MM-DD"),
    }));
  };

  const [pageDepartment, setPageDepartment] = useState<number>(0);
  const departments = useDepartments(pageDepartment);
  const handleScrollDepartments = (event: any) => {
    const target = event.target;
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight &&
      departments.departments.length < departments.size
    ) {
      setPageDepartment((prev) => prev + 1);
    }
  };

  const assignNewDepart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltersValue((prev: UserFilter) => ({
      ...prev,
      department: departments.departmentsMap[event.target.value],
    }));
  };

  const filters = [
    {
      name: "Дата найма",
      element: (
        <Box display="flex" gap={2} width="100%">
          <DatePickerCustom
            value={filtersValue.dateFrom}
            onChange={handleChangeDate("dateFrom")}
            placeholder="с"
          />
          <DatePickerCustom
            value={filtersValue.dateTo}
            onChange={handleChangeDate("dateTo")}
            placeholder="по"
          />
        </Box>
      ),
    },
    {
      name: "Департамент",
      element: (
        <CustomSelect
          items={departments.departments}
          value={departments.departmentsMap[
            filtersValue?.department
          ] ?? ""}
          onChange={assignNewDepart}
          placeholder="Выберите департамент"
          onScroll={handleScrollDepartments}
        />
      ),
    },
    {
      name: "Роли",
      element: (
        <Roles
          personData={filtersValue}
          setPersonData={setFiltersValue}
          disabled={false}
        />
      ),
    },
  ];

  const wrapperStyle = {
    "& > .MuiTypography-root": {
      color: theme.palette.text.secondary,
    },
  };

  return (
    <>
      <Typography variant="h4" mb={3}>
        Фильтры
      </Typography>
      <Stack spacing={4}>
        {filters.map((item, index) => (
          <InputWrapper
            key={index}
            title={item.name}
            element={item.element}
            flexDirection="column"
            styles={wrapperStyle}
          />
        ))}
      </Stack>
    </>
  );
};

export default FiltersModalContent;
