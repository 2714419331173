import React, {
  FC,
  SetStateAction,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import Search from "../../common/Search/Search";
import { ReactComponent as FilterNotEmpty } from "../../../assets/icons/filterNotEmpty.svg";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import FiltersModalContent from "./FiltersModalContent/FiltersModalContent";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import {
  UserFilter,
  initialFiltersValue,
} from "../../../types/UserFilters.types";
import { CompanyContext } from "../CompanyContext/CompanyContextProvider";
import { personController } from "../../../services/person.controller";
import { profilesInitial } from "../../../actions/profiles";
import { searchFIO } from "../../../operation/searchFIO";
import {sanitizeAndTrimStart} from "../../../operation/sanitizeAndTrimStart";

interface CompanySearchProps {
  filtersValue: UserFilter;
  setFiltersValue: SetStateAction<any>;
  setSearchFilters: SetStateAction<any>;
}

export const CompanySearch: FC<CompanySearchProps> = ({
  filtersValue,
  setFiltersValue,
  setSearchFilters,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const { searchStatus, companyInitialState } = useContext(CompanyContext);

  const isEmptyFilters =
    !filtersValue.dateFrom &&
    !filtersValue.dateTo &&
    !filtersValue.department &&
    !filtersValue.role;

  const endAdornment: React.ReactNode = (
    <InputAdornment position="end" sx={{ height: "auto" }}>
      <IconButton
        aria-label="toggle filter"
        onClick={handleModal}
        color="secondary"
        edge="end"
        disableRipple
      >
        {isEmptyFilters ? <Filter /> : <FilterNotEmpty />}
      </IconButton>
    </InputAdornment>
  );

  const handleCancel = () => {
    handleModal();
    setFiltersValue(initialFiltersValue);
    setSearchFilters("");
  };

  const handleSend = () => {
    setSearchFilters(
      `${filtersValue.dateFrom ? `,hired>:${filtersValue.dateFrom}` : ""}${
        filtersValue.dateTo ? `,hired<:${filtersValue.dateTo}` : ""
      }${filtersValue.role ? `,role.role:${filtersValue.role}` : ""}${
        filtersValue.department
          ? `,job.department.id:${filtersValue.department}`
          : ""
      }`
    );
    handleModal();
  };

  const modalButtons: React.ReactNode = (
    <ModalButtons
      nameButtonSend="Применить"
      handleSend={handleSend}
      handleCancel={handleCancel}
      justifyContent="end"
      disableSend={isEmptyFilters}
    />
  );

  const filterUsers = useCallback(
    (inputValue: string) => {
      personController
        .allPersons(searchFIO(inputValue, searchStatus))
        .then((res) => dispatch(profilesInitial.allProfiles(res.data.items)));
    },
    [dispatch, searchStatus]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = sanitizeAndTrimStart(value);
    setSearchValue(sanitizedValue);
  };

  useEffect(() => {
    companyInitialState.shouldGetData && filterUsers(searchValue);
    /* eslint-disable-next-line */
  }, [searchValue, filterUsers]);

  return (
    <>
      <Search
        value={searchValue}
        styles={{ height: "100%" }}
        endAdornment={endAdornment}
        placeholder="Поиск по имени и фамилии"
        handleChange={handleChange}
      />
      <DialogWrapper
        openModal={openModal}
        handleModal={handleModal}
        width="532px"
        contentDividers
        buttons={modalButtons}
      >
        <FiltersModalContent
          filtersValue={filtersValue}
          setFiltersValue={setFiltersValue}
        />
      </DialogWrapper>
    </>
  );
};

export default CompanySearch;
