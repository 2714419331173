import { useState, useEffect } from "react";

import { departmentController } from "../services/department.controller";
import { DepartmentView } from "../models/profile.models";

const departmentsMap: any = {};

export const useDepartments = (page?: number) => {
  const [departments, setDepartments] = useState<string[]>([]);
  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    departmentController.departments(page).then((res) => {
      const allDepartments = res.data.items.map(
        (item: DepartmentView) => {
          departmentsMap[item.name] = item.id;
          departmentsMap[item.id] = item.name;
          return item.name;
        }
      );
      setDepartments([...departments, ...allDepartments]);
      setSize(res.data.totalItems);
    });
    /* eslint-disable-next-line */
  }, [page]);

  return { departments, setDepartments, departmentsMap, size, setSize };
};
