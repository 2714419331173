import React, { FC, useContext } from "react";

import { Box, Stack, Typography } from "@mui/material";

import Search from "../../common/Search/Search";
import { useUserList } from "../../../hooks/useUserList";
import ShowMoreButton from "../../KnowledgeBase/ShowMoreButton/ShowMoreButton";
import Observer from "./Observer/Observer";
import { MemberItemSize } from "../../../types/UserInfo.types";
import CloseButton from "../CloseButton/CloseButton";
import { PersonView } from "../../../models/profile.models";
import { AchievContext } from "../AchievContextProvider/AchievContextProvider";
import {sanitizeAndTrimStart} from "../../../operation/sanitizeAndTrimStart";

interface ObserversContentProps {
  type: "achievement" | "task";
  handleClose?: () => void;
  handleList: (checked: boolean, userId: PersonView) => void;
  memberInfoSize?: MemberItemSize;
  selectedUsers: PersonView[];
}

export const ObserversContent: FC<ObserversContentProps> = ({
  type,
  handleClose,
  handleList,
  memberInfoSize,
  selectedUsers,
}) => {
  const achievement = type === "achievement";
  const excludedIds = selectedUsers?.map((user) => `id!:${user.id}`).join(",");
  const { users, search, setSearch, setPage, totalItems } = useUserList(
    !achievement ? excludedIds : ""
  );
  const { observerList } = useContext(AchievContext);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const sanitizedValue = sanitizeAndTrimStart(value);
    setSearch(sanitizedValue);
  };
  const showMore = totalItems > users.length;
  const getNextUsers = () => {
    setPage((prev) => prev + 1);
  };

  const includedInList = (item: PersonView, list: PersonView[]) => {
    return list?.findIndex((user) => user.id === item.id) !== -1;
  };

  return (
    <Stack gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant={achievement ? "subtitle1" : "subtitle2"}>
          Наблюдатели
        </Typography>
        {achievement ? (
          <Typography
            variant="body1"
            color={(theme) => theme.palette.text.secondary}
          >
            {selectedUsers.length} чел.
          </Typography>
        ) : (
          <CloseButton handleClose={handleClose!} />
        )}
      </Box>
      <Search placeholder="Поиск" value={search} handleChange={handleSearch} />
      <Stack gap={achievement ? 0 : 1}>
        {!search && !achievement &&
          selectedUsers?.map((item) => (
            //current observers from task
            <Observer
              user={item}
              handleList={handleList}
              key={item.id}
              memberInfoSize={memberInfoSize}
              isChecked={includedInList(item, observerList)}
            />
          ))}
        {users.map((item) => (
          <Observer
            user={item}
            handleList={handleList}
            key={item.id}
            memberInfoSize={memberInfoSize}
            isChecked={
              achievement
                ? includedInList(item, selectedUsers)
                : includedInList(item, observerList)
            }
          />
        ))}
        {showMore && <ShowMoreButton onClick={getNextUsers} />}
      </Stack>
    </Stack>
  );
};

export default ObserversContent;
