import React, { FC, SetStateAction, Dispatch, useContext } from "react";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";

import { Box, Stack, Typography } from "@mui/material";

import TagItem from "./TagItem/TagItem";
import EditableTagPopover from "../../EditableTagPopover/EditableTagPopover";
import CloseButton from "../../../../CloseButton/CloseButton";
import {
  TaskTagView,
  TaskView,
} from "../../../../../../models/achievement.models";
import { AchievContext } from "../../../../AchievContextProvider/AchievContextProvider";
import { RecordModificationType } from "../../../../../../types/RecordModification.type";

interface TagsContentProps {
  closeModal: () => void;
  type: RecordModificationType;
  addTag: (item: TaskTagView) => void;
  deleteTag: (item: TaskTagView) => void;
}

export const TagsContent: FC<TagsContentProps> = ({
  closeModal,
  type,
  addTag,
  deleteTag,
}) => {
  const isCreate = type === "create";
  const tags: TaskTagView[] = useAppSelector(
    (state) => state.achievementsReducer.achievementTags
  );
  const task: TaskView = useAppSelector(
    (state) => state.achievementsReducer.achievementTask
  );

  const { tagList } = useContext(AchievContext);

  const isChecked = (item: TaskTagView) => {
    if (isCreate) {
      return tagList.some((tag) => tag.id === item.id);
    } else {
      return task.tags.some((tag) => tag.id === item.id);
    }
  };

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    setChecked: Dispatch<SetStateAction<boolean>>,
    item: TaskTagView
  ) => {
    const checked = event.target.checked;
    setChecked(checked);
    if (checked) {
      addTag(item);
    } else {
      deleteTag(item);
    }
  };

  return (
    <Stack gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">Метки</Typography>
        <CloseButton handleClose={closeModal} />
      </Box>
      {tags.length > 0 ? (
        <Stack gap={1}>
          {tags?.map((item, index) => (
            <TagItem
              item={item}
              key={index}
              isChecked={isChecked(item)}
              handleCheckbox={handleCheckbox}
            />
          ))}
        </Stack>
      ) : (
        <Typography
          textAlign="center"
          color={(theme) => theme.palette.text.secondary}
        >
          Меток еще нет. Создайте новую.
        </Typography>
      )}
      <EditableTagPopover type="create" />
    </Stack>
  );
};

export default TagsContent;
