import React, { FC, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import { Stack, Typography } from "@mui/material";

import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import { communityController } from "../../../services/community.controller";
import { communitiesInitial } from "../../../actions/communities";
import { CommunityContext } from "../CommunityContextProvider/CommunityContextProvider";
import Search from "../../common/Search/Search";
import MemberWrapper from "./MemberWrapper/MemberWrapper";
import { PersonView } from "../../../models/profile.models";
import { personController } from "../../../services/person.controller";
import {
  searchFIO,
  searchFIOMemberCommunity,
} from "../../../operation/searchFIO";
import { TypeMembersModal } from "../CommunityHeader/CommunityHeader";
import ShowMoreButton from "../../KnowledgeBase/ShowMoreButton/ShowMoreButton";
import { PersonCommunityView } from "../../../models/community.models";
import { roles, memberGroupRoles } from "../../../constants/role";
import SelectMember from "./SelectMember/SelectMember";
import {sanitizeAndTrimStart} from "../../../operation/sanitizeAndTrimStart";

interface MembersModalProps {
  type: TypeMembersModal;
  openModal: boolean;
  handleModal: () => void;
  currentUserRole: string;
  leaveCommunity?: () => void;
}
export const MembersModal: FC<MembersModalProps> = ({
  type,
  openModal,
  handleModal,
  currentUserRole,
  leaveCommunity,
}) => {
  const dispatch = useDispatch();
  const isView = type === "view";
  const communityId = localStorage.getItem("groupId") || "";
  const { handleSnackbar, membersTotalItems, setMembersTotalItems } =
    useContext(CommunityContext);

  const modalTitle =
    type === "add"
      ? "Пригласить"
      : type === "admin"
      ? "Новые администраторы группы"
      : type === "owner"
      ? "Новый владелец группы"
      : "Участники группы";

  const [search, setSearch] = useState<string>("");
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const sanitizedValue = sanitizeAndTrimStart(value);
    setSearch(sanitizedValue);
  };

  const groupMembers: PersonCommunityView[] = useAppSelector(
    (state) => state.communitiesReducer.members
  );
  const handleCancel = () => {
    setSearch("");
    handleModal();
  };

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalUserItems, setTotaUserlItems] = useState<number>(0);
  const [userList, setUserList] = useState<
    PersonView[] | PersonCommunityView[]
  >([]);
  const [idList, setIdList] = useState<string[]>([]);

  useEffect(() => {
    if (type === "add") {
      communityController
        .allMembers(communityId, 0, membersTotalItems)
        .then((res) => {
          dispatch(communitiesInitial.members(res.data.items));
        });
    }
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  const prepareNotMemberData = (roleFilter: string) => {
    if (search) {
      setCurrentPage(0);
      communityController
        .allMembers(
          communityId,
          currentPage,
          undefined,
          searchFIOMemberCommunity(search, roleFilter)
        )
        .then((res) => {
          setTotaUserlItems(res.data.totalItems);
          setUserList(res.data.items);
        });
    } else if (!search && currentPage === 0) {
      communityController
        .allMembers(communityId, currentPage, undefined, roleFilter)
        .then((res) => {
          setTotaUserlItems(res.data.totalItems);
          setUserList(res.data.items);
        });
    } else {
      communityController
        .allMembers(communityId, currentPage, undefined, roleFilter)
        .then((res) => {
          setTotaUserlItems(res.data.totalItems);
          setUserList((prevList) => [...prevList, ...res.data.items]);
        });
    }
  };

  useEffect(() => {
    if (type === "add") {
      const filter = groupMembers.map((member) => `id!:${member.id}`);

      if (search) {
        setCurrentPage(0);
        personController
          .allPersons(
            searchFIO(search, `blocked:false,${filter.join()}`),
            undefined,
            currentPage
          )
          .then((res) => {
            setTotaUserlItems(res.data.totalItems);
            setUserList(res.data.items);
          });
      } else if (!search && currentPage === 0) {
        personController
          .allPersons(`blocked:false,${filter.join()}`, undefined, currentPage)
          .then((res) => {
            setTotaUserlItems(res.data.totalItems);
            setUserList(res.data.items);
          });
      } else {
        personController
          .allPersons(`blocked:false,${filter.join()}`, undefined, currentPage)
          .then((res) => {
            setTotaUserlItems(res.data.totalItems);
            setUserList((prevList) => [...prevList, ...res.data.items]);
          });
      }
    }

    if (type === "view") {
      if (search) {
        setCurrentPage(0);
        communityController
          .allMembers(
            communityId,
            currentPage,
            undefined,
            searchFIOMemberCommunity(search)
          )
          .then((res) => {
            setMembersTotalItems(res.data.totalItems);
            dispatch(communitiesInitial.members(res.data.items));
          });
      } else if (!search && currentPage === 0) {
        communityController.allMembers(communityId, currentPage).then((res) => {
          setMembersTotalItems(res.data.totalItems);
          dispatch(communitiesInitial.members(res.data.items));
        });
      } else {
        communityController.allMembers(communityId, currentPage).then((res) => {
          setMembersTotalItems(res.data.totalItems);
          dispatch(
            communitiesInitial.members([...groupMembers, ...res.data.items])
          );
        });
      }
    }

    if (type === "admin") {
      prepareNotMemberData(`communityRole:${memberGroupRoles.member}`);
    }

    if (type === "owner") {
      prepareNotMemberData(`communityRole!:${memberGroupRoles.owner}`);
    }
    /* eslint-disable */
  }, [search, currentPage, type]);
  /* eslint-enable */

  const getNextMembers = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const showMore = isView
    ? membersTotalItems > groupMembers.length
    : totalUserItems > userList.length;

  const updateMembers = () => {
    communityController.allMembers(communityId).then((res) => {
      dispatch(communitiesInitial.members(res.data.items));
      setMembersTotalItems(res.data.totalItems);
    });
  };
  const addService = () => {
    const bodyRequest = idList.map((id) => ({
      personId: id,
      role: memberGroupRoles.member,
    }));
    communityController
      .addMembers(communityId, bodyRequest)
      .then(() => {
        updateMembers();
        handleSnackbar("success", "Участник(и) были добавлены в группу");
        handleCancel();
      })
      .catch(() => {
        handleSnackbar("error");
      });
  };

  const deleteService = () => {
    const body = { membersIds: idList };
    communityController
      .deleteMembers(communityId, body)
      .then(() => {
        updateMembers();
        handleSnackbar("success", "Участник(и) были удалены из группы");
        handleCancel();
      })
      .catch(() => {
        handleSnackbar("error");
      });
  };

  const addAdminService = () => {
    idList.forEach((id, index) => {
      communityController
        .roles(communityId, { personId: id, role: "ADMIN" })
        .then(() => {
          if (index + 1 === idList.length) {
            handleSnackbar("success", "Администратор(ы) группы назначен(ы)");
            updateMembers();
            handleCancel();
          }
        })
        .catch(() => {
          if (index + 1 === idList.length) {
            handleSnackbar("error");
          }
        });
    });
  };

  const newOwnerService = () => {
    communityController
      .newOwnerCommunity(communityId, idList[0])
      .then(() => {
        handleSnackbar("success", "Назначен новый администратор группы");
        leaveCommunity?.();
        updateMembers();
        handleCancel();
      })
      .catch(() => {
        handleSnackbar("error");
      });
  };

  const handleSendButton = () => {
    if (type === "add") {
      addService();
    } else if (type === "admin") {
      addAdminService();
    } else if (type === "owner") {
      newOwnerService();
    } else {
      deleteService();
    }
  };

  const disableSendButton = isView
    ? idList.length === groupMembers.length || idList.length === 0
    : idList.length < 1;

  const buttons = (
    <ModalButtons
      nameButtonSend="Применить"
      handleSend={handleSendButton}
      disableSend={disableSendButton}
      handleCancel={handleCancel}
      fullWidth
      justifyContent="flex-end"
    />
  );

  const user = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}");
  const editAvailable =
    user.role === roles.admin ||
    (currentUserRole && currentUserRole !== memberGroupRoles.member);

  return (
    <>
      <DialogWrapper
        openModal={openModal}
        handleModal={handleModal}
        contentDividers
        buttons={editAvailable ? buttons : undefined}
        styles={{
          "& .MuiDialog-paper": { maxHeight: "480px", width: "532px" },
        }}
      >
        <Stack gap={4}>
          <Typography variant="h4">{modalTitle}</Typography>
          <Search
            placeholder="Поиск"
            value={search}
            handleChange={handleSearch}
          />
          <Stack gap={type === "owner" ? 0 : 1}>
            {!isView
              ? userList.map((member, index) =>
                  type === "owner" ? (
                    <SelectMember
                      key={index}
                      userInfo={member}
                      setIdList={setIdList}
                      idList={idList}
                    />
                  ) : (
                    <MemberWrapper
                      key={index}
                      userInfo={member}
                      setIdList={setIdList}
                      typeModal={type}
                      idList={idList}
                    />
                  )
                )
              : groupMembers.map((member, index) => (
                  <MemberWrapper
                    key={index}
                    userInfo={member}
                    setIdList={setIdList}
                    typeModal={type}
                    idList={idList}
                    disabled={
                      !editAvailable ||
                      member.communityRole === memberGroupRoles.owner
                    }
                    isGroupMember={true}
                  />
                ))}
            {showMore && <ShowMoreButton onClick={getNextMembers} />}
          </Stack>
        </Stack>
      </DialogWrapper>
    </>
  );
};

export default MembersModal;
