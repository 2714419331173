import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import MemberItem from "./MemberItem/MemberItem";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import { PersonView } from "../../../../models/profile.models";
import { personController } from "../../../../services/person.controller";
import { profilesInitial } from "../../../../actions/profiles";
import OnlineMembers from "./OnlineMembers/OnlineMembers";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";

export const Members: FC = () => {
  const dispatch = useDispatch();
  const loggedUserId = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).id;

  const loggedUsers = useAppSelector(
    (state) => state.socketsReducer.loggedUsers
  );

  const [page, setPage] = useState<number>(0);
  const [totalMembers, setTotalMembers] = useState<number>(0);
  const profiles: PersonView[] = useAppSelector(
    (state) => state.profileReducer.filteredProfiles
  );

  /* eslint-disable */
  useEffect(() => {
    personController
      .allPersons(`id!:${loggedUserId},blocked:false`)
      .then((res) => {
        dispatch(profilesInitial.filteredProfiles(res.data.items));
        setTotalMembers(res.data.totalItems);
      });
  }, []);

  useEffect(() => {
    if (page > 0) {
      personController
        .allPersons(`id!:${loggedUserId},blocked:false`, undefined, page)
        .then((res) => {
          dispatch(
            profilesInitial.filteredProfiles([...profiles, ...res.data.items])
          );
          setTotalMembers(res.data.totalItems);
        });
    }
  }, [page, loggedUserId]);
  /* eslint-enable */

  const showMore = totalMembers > profiles.length;

  const getNextMembers = () => {
    setPage((prev) => prev + 1);
  };

  const moreMembersButtonProps = {
    onClick: getNextMembers,
    color: "secondary" as const,
    text: "Ещё сотрудники",
    styles: { width: "100%", mt: "8px" },
  };

  return (
    <Box>
      <Typography variant="body1" color="secondary" py={2}>
        В сети
      </Typography>
      {/* <MemberItem userInfo={joyUser} isJoy /> */}
      {/* TODO: wait telegram bot */}
      <OnlineMembers />
      <Typography variant="body1" color="secondary" py={2}>
        Не в сети
      </Typography>
      {profiles
        .filter((user) => !loggedUsers?.includes(user?.id))
        .map((item, index) => (
          <MemberItem userInfo={item} key={index} />
        ))}
      {showMore && <CustomButton {...moreMembersButtonProps} />}
    </Box>
  );
};

export default Members;
