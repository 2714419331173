import React, { FC } from "react";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";

import type { NotificationView } from "../../../../../models/notifications.model";

interface NotificationsButtonProps {
  expanded?: boolean;
  handleClick: () => void;
}

export const NotificationsButton: FC<NotificationsButtonProps> = ({ expanded, handleClick }) => {
  const liveNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.liveNotifications
  );
  
  const notificationsButtonStyle = {
    width: "40px",
    height: "40px",
    backgroundColor: expanded ? "rgba(51, 146, 255, 0.25)" : "rgba(203, 225, 255, 0.17)",
    color: expanded ? "rgba(24, 132, 255, 1)" : "rgba(229, 235, 242, 0.65)",
    ":hover": {
      color: "rgba(24, 132, 255, 1)",
    },
    ".MuiBadge-badge": {
      mt: "-5px",
      mr: "-3px",
      height: "16px",
      minWidth: "16px",
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "16px",
      padding: "4px",
    },
  };

  return (
    <IconButton onClick={handleClick} sx={notificationsButtonStyle}>
      <Badge badgeContent={liveNotifications.length} max={9} color="primary">
        <NotificationsNoneIcon sx={{ width: "24px", height: "24px" }} />
      </Badge>
    </IconButton>
  );
};

export default NotificationsButton;