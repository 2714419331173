import React, { FC, useContext, useState } from "react";

import { Typography, Stack } from "@mui/material";

import { achievementController } from "../../../../services/achievement.controller";
import { AchievContext } from "../../AchievContextProvider/AchievContextProvider";
import DialogWrapper from "../../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../../common/Buttons/ModalButtons/ModalButtons";
import InputWrapper from "../../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../../common/Inputs/InputLine/InputLine";
import { regEx } from "../../../../constants/regEx";
import ObserversContent from "../../ObserversContent/ObserversContent";
import { PersonView } from "../../../../models/profile.models";
import { AppContext } from "../../../shared/AppContextProvider";

interface AchievModalProps {
  type: "create" | "edit";
  openModal: boolean;
  handleModal: () => void;
  id?: string;
  name?: string;
}

export const AchievModal: FC<AchievModalProps> = ({
  type,
  openModal,
  handleModal,
  id,
  name,
}) => {
  const newAchiev = type === "create";
  const userId = localStorage.getItem("userId") || "";
  const { handleSnackbar, updAchievemnts } = useContext(AchievContext);
  const { isMobile } = useContext(AppContext);

  const [title, setTitle] = useState<string>(newAchiev ? "" : name!);
  const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const [selectedUsers, setSelectedUsers] = useState<PersonView[]>([]);
  const handleList = (checked: boolean, user: PersonView) => {
    if (checked) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      setSelectedUsers((prev) => prev.filter((item) => item.id !== user.id));
    }
  };
  const handleCancel = () => {
    handleModal();
    if (newAchiev) {
      setTitle("");
      setSelectedUsers([]);
    }
  };

  const serviceCreate = () => {
    const body = {
      name: title,
      ownerId: userId,
      observerIds: selectedUsers.map((user) => user.id),
    };
    achievementController
      .newAchievement(body)
      .then(() => {
        updAchievemnts(userId, false);
        handleCancel();
      })
      .catch(() => handleSnackbar("error"));
  };

  const serviceEdit = () => {
    const body = {
      name: title,
    };
    achievementController
      .edit(id!, body)
      .then(() => {
        updAchievemnts(userId, false);
        handleCancel();
      })
      .catch(() => handleSnackbar("error"));
  };

  const handleSend = () => {
    if (newAchiev) {
      serviceCreate();
    } else {
      serviceEdit();
    }
  };

  const isEmptyTitle = regEx.isEmpty.test(title);
  const disabledSendButton = newAchiev
    ? isEmptyTitle || !selectedUsers.length
    : isEmptyTitle;

  const actionButtons = (
    <ModalButtons
      disableSend={disabledSendButton}
      handleCancel={handleCancel}
      handleSend={handleSend}
      fullWidth
      justifyContent="flex-end"
    />
  );

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleCancel}
      width="532px"
      height={newAchiev && !isMobile ? "608px" : undefined}
      contentDividers
      buttons={actionButtons}
    >
      <Stack gap={4}>
        <Typography variant="h4">
          {newAchiev ? "Создать цель" : "Редактировать цель"}
        </Typography>
        <InputWrapper
          titleColor="secondary"
          flexDirection="column"
          title="Название цели"
          element={
            <InputLine
              value={title}
              onChange={handleTitle}
              placeholder="Укажите название цели"
            />
          }
        />
        {newAchiev && (
          <ObserversContent
            type="achievement"
            handleList={handleList}
            selectedUsers={selectedUsers}
          />
        )}
      </Stack>
    </DialogWrapper>
  );
};

export default AchievModal;
