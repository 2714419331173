import React, { FC } from "react";

import Box from "@mui/material/Box";
import { Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ReactComponent as FavoritesInactiveIcon } from "../../../assets/icons/favoritesActive.svg";
import { ReactComponent as FavoritesActiveIcon } from "../../../assets/icons/favoritesInactive.svg";
import CustomToggleButtons from "../../common/Buttons/CustomToggleButtons/CustomToggleButtons";

import type { IdeaStatus } from "../../../types/IdeaStatus.type";

const StyledBox = styled(Box, {
	shouldForwardProp(prop: string) {
		return (
			prop !== "textColor"
			&& prop !== "selectionFollowsFocus"
			&& prop !== "indicator"
			&& prop !== "fullWidth"
		);
	},
})``;

interface IdeasButtonsProps {
	toggleItems: IdeaStatus[];
	handleToggle: (value: IdeaStatus) => void;
	status: IdeaStatus | null;
}

const IdeasButtons: FC<IdeasButtonsProps> = ({
	toggleItems,
	handleToggle,
	status,
}) => {
	const favoritesIcon = <Box sx={{ margin: "-2px 0px", lineHeight: "0px" }}>
		{status === "Избранное" ? <FavoritesActiveIcon /> : <FavoritesInactiveIcon />}
	</Box>;
	const tabsStyle = {
		".MuiTabs-indicator": {
			height: "0px",
		},
		".MuiToggleButtonGroup-root": {
			flexWrap: "nowrap",
			gap: "8px",
			".MuiToggleButtonGroup-grouped.MuiToggleButton-root": {
				margin: "0px",
			},
		},
	};

	return (
		<Tabs
			variant="scrollable"
			scrollButtons={false}
			sx={tabsStyle}
			value={0}
		>
			<StyledBox pl="16px">
				<CustomToggleButtons
					items={toggleItems}
					onChange={handleToggle}
					currentValue={status ?? undefined}
					icons={[undefined, undefined, undefined, favoritesIcon]}
				/>
			</StyledBox>
		</Tabs>
	);
}

export default IdeasButtons;