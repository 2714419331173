import moment from "moment";

export const determineDate = (createDate: string) => {
  const now = moment();
  const createdMoment = moment(createDate);
  const diffHours = now.diff(createdMoment, "hours");
  const isToday = now.format("DD/MM/YYYY") === createdMoment.format("DD/MM/YYYY");
  const isYesterday = now.clone().subtract(1, 'days').format("DD/MM/YYYY") === createdMoment.format("DD/MM/YYYY");

  if (diffHours <= 1) {
    return "менее часа назад";
  }
  if (diffHours > 1 && diffHours <= 2) {
    return "около 2 часов назад";
  }
  if (diffHours > 2 && isToday) {
    return "сегодня";
  }
  if (isYesterday) {
    return "вчера";
  }
  return createdMoment.format("DD.MM.YYYY");
};
