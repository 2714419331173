import React, { FC } from "react";

import { PersonView } from "../../../models/profile.models";
import { translateRoles } from "../../../translators/roles.translate";
import { roles } from "../../../constants/role";
import CustomToggleButtons from "../../common/Buttons/CustomToggleButtons/CustomToggleButtons";

interface RoleInfoProps {
  data: PersonView;
}
export const RoleInfo: FC<RoleInfoProps> = ({ data }) => {
  const rolesInfo = [data.job.department.name, data.role.role].filter(
    (item) => item !== roles.user
  );

  return (
    <CustomToggleButtons
      items={rolesInfo}
      disabled
      translator={translateRoles}
    />
  );
};

export default RoleInfo;
