import { useState, useEffect } from "react";

import { personController } from "../services/person.controller";
import { ListItem } from "../types/ListItem";

export const useCities = (page?: number) => {
  const [cities, setCities] = useState<ListItem[]>([]);
  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    personController.cities(page).then((res) => {
      setCities([...cities, ...res.data.items]);
      setSize(res.data.totalItems);
    });
    /* eslint-disable-next-line */
  }, [page]);

  return { cities, setCities, size, setSize };
};
