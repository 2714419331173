import React, { FC } from "react";

import Link from "@mui/material/Link";

interface ShowMoreButtonProps {
  style?: any;
  onClick: () => void;
}

export const ShowMoreButton: FC<ShowMoreButtonProps> = ({ style, onClick }) => {
  const styleLink = {
    "&:hover": {
      cursor: "pointer",
    },
    ...style,
  };

  const handleLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    onClick();
  };

  return (
    <Link variant="body2" sx={styleLink} onClick={handleLink} underline="hover">
      Показать еще...
    </Link>
  );
};

export default ShowMoreButton;
