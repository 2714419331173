import { TaskView } from "../../../../models/achievement.models";

export type Column = {
  id: string;
  title: string;
  taskIds: string[];
};

export type InitialTaskTable = {
  tasks: Record<string, TaskView>;
  columns: Record<string, Column>;
  columnOrder: string[];
};

export const initialTaskTable: InitialTaskTable = {
  tasks: {},
  columns: {
    PENDING: {
      id: "PENDING",
      title: "Ожидает",
      taskIds: [],
    },
    IN_PROGRESS: {
      id: "IN_PROGRESS",
      title: "В работе",
      taskIds: [],
    },
    NEED_REVIEW: {
      id: "NEED_REVIEW",
      title: "На проверке",
      taskIds: [],
    },
    DONE: {
      id: "DONE",
      title: "Готово",
      taskIds: [],
    },
  },
  columnOrder: ["PENDING", "IN_PROGRESS", "NEED_REVIEW", "DONE"],
};
