import { initialProfiles } from "./initialProfile";

export const initialAchievements = {
  achievement: {
    author: initialProfiles.profile,
    countOfDoneTasks: 0,
    creationDate: "",
    id: "",
    name: "",
    observers: [],
    status: "",
    tasksSize: 0,
  },
  achievementTask: {
    attachments: [],
    author: initialProfiles.profile,
    commentSize: 0,
    content: "",
    date: "",
    header: "",
    id: "",
    modified: false,
    modifyAt: "",
    modifyBy: initialProfiles.profile,
    observers: [],
    reaction: { id: "" },
    sequence: 0,
    status: "",
    tags: [],
    topComments: [],
  },
  achievementsList: [],
  achievementTags: [],
  achievementComments: [],
};
