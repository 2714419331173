import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Chip from '@mui/material/Chip';
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {PersonView, SelectedPerson} from "../../../../models/profile.models";
import UserAvatar from "../../../common/User/UserAvatar/UserAvatar";
import {useUserList} from "../../../../hooks/useUserList";
import ShowMoreButton from "../../../KnowledgeBase/ShowMoreButton/ShowMoreButton";
import Search from "../../../common/Search/Search";
import {sanitizeAndTrimStart} from "../../../../operation/sanitizeAndTrimStart";
import {LoadProgress} from "../../../common/LoadProgress/LoadProgress";
import CheckboxMember from "../../../common/CheckboxMember/CheckboxMember";
import {decodeBase64} from "../../utils/decodeId";
import { mergeReviewerInfo } from '../../utils/mergeReviewerInfo';

interface IProps {
    selectedReviewers: PersonView[];
    setSelectedReviewers: Dispatch<SetStateAction<PersonView[]>>
    votedReviewers?: {id:string, isVoted:boolean}[]
}

const styleAvatar = {width: '20px', height: '20px', marginLeft: '6px'}

export function UserSelect({selectedReviewers, setSelectedReviewers, votedReviewers}: IProps) {
    const {users, setPage, totalItems, setSearch, search} = useUserList()
    const [userStatuses, setUserStatuses] = useState<SelectedPerson[]>(users.map(user => ({
        ...user,
        isChecked: selectedReviewers.some(r => decodeBase64(r.id) === user.id)
    })))
    const[mergedReviewers, setMergedReviewers] = useState<PersonView[]>([])

    const loading = users.length === 0 && !search;
    const showMore = totalItems > users.length;


    useEffect(() => {
        const updatedUserStatuses = users.map(user => ({
            ...user,
            isChecked: selectedReviewers.some(r => decodeBase64(r.id) === user.id)
        }));
        setUserStatuses(updatedUserStatuses)

        async function updateMergedReviewers() {
            const initMergedReviewers = await mergeReviewerInfo(selectedReviewers)
            setMergedReviewers(initMergedReviewers);
        }
        void updateMergedReviewers()
    }, [selectedReviewers, users])

    const getNextUsers = () => {
        setPage((prev) => prev + 1);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const sanitizedValue = sanitizeAndTrimStart(value);
        setSearch(sanitizedValue);
    };

    const handleList = (checked: boolean, user: PersonView) => {
        if (checked) {
            setSelectedReviewers((prev) => [...prev, user]);
        } else if (!checked) {
            setSelectedReviewers((prev) => prev.filter((item) => decodeBase64(item.id) !== user.id));
        }
    };

    const handleDelete = (user: PersonView) => () => {
        setSelectedReviewers((prev) => prev.filter((item) => item.id !== user.id));
    }

    const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, user: PersonView) => {
        handleList(e.target.checked, user)
    }

    return (
        <Stack gap={2}>
            <Typography
                variant="body1"
                minWidth={192}
                color={'secondary'}
            >
                Ревьюеры
            </Typography>
            <Search placeholder="Поиск" value={search} handleChange={handleSearch}/>
            <Stack gap={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} flexWrap={'wrap'}>
                {
                    mergedReviewers.map(option =>
                        <Chip
                            key={option.id}
                            sx={(theme)=> ({width: 'max-content', backgroundColor: theme?.palette?.secondary?.contrastText})}
                            label={`${option.name} ${option.surname}`}
                            avatar={<UserAvatar userInfo={option} styles={styleAvatar}/>}
                            onDelete={handleDelete(option)}
                            disabled={votedReviewers && votedReviewers.some(el => el.id === option.id && el.isVoted)}
                        />)
                }
            </Stack>
            {
                loading
                    ? <LoadProgress/>
                    : <Stack>
                        {userStatuses.map((item) => (
                            <CheckboxMember
                                userInfo={item}
                                handleCheckbox={(e) => handleCheckBox(e, item)}
                                key={item.id}
                                checked={item.isChecked}
                                disabled={votedReviewers && votedReviewers.some(el => el.id === item.id && el.isVoted)}
                            />
                        ))}
                        {showMore && <ShowMoreButton onClick={getNextUsers}/>}
                    </Stack>
            }
        </Stack>
    )
}