import React, { FC } from "react";

import ListWrapper from "./ListWrapper/ListWrapper";
import NoGoals from "./NoGoals/NoGoals";
import { useAppSelector } from "../../../hooks/useAppSelector";

interface AchievListProp {
  archived: boolean;
  newAchievButton?: React.ReactElement;
}

export const AchievList: FC<AchievListProp> = ({
  archived,
  newAchievButton,
}) => {
  const achievs = useAppSelector(
    (state) => state.achievementsReducer.achievementsList
  );

  const styledButton = newAchievButton
    ? React.cloneElement(newAchievButton, {
        styles: { mt: 2, fontSize: "16px", p: "4px 24px" },
      })
    : null;

  const createButton = <>{!archived && styledButton}</>;

  const emptyListText = archived
    ? "Еще нет целей в архиве"
    : "Пока нет активной цели";

  return (
    <>
      {achievs?.length > 0 ? (
        <ListWrapper data={achievs} />
      ) : (
        <NoGoals text={emptyListText} button={createButton} />
      )}
    </>
  );
};

export default AchievList;
