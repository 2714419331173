import React, { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface InputWrapperProps {
  title: string;
  element: React.ReactElement;
  flexDirection?: "column" | "row";
  titleColor?: "primary" | "secondary";
  styles?: any;
}
export const InputWrapper: FC<InputWrapperProps> = ({
  title,
  element,
  flexDirection = "row",
  titleColor = "primary",
  styles,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleFlex = {
    display: "flex",
    alignItems: "baseline",
    gap: isMobile ? "8px" : "12px",
    flexDirection: isMobile ? "column" : flexDirection,
    ...styles,
  };

  return (
    <Box sx={styleFlex}>
      <Typography
        variant="body1"
        minWidth={192}
        color={(theme) =>
          titleColor === "secondary"
            ? theme.palette.text.secondary
            : theme.palette.text.primary
        }
      >
        {title}
      </Typography>
      {element}
    </Box>
  );
};

export default InputWrapper;
