import React, { FC, SetStateAction } from "react";

import { Box } from "@mui/material";

import InputFilled from "../Inputs/InputFilled/InputFilled";
import AttachmentButton from "../Buttons/AttachmentButton/AttachmentButton";
import PreviewFiles from "../Attachments/PreviewFiles/PreviewFiles";

interface CommentTaskProps {
  comment: string;
  handleComment: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAttache?: (event: any) => void;
  files: any;
  setFiles: SetStateAction<any>;
  fileLocation?: "top" | "bottom";
  placeholder?: string;
  mt?: boolean;
  saveDeleteId?: boolean;
}

export const CommentTask: FC<CommentTaskProps> = ({
  comment,
  handleComment,
  handleAttache,
  files,
  setFiles,
  fileLocation = "top",
  placeholder = "Напишите комментарий",
  mt = true,
  saveDeleteId,
}) => {
  const previewFiles = (
    <PreviewFiles
      files={files}
      setFiles={setFiles}
      type="upload"
      saveDeleteId={saveDeleteId}
    />
  );
  return (
    <Box mt={mt ? 3 : 0}>
      {files && fileLocation === "top" && previewFiles}
      <InputFilled
        value={comment}
        handleValue={handleComment}
        multiline
        placeholder={placeholder}
        margin={fileLocation === "top" ? "16px 0 0" : "0 0 16px"}
      />
      {files && fileLocation === "bottom" && previewFiles}
      {handleAttache && (
        <Box mt={2}>
          <AttachmentButton onChange={handleAttache} withBackground />
        </Box>
      )}
    </Box>
  );
};

export default CommentTask;
