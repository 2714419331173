import React, { FC, useState, MouseEvent, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import CustomButton from "../../../../common/Buttons/CustomButton/CustomButton";
import TagsContent from "./TagsContent/TagsContent";
import PopoverWrapper from "../../../../common/Modals/PopoverWrapper/PopoverWrapper";
import { AchievContext } from "../../../AchievContextProvider/AchievContextProvider";
import { achievementController } from "../../../../../services/achievement.controller";
import { achievementsInitial } from "../../../../../actions/achievements";
import { RecordModificationType } from "../../../../../types/RecordModification.type";
import { TaskTagView } from "../../../../../models/achievement.models";
import { AppContext } from "../../../../shared/AppContextProvider";

interface TagsPopoverProps {
  type: RecordModificationType;
  addTag: (item: TaskTagView) => void;
  deleteTag: (item: TaskTagView) => void;
}

export const TagsPopover: FC<TagsPopoverProps> = ({
  type,
  addTag,
  deleteTag,
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "tags-popover" : undefined;

  const { tagCompRef, handleSnackbar } = useContext(AchievContext);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isOpen) {
      const id = sessionStorage.getItem("achievementId") || "";
      achievementController
        .getTags(id)
        .then((res) =>
          dispatch(achievementsInitial.achievementTags(res.data.items))
        )
        .catch(() => handleSnackbar("error"));
    }
    /* eslint-disable-next-line */
  }, [isOpen]);

  const cardStyle = {
    width: "307px",
    height: "auto",
  };

  return (
    <Box ref={tagCompRef}>
      <CustomButton
        text="Добавить метку"
        color="secondary"
        fullWidth
        onClick={handleClick}
      />
      <PopoverWrapper
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        cardStyle={cardStyle}
        anchorOriginHorizontal={isMobile ? "center" : "left"}
        transformOriginHorizontal={isMobile ? "center" : "left"}
        popoverStyle={{ mt: 1 }}
      >
        <TagsContent
          closeModal={handleClose}
          type={type}
          addTag={addTag}
          deleteTag={deleteTag}
        />
      </PopoverWrapper>
    </Box>
  );
};

export default TagsPopover;
