export const translateRoles = (role: string) => {
  switch (role) {
    case "EDITOR":
      return (role = "Редактор");
    case "ADMIN":
      return (role = "Администратор");
    case "USER":
      return (role = "Пользователь");
    default:
      return role;
  }
};
