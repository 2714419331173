import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";

import { SnackBarType } from "../../common/Snackbars/Snackbars";
import Snackbars from "../../common/Snackbars/Snackbars";
import { AppContext } from "../../shared/AppContextProvider";
import { roles } from "../../../constants/role";
import { achievementController } from "../../../services/achievement.controller";
import { achievementsInitial } from "../../../actions/achievements";
import { TaskTagView, TaskView } from "../../../models/achievement.models";
import {
  InitialTaskTable,
  initialTaskTable,
} from "../Tasks/TaskTable/initialTaskTable";
import { PersonView } from "../../../models/profile.models";

export type TaskStatus = "Ожидает" | "В работе" | "На проверке" | "Готово";

interface AchivContextProps {
  children: React.ReactNode;
}

interface AchievContextValue {
  handleSnackbar: (type: SnackBarType, message?: string) => void;
  editableAcces: boolean;
  achivementAcces: boolean;
  listSize: number;
  setListSize: Dispatch<SetStateAction<number>>;
  updAchievemnts: (userId: string, archived: boolean) => void;
  listPage: number;
  setListPage: Dispatch<SetStateAction<number>>;
  tagCompRef: React.MutableRefObject<HTMLButtonElement | null>;
  tag: TaskTagView | null;
  setTag: Dispatch<SetStateAction<TaskTagView | null>>;
  tagList: TaskTagView[];
  setTagList: Dispatch<SetStateAction<TaskTagView[]>>;
  observerList: PersonView[];
  setObserverList: Dispatch<SetStateAction<PersonView[]>>;
  status: TaskStatus;
  setStatus: Dispatch<SetStateAction<TaskStatus>>;
  taskTable: InitialTaskTable;
  setTaskTable: Dispatch<SetStateAction<InitialTaskTable>>;
  handleTasks: (tasks: TaskView[]) => void;
  isChangeStatus: boolean;
  setIsChangeStatus: Dispatch<SetStateAction<boolean>>;
  selectedDot: number;
  setSelectedDot: Dispatch<SetStateAction<number>>;
  userRole: boolean;
  isCurrentUser: boolean;
}

let defaultValue: AchievContextValue = {
  handleSnackbar: () => {},
  editableAcces: false,
  achivementAcces: false,
  listSize: 0,
  setListSize: () => {},
  updAchievemnts: () => {},
  listPage: 0,
  setListPage: () => {},
  tagCompRef: React.createRef<HTMLButtonElement>(),
  tag: null,
  setTag: () => {},
  tagList: [],
  setTagList: () => {},
  observerList: [],
  setObserverList: () => {},
  status: "Ожидает",
  setStatus: () => {},
  taskTable: initialTaskTable,
  setTaskTable: () => {},
  handleTasks: () => {},
  isChangeStatus: false,
  setIsChangeStatus: () => {},
  selectedDot: 0,
  setSelectedDot: () => {},
  userRole: false,
  isCurrentUser: false,
};
export const AchievContext = createContext(defaultValue);

export const AchievContextProvider: FC<AchivContextProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [listSize, setListSize] = useState<number>(0);
  const [listPage, setListPage] = useState<number>(0);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<SnackBarType>("error");
  const [snackbarText, setSnackbarText] = useState<string | undefined>(
    undefined
  );

  const handleSnackbar = (type: SnackBarType, message?: string) => {
    setSnackbarType(type);
    setOpenSnackbar(true);
    if (message) {
      setSnackbarText(message);
    } else {
      setSnackbarText(undefined);
    }
  };

  const { user } = useContext(AppContext);
  const userId = localStorage.getItem("userId") || "";
  const currentUserId = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).id;
  const isCurrentUser = userId === currentUserId;
  const userRole = user.role === roles.user;
  const editableAcces = !userRole && !isCurrentUser;
  const achivementAcces =
    !userRole || isCurrentUser || (userRole && listSize > 0);

  const updAchievemnts = (userId: string, archived: boolean) => {
    achievementController
      .byUser(userId, archived, 0, (listPage + 1) * 5)
      .then((result) => {
        dispatch(achievementsInitial.achievementsList(result.data.items));
        setListSize(result.data.totalItems);
      })
      .catch(() => handleSnackbar("error"));
  };

  const tagCompRef = useRef<HTMLButtonElement | null>(null);
  const [tag, setTag] = useState<TaskTagView | null>(null);
  const [tagList, setTagList] = useState<TaskTagView[]>([]);

  const [observerList, setObserverList] = useState<PersonView[]>([]);
  const [status, setStatus] = useState<TaskStatus>("Ожидает");
  const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false);

  const [taskTable, setTaskTable] =
    useState<InitialTaskTable>(initialTaskTable);

  const handleTasks = (tasks: TaskView[]) => {
    const convertTasks = tasks.reduce((acc, current) => {
      acc[current.id] = { ...current };
      return acc;
    }, {} as { [key: string]: TaskView });

    const filterTasks = (colunmId: string) => {
      return tasks
        .filter((item) => item.status === colunmId)
        .map((task) => task.id);
    };

    const newState = {
      ...taskTable,
      tasks: convertTasks,
      columns: {
        PENDING: {
          ...taskTable.columns["PENDING"],
          taskIds: filterTasks("PENDING"),
        },
        IN_PROGRESS: {
          ...taskTable.columns["IN_PROGRESS"],
          taskIds: filterTasks("IN_PROGRESS"),
        },
        NEED_REVIEW: {
          ...taskTable.columns["NEED_REVIEW"],
          taskIds: filterTasks("NEED_REVIEW"),
        },
        DONE: {
          ...taskTable.columns["DONE"],
          taskIds: filterTasks("DONE"),
        },
      },
    };
    setTaskTable(newState);
  };

  const [selectedDot, setSelectedDot] = useState<number>(0);

  return (
    <AchievContext.Provider
      value={{
        handleSnackbar,
        editableAcces,
        achivementAcces,
        listSize,
        setListSize,
        updAchievemnts,
        listPage,
        setListPage,
        tagCompRef,
        tag,
        setTag,
        tagList,
        setTagList,
        observerList,
        setObserverList,
        status,
        setStatus,
        taskTable,
        setTaskTable,
        handleTasks,
        isChangeStatus,
        setIsChangeStatus,
        selectedDot,
        setSelectedDot,
        userRole,
        isCurrentUser,
      }}
    >
      {children}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type={snackbarType}
        message={snackbarText}
        position="center"
      />
    </AchievContext.Provider>
  );
};

export default AchievContextProvider;
