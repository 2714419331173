import React, { FC, useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Modal from "@mui/material/Modal";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-material-ui-carousel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CustomBadge from "../../CustomBadge/CustomBadge";
import { AttachmentView } from "../../../../models/attachment.model";

interface PreviewImagesProp {
  images: AttachmentView[];
}

export const PreviewImages: FC<PreviewImagesProp> = ({ images }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => setOpenModal(!openModal);

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
    width: isMobile ? "80%" : "40%",
    height: "auto",
  };

  const [selectedItem, setSelectedItem] = useState<number>(0);
  const handleImageItem = (index: number) => {
    setSelectedItem(index);
    handleModal();
  };

  return (
    <ImageList cols={6} gap={8} variant="quilted">
      {images
        ?.filter((item: any, index: number) => index <= 13)
        .map((item: any, index: number) => (
          <ImageListItem
            onClick={() => handleImageItem(index)}
            key={index}
            cols={index <= 1 ? 3 : 1}
            rows={isMobile ? 1 : index <= 1 ? 2 : 1}
            sx={{
              "& .MuiImageListItem-img": { borderRadius: "8px" },
            }}
          >
            <img
              src={`/loyalty-portal/api/attachments/${item.name}${item.extension}?w=auto&h=auto&fit=crop&auto=format`}
              srcSet={`/loyalty-portal/api/attachments/${item.name}${item.extension}?w=auto&h=auto&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            {index + 1 === 14 && <CustomBadge label={images.length} />}
          </ImageListItem>
        ))}
      <Modal
        open={openModal}
        onClose={handleModal}
        aria-labelledby="modal-photo"
        aria-describedby="modal-open-photo"
      >
        <Box sx={styleModal}>
          <Carousel autoPlay={false} index={selectedItem}>
            {images?.map((item: any, index: number) => (
              <Card key={index} sx={{ backgroundColor: "transparent" }}>
                <CardMedia
                  component="img"
                  height="auto"
                  width="100%"
                  image={`/loyalty-portal/api/attachments/${item.name}${item.extension}`}
                  alt={item.name}
                  loading="lazy"
                  sx={{
                    objectFit: "contain",
                    maxHeight: `calc(${window.innerHeight}px*0.95)`,
                  }}
                />
              </Card>
            ))}
          </Carousel>
        </Box>
      </Modal>
    </ImageList>
  );
};
export default PreviewImages;
