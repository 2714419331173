import type { IdeaStatus } from "../types/IdeaStatus.type";

export const filterIdeas = (status: IdeaStatus | null) => {
  switch (status) {
    case "Актуальные":
      return "NEW";
    case "Принятые":
      return "APPROVED";
    case "Отстойник":
      return "DISMISSED";
    case "Избранное":
      return "FAVORITE";
    default:
      return " ";
  }
};