import { useEffect, useRef } from "react";
import { IMessage } from "@stomp/stompjs";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector";

import { dataInitial } from "../actions/data";
import { notificationsController } from "../services/notifications.controller";

import type { NotificationView } from "../models/notifications.model";

const notifications: Notification[] = [];

export const useNotifications = (token?: string) => {
  const liveNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.liveNotifications
  );
  const liveNotificationsRef = useRef<NotificationView[]>([]);
  liveNotificationsRef.current = [...liveNotifications];
  const dispatch = useDispatch();
  
  const updateNotifications = (message: IMessage) => {
    const newNotification = JSON.parse(message.body);
    dispatch(dataInitial.liveNotifications([newNotification, ...liveNotificationsRef.current]));

    if (document.hidden && newNotification.id !== localStorage.getItem("notification")) {
      localStorage.setItem("notification", newNotification.id);
      setTimeout(() => {
        localStorage.removeItem("notification");
      }, 1000);
      const notification = new Notification("Новое уведомление на портале Loyalty Labs", {
        icon: "/notification-icon.png",
        body: newNotification.content,
        tag: newNotification.id,
      });
      notification.onclick = (event: Event) => {
        event.preventDefault();
        window.open("/notifications");
      };
      notifications.push(notification);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        notifications.forEach(notification => notification.close());
      }
    });
  }, []);

  useEffect(() => {
    if (token) {
      notificationsController
        .notifications()
        .then((res) => {
          dispatch(dataInitial.liveNotifications(res.data.items));
        });
      notificationsController
        .notifications(true)
        .then((res) => {
          dispatch(dataInitial.seenNotifications(res.data.items));
        });
    }
    return () => {
      dispatch(dataInitial.liveNotifications([]));
      dispatch(dataInitial.seenNotifications([]));
    };
    /* eslint-disable */
  }, [token]);

  return updateNotifications;
};

export default useNotifications;