import React, { FC } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface CloseButtonProps {
  handleClose: () => void;
  style?: any;
}

export const CloseButton: FC<CloseButtonProps> = ({ handleClose, style }) => {
  return (
    <IconButton
      size="small"
      color="secondary"
      onClick={handleClose}
      sx={{ p: 0, ...style }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
