import React, { FC } from "react";

import CommunityContextProvider from "./CommunityContextProvider/CommunityContextProvider";
import CommunityContent from "./CommunityContent/CommunityContent";

export const Community: FC = () => {
  return (
    <CommunityContextProvider>
      <CommunityContent />
    </CommunityContextProvider>
  );
};

export default Community;
