import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { PersonView } from "../../../../models/profile.models";
import { TypeMembersModal } from "../../CommunityHeader/CommunityHeader";
import { PersonCommunityView } from "../../../../models/community.models";
import CheckboxMember from "../../../common/CheckboxMember/CheckboxMember";

interface CheckboxMemberProps {
  userInfo: PersonView | PersonCommunityView;
  setIdList: Dispatch<SetStateAction<string[]>>;
  typeModal: TypeMembersModal;
  disabled?: boolean;
  idList: string[];
  isGroupMember?: boolean;
}

export const MemberWrapper: FC<CheckboxMemberProps> = ({
  userInfo,
  setIdList,
  typeModal,
  disabled,
  idList,
  isGroupMember,
}) => {
  const isViewType = typeModal === "view";
  const [checked, setChecked] = useState<boolean>(isGroupMember ? true : idList.includes(userInfo.id));

  const addUsers = (checked: boolean) => {
    if (checked) {
      setIdList((prev) => [...new Set([...prev, userInfo.id])]);
    } else {
      setIdList((prev) => prev.filter((id) => id !== userInfo.id));
    }
  };

  const deleteUsers = (checked: boolean) => {
    if (checked) {
      setIdList((prev) => prev.filter((id) => id !== userInfo.id));
    } else {
      setIdList((prev) => [...new Set([...prev, userInfo.id])]);
    }
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (!isViewType) {
      addUsers(event.target.checked);
    } else {
      deleteUsers(event.target.checked);
    }
  };

  return (
    <CheckboxMember
      userInfo={userInfo}
      disabled={disabled}
      checked={checked}
      handleCheckbox={handleCheckbox}
    />
  );
};

export default MemberWrapper;
