import { manageDataInitial } from "../actions/manageData";
import { MANAGE_DATA } from "../constants/actions";
import { ReturnActionsTypes } from "../store";

type ManageData = {
  deleteFiles: string[];
};

const initialState: ManageData = { deleteFiles: [] };

export type manageDataType = ReturnActionsTypes<typeof manageDataInitial>;

export const manageDataReducer = (
  state = initialState,
  action: manageDataType
) => {
  switch (action.type) {
    case MANAGE_DATA.DELETE_FILES:
      return {
        ...state,
        deleteFiles: action.payload,
      };
    default:
      return state;
  }
};
