export const communityRole = (role: string) => {
  switch (role) {
    case "MEMBER":
      return (role = "Участник");
    case "ADMIN":
      return (role = "Администратор");
    case "OWNER":
      return (role = "Владелец");
    default:
      return role;
  }
};
