import React, {useContext} from "react";
import {ReviewContext} from "../RewievContextProvider/ReviewContextProvider";
import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {editPersonSelect} from "../../../reducer/selectors/personSelectors";
import {PersonView} from "../../../models/profile.models";

const styleCreateButton = {
    width: 'max-content',
    display: 'flex',
    justifyContent: 'flex-end',
}

export const CreateReview = () => {
    const {editableAccess, handleReviewModal} = useContext(ReviewContext)
    const user: PersonView = useAppSelector(editPersonSelect)

    return (
        <>
            {
                editableAccess && !user.blocked &&
                <CustomButton
                    styles={styleCreateButton}
                    color="secondary"
                    text={'Назначить ревью'}
                    startIcon={<AddIcon/>}
                    onClick={handleReviewModal}
                    fullWidth={false}
                />
            }
        </>
    )
}