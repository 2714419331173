import { apiUrlPortal } from "../constants/apiUrl";
import { CreateCommunityRequest } from "../types/CreateCommunityRequest.type";
import {
  DeleteAttachmentsRequest,
  UpdateRecordRequest,
} from "../models/record.models";
import jwtInterceoptor from "./jwtInterceptor";

type membersBody = { personId: string; role: string };

export const communityController = {
  //Communities
  community: async (id: string): Promise<any> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/community/${id}`);
  },
  allCommunities: async (page?: number, size?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/community?sort=createdAt,desc&page=${page ?? 0}&size=${
        size ?? 5
      }`
    );
  }, //only for admin
  communitiesByPerson: async (page?: number, size?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/community/personal?sort=community.name,asc&page=${
        page ?? 0
      }&size=${size ?? 5}`
    );
  },
  newCommunity: async (body: CreateCommunityRequest): Promise<any> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/community`, body);
  },
  editCommunity: async (
    id: string,
    body: CreateCommunityRequest
  ): Promise<any> => {
    return await jwtInterceoptor.put(`${apiUrlPortal}/community/${id}`, body);
  },
  avatarCommunity: async (id: string, formData: any): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/${id}/avatar`,
      formData
    );
  },
  deleteCommunity: async (id: string): Promise<any> => {
    return await jwtInterceoptor.delete(`${apiUrlPortal}/community/${id}`);
  },
  leaveCommunity: async (id: string): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/leave?communityId=${id}`
    );
  },
  joinCommunity: async (id: string): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/join?communityId=${id}`
    );
  },
  publicCommunities: async (page?: number, size?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/community/public?sort=createdAt,desc&page=${
        page ?? 0
      }&size=${size ?? 5}`
    );
  },

  //Members
  allMembers: async (
    id: string,
    page?: number,
    size?: number,
    search?: string
  ): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/community/${id}/members?page=${page ?? 0}&size=${
        size ?? ""
      }&search=${search ?? ""}`
    );
  },
  addMembers: async (id: string, body: membersBody[]): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/${id}/members`,
      body
    );
  },
  deleteMembers: async (id: string, body: any): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/community/${id}/members`,
      { data: body }
    );
  },
  roles: async (id: string, body: membersBody): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/${id}/members/roles`,
      body
    );
  },
  newOwnerCommunity: async (
    communityId: string,
    ownerId: string
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/${communityId}/members/roles/owner?personId=${ownerId}`
    );
  },

  //Messages
  messages: async (id: string, page?: number, size?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/community/${id}/messages?sort=date,desc&page=${
        page ?? 0
      }&size=${size ?? ""}`
    );
  },
  newMessage: async (id: string, body: any): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/community/${id}/messages`,
      body
    );
  },
  editMessage: async (
    communityId: string,
    messageId: string,
    body: UpdateRecordRequest
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/${communityId}/messages/${messageId}`,
      body
    );
  },
  deleteMessage: async (
    communityId: string,
    messageId: string
  ): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/community/${communityId}/messages/${messageId}`
    );
  },
  addAttachments: async (
    communityId: string,
    messageId: string,
    body: FormData
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/community/${communityId}/messages/${messageId}/attachments`,
      body
    );
  },
  deleteAttachments: async (
    communityId: string,
    messageId: string,
    body: DeleteAttachmentsRequest
  ): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/community/${communityId}/messages/${messageId}/attachments`,
      { data: body }
    );
  },
};
