import React, { FC } from "react";

import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { PickersActionBar } from "@mui/x-date-pickers/PickersActionBar";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import moment from "moment";

import { inputHoverStyle } from "../../../../constants/inputHoverStyle";

interface CalendarTimePickerProps {
  value: string;
  handleTimeChange: (startTime: string) => void;
  disabled: boolean,
  title: string,
}
export const CalendarTimePicker: FC<CalendarTimePickerProps> = ({
  value,
  handleTimeChange,
  disabled,
  title,
}) => {
  const theme = useTheme();

  const timePickerStyle = {
    ...inputHoverStyle,
    borderRadius: 16,
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    ".MuiOutlinedInput-root": {
      pl: "10px",
      fontSize: "16px",
    },
    height: "50px",
    width: "100px",
    justifyContent: "center",
    padding: "0px",
  };

  return (
    <MobileTimePicker
      value={moment(value, "HH:mm")}
      onChange={(value) => handleTimeChange(value!.format("HH:mm"))}
      renderInput={(params) => <TextField {...params} sx={timePickerStyle} />}
      disabled={disabled}
      toolbarTitle={title}
      components={{
        ActionBar: (params) => <PickersActionBar {...params} sx={{
          "& button": {
            borderRadius: "60px",
            padding: "10px 12px",
            fontSize: "14px",
            backgroundColor: "rgba(51, 146, 255, 0.25)",
            lineHeight: "20px",
            color: "transparent",
          },
          "& button:first-of-type:after": {
            color: "rgba(24, 132, 255, 1)",
            marginLeft: "-44px",
            content: '"Отмена"',
          },
          "& button:hover": {
            backgroundColor: "rgba(51, 146, 255, 0.4)",
          },
          "& button:last-of-type": {
            backgroundColor: "rgba(24, 132, 255, 1)",
            color: theme.palette.text.primary,
          },
        }} />
      }}
    />
  );
};

export default CalendarTimePicker;