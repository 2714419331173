import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useState,
} from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { authController } from "../../services/auth.controller";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface AppContextProviderProps {
  children: React.ReactNode;
}

type User = {
  sub: string;
  exp: number;
  role: string;
  firstName: string;
};

interface AppContextValue {
  user: User;
  passLogin: (username: string, password: string) => Promise<void>;
  linkLogin: (loginCode: string) => Promise<void>;
  logout: () => void;
  isMobile: boolean;
  totalGroups: number;
  setTotalGroups: Dispatch<SetStateAction<number>>;
  pageGroups: number;
  setPageGroups: Dispatch<SetStateAction<number>>;
  totalGroupList: number;
  setTotalGroupList: Dispatch<SetStateAction<number>>;
  pageGroupsList: number;
  setPageGroupList: Dispatch<SetStateAction<number>>;
}

let defaultValue: AppContextValue = {
  user: {
    sub: "",
    exp: 0,
    role: "",
    firstName: "",
  },
  passLogin: async (username: string, password: string) => {},
  linkLogin: async (loginCode: string) => {},
  logout: () => {},
  isMobile: false,
  totalGroups: 0,
  setTotalGroups: () => {},
  pageGroups: 0,
  setPageGroups: () => {},
  totalGroupList: 0,
  setTotalGroupList: () => {},
  pageGroupsList: 0,
  setPageGroupList: () => {},
};
export const AppContext = createContext(defaultValue);

export const AppContextProvider: FC<AppContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<any>(() => {
    if (localStorage.getItem("REACT_TOKEN_AUTH_KEY")) {
      let tokens = JSON.parse(
        localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
      );
      return jwt_decode(tokens.accessToken);
    }
    return null;
  });

  const navigate = useNavigate();

  const handleLogin = (apiResponse: any) => {
    localStorage.setItem(
      "REACT_TOKEN_AUTH_KEY",
      JSON.stringify(apiResponse.data)
    );
    setUser(jwt_decode(apiResponse.data.accessToken));
    navigate("/feed");
  };
  const passLogin = async (username: string, password: string) => {
    const apiResponse = await authController.login(username, password);
    handleLogin(apiResponse);
  };
  const linkLogin = async (loginCode: string) => {
    const apiResponse = await authController.linkLogin(loginCode);
    handleLogin(apiResponse);
  };

  const logout = async () => {
    localStorage.removeItem("REACT_TOKEN_AUTH_KEY");
    localStorage.removeItem("userId");
    localStorage.removeItem("feedId");
    localStorage.removeItem("article");
    localStorage.removeItem("groupId");
    localStorage.removeItem("profile");
    setUser(null);
    navigate("/");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const [totalGroups, setTotalGroups] = useState<number>(0);
  const [pageGroups, setPageGroups] = useState<number>(0);

  const [totalGroupList, setTotalGroupList] = useState<number>(0);
  const [pageGroupsList, setPageGroupList] = useState<number>(0);

  return (
    <AppContext.Provider
      value={{
        user,
        passLogin,
        linkLogin,
        logout,
        isMobile,
        totalGroups,
        setTotalGroups,
        pageGroups,
        setPageGroups,
        totalGroupList,
        setTotalGroupList,
        pageGroupsList,
        setPageGroupList,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
