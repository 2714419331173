type NullableNumber<T> = T | null;

enum RatingValue {
    Middle = 3,
    High = 7,
}

const colors = {
    negative: 'rgba(203, 225, 255, 0.17)',
    low: 'rgba(224, 63, 72, 1)',
    middle: 'rgba(242, 153, 74, 1)',
    high: 'rgba(55, 189, 93, 1)',
};

export const getColorByValue = (value: NullableNumber<number>): string => {
    if (value) {
        if (value < 0) return colors.negative
        if (value <= RatingValue.Middle) return colors.low
        if (value > RatingValue.Middle && value <= RatingValue.High) return colors.middle
        return colors.high
    } else return colors.negative
};