export const searchFIO = (eventValue: string, additionalFilters?: string) => {
  const FIO = eventValue.split(" ");
  if (FIO.length === 1) {
    return `${additionalFilters},(name:${FIO[0]}%2Bsurname:${FIO[0]})`;
  }
  if (FIO.length === 2) {
    return `${additionalFilters},(name:${FIO[0]},surname:${FIO[1]}%2Bname:${FIO[1]},surname:${FIO[0]})`;
  }
};

export const searchFIOMemberCommunity = (
  eventValue: string,
  additionalFilters?: string
) => {
  const FIO = eventValue.split(" ");
  if (FIO.length === 1) {
    return `${additionalFilters},(person.name:${FIO[0]}%2Bperson.surname:${FIO[0]})`;
  }
  if (FIO.length === 2) {
    return `${additionalFilters},(person.name:${FIO[0]},person.surname:${FIO[1]}%2Bperson.name:${FIO[1]},person.surname:${FIO[0]})`;
  }
};
