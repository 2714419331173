import React, { FC, useRef, SetStateAction, Dispatch, useEffect } from "react";
import moment from "moment";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../common/Inputs/InputLine/InputLine";
import InputFilled from "../../common/Inputs/InputFilled/InputFilled";
import EditAvatar from "./EditAvatar/EditAvatar";
import DatePickerCustom from "../../common/Inputs/DatePickerCustom/DatePickerCustom";
import { UserInfo } from "../../../types/UserInfo.types";
import { checkLanguage } from "../../../constants/errorText";
import { roles } from "../../../constants/role";
import { checkLetters } from "../../../operation/checkLetters";
import Gender from "../../common/Gender/Gender";
import { regEx } from "../../../constants/regEx";
import CityCombobox from "./CityCombobox/CityCombobox";
import Job from "./Job/Job";

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  newAvatar: File | null;
  setNewAvatar: Dispatch<SetStateAction<File | null>>;
  setIsSaveDisabled: Dispatch<SetStateAction<boolean>>;
}

export const PersonalInfo: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  newAvatar,
  setNewAvatar,
  setIsSaveDisabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const currentUserRole = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).role;

  const isSaveDisabled = useRef<any>({});
  const handleInputCheck = (inputName: string, value: string) => {
    if (inputName !== "description") {
      //description doesn't have restrictions
      isSaveDisabled.current[inputName as keyof UserInfo] =
        checkLetters(value) || regEx.isEmpty.test(value);
      setIsSaveDisabled(Object.values(isSaveDisabled.current).includes(true));
    }
  };

  useEffect(() => {
    isSaveDisabled.current.name = checkLetters(personData.name);
    isSaveDisabled.current.surname = checkLetters(personData.surname);
    isSaveDisabled.current.patronymic = checkLetters(personData.patronymic);
    isSaveDisabled.current.dob = personData.dob === "Invalid date";
    setIsSaveDisabled(Object.values(isSaveDisabled.current).includes(true));
    /* eslint-disable */
  }, []);

  const handleChangeInputText =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPersonData({ ...personData, [prop]: event.target.value });
      handleInputCheck(prop, event.target.value);
    };

  const handleChangeDate = (newValue?: string | null) => {
    const newDate = moment(newValue, "DD.MM.YYYY");
    setPersonData({
      ...personData,
      dob: newDate.format("YYYY-MM-DD"),
    });
    isSaveDisabled.current.dob = !newDate.isValid() || newDate.isAfter();
    setIsSaveDisabled(Object.values(isSaveDisabled.current).includes(true));
  };

  const handleAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setNewAvatar(file || null);
  };
  const disableEditInfo = currentUserRole === roles.user;
  const data = [
    {
      name: "Аватар",
      element: (
        <EditAvatar avatarHandler={handleAvatar} newAvatar={newAvatar} />
      ),
    },
    {
      name: "Фамилия",
      element: (
        <InputLine
          value={personData?.surname}
          onChange={handleChangeInputText("surname")}
          error={isSaveDisabled.current.surname}
          helperText={checkLanguage}
          disabled={disableEditInfo}
        />
      ),
    },
    {
      name: "Имя",
      element: (
        <InputLine
          value={personData?.name}
          onChange={handleChangeInputText("name")}
          error={isSaveDisabled.current.name}
          helperText={checkLanguage}
          disabled={disableEditInfo}
        />
      ),
    },
    {
      name: "Отчество",
      element: (
        <InputLine
          value={personData?.patronymic}
          onChange={handleChangeInputText("patronymic")}
          error={isSaveDisabled.current.patronymic}
          helperText={checkLanguage}
          disabled={disableEditInfo}
        />
      ),
    },
    {
      name: "Должность и Департамент",
      element: (
        <Job
          personData={personData}
          setPersonData={setPersonData}
          disabled={disableEditInfo}
        />
      ),
    },
    {
      name: "Дата рождения",
      element: (
        <DatePickerCustom
          value={personData?.dob}
          styles={{
            width: isMobile ? "100%" : "48%",
            pr: "8px",
          }}
          onChange={handleChangeDate}
        />
      ),
    },
    {
      name: "Пол",
      element: (
        <Gender
          personData={personData}
          setPersonData={setPersonData}
          disabled={disableEditInfo}
        />
      ),
    },
    {
      name: "Город",
      element: (
        <CityCombobox
          personData={personData}
          setPersonData={setPersonData}
          disabled={disableEditInfo}
          width={isMobile ? "100%" : "48%"}
        />
      ),
    },
    {
      name: "О себе",
      element: (
        <InputFilled
          multiline
          value={personData?.description}
          handleValue={handleChangeInputText("description")}
        />
      ),
    },
  ];
  return (
    <Stack spacing={4}>
      {data.map((item, index) => (
        <InputWrapper key={index} title={item.name} element={item.element} />
      ))}
    </Stack>
  );
};

export default PersonalInfo;
