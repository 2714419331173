import React, { FC } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Groups from "../../LeftSidebar/CommunityList/CommunityList";
import Navigation from "../../LeftSidebar/Navigation/Navigation";
import BugReport from "../../../BugReport/BugReport";

export const RemainingMenu: FC = () => {
  return (
    <Container>
      <Typography variant="h4" component="h4" mb={1}>
        Ещё
      </Typography>
      <Navigation forRemainingMenu />
      <BugReport type="mobile" />
      <Groups forRemainingMenu />
    </Container>
  );
};

export default RemainingMenu;
