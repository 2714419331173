import React, { FC } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadProgress: FC = () => {
  return (
    <Box display="flex" justifyContent="center" my={2} width="100%">
      <CircularProgress
        color="inherit"
        aria-describedby="loading"
        aria-busy={true}
      />
    </Box>
  );
};
