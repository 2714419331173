import React, { FC, useContext, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import moment from "moment";

import { theme } from "../../../constants/theme";
import { roles } from "../../../constants/role";
import { AppContext } from "../../shared/AppContextProvider";
import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";

import { calendarInitial } from "../../../actions/calendar";
import { calendarController } from "../../../services/calendar.controller";
import { dayEventsColors } from "../CalendarDay/CalendarDay";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";
import EventModalContent from "../CalendarEventCreator/EventModalContent/EventModalContent";
import ConfirmModal from "../../common/Modals/ConfirmModal/ConfirmModal";

interface CalendarPopoverProps {
  calendarEvent: any;
  closePopover: () => void;
}

export const CalendarPopover: FC<CalendarPopoverProps> = ({
  calendarEvent,
  closePopover,
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [sendIsDisabled, setSendIsDisabled] = useState<boolean>(false);

  const forRepeatedEvent = useRef<boolean>();
  const confirmModalText = useRef<string>("");

  const { user } = useContext(AppContext);
  const notUserRole = user.role !== roles.user;

  const { onCalendarError: handleError } = useContext(CalendarContext);

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleCancel = () => {
    setOpenModal(false);
    closePopover();
  };

  const handleConfirmModal = () => {
    setOpenConfirmModal((prev) => !prev);
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleSend = () => {
    calendarController
      .editEvent(calendarEvent, forRepeatedEvent.current)
      .then(() => {
        calendarController
          .allEvents()
          .then((res) => {
            dispatch(calendarInitial.allEvents(res.data));
          })
          .catch(() => {
            handleError();
          });
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        handleCancel();
      });
  };

  const handleDelete = () => {
    calendarController
      .deleteEvent(calendarEvent.id, forRepeatedEvent.current)
      .then(() => {
        calendarController
          .allEvents()
          .then((res) => {
            dispatch(calendarInitial.allEvents(res.data));
          })
          .catch(() => {
            handleError();
          });
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        closeConfirmModal();
      });
  };

  const styleMainBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: "30px",
    marginTop: "6px",
    gap: "6px",
  };

  const styleCard = {
    minWidth: 280,
    height: "auto",
    backgroundColor: "#212633",
  };

  const styleContent = {
    marginTop: "-12px",
  };

  const boxIconStyle = {
    bgcolor: dayEventsColors[calendarEvent.calendarEventType],
    height: "16px",
    width: "16px",
    ml: "-26px",
    mr: "12px",
    borderRadius: "5px",
  };

  const modalButtons: React.ReactNode = (
    <ModalButtons
      nameButtonSend="Сохранить"
      handleSend={handleSend}
      handleCancel={handleCancel}
      justifyContent="end"
      disableSend={sendIsDisabled}
    />
  );

  const popoverButtons = [
    {
      name: "Редактировать",
      action: () => {
        forRepeatedEvent.current = false;
        handleModal();
      },
    },
    {
      name: "Удалить",
      action: () => {
        forRepeatedEvent.current = false;
        confirmModalText.current = "Данное событие будет удалено.";
        handleConfirmModal();
      },
    },
    ...calendarEvent.repeatId ? [
      {
        name: "Редактировать цепочку событий",
        action: () => {
          forRepeatedEvent.current = true;
          handleModal();
        },
      },
      {
        name: "Удалить цепочку событий",
        action: () => {
          forRepeatedEvent.current = true;
          confirmModalText.current = "Данная цепочка событий будет удалена.";
          handleConfirmModal();
        },
      },
    ] : [],
  ];

  return (
    <Card sx={styleCard}>
      <CardContent sx={styleContent}>
        <Box sx={styleMainBox}>
          <Box display="flex" alignItems="center" mt="12px" width="100%">
            <Box {...boxIconStyle} />
            <Box
              display="flex"
              alignItems="center"
              mt="1px"
              width="100%"
              justifyContent="space-between"
            >
              <Typography variant="subtitle2" mr="42px">
                {calendarEvent.name}
              </Typography>
              {notUserRole && (
                <>
                  <EditPopover buttonsData={popoverButtons} />
                  <DialogWrapper
                    openModal={openModal}
                    handleModal={handleCancel}
                    width="862px"
                    contentDividers
                    stylesContent={{ padding: "0px", borderTop: "none" }}
                    buttons={modalButtons}
                  >
                    <EventModalContent
                      calendarEvent={calendarEvent}
                      setSendIsDisabled={setSendIsDisabled}
                      forRepeatedEvent={forRepeatedEvent.current}
                    />
                  </DialogWrapper>
                </>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            width="80%"
            marginLeft="2px"
          >
            <Typography
              variant="body1"
              color={theme?.palette?.text?.secondary}
              sx={{ "&:first-letter": { textTransform: "uppercase" } }}
            >
              {moment(calendarEvent.date, "DD-MM-YYYY").format("dddd, Do MMMM")}
            </Typography>
            <Typography variant="body1" color={theme?.palette?.text?.secondary}>
              {calendarEvent.description}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        handleModal={handleConfirmModal}
        nameConfirmButton="Удалить"
        handleConfirm={handleDelete}
        text={confirmModalText.current}
      />
    </Card>
  );
};

export default CalendarPopover;
