import React, { FC } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ListItem } from "../../../../types/ListItem";

interface ComboboxProps {
  options: ListItem[];
  value: ListItem | null;
  onChange: (event: any, newValue: ListItem | null) => void;
  placeholder: string;
  styles?: any;
  inputValue?: string;
  onInputChange?: (
    e: React.SyntheticEvent,
    value: string,
    reason: string
  ) => void;
  handleScrollBottom?: (event: any) => void;
  disabled?: boolean;
}

export const Combobox: FC<ComboboxProps> = ({
  options,
  value,
  onChange,
  placeholder,
  styles,
  inputValue,
  onInputChange,
  handleScrollBottom,
  disabled,
}) => {
  const validValue = value ? value : { id: "", name: "" };

  const popperStyle = {
    backgroundColor: "#212633",
  };

  const textFieldStyle = {
    "& .MuiInput-root": {
      fontSize: "16px",
    },
    "& .MuiInput-root:before": {
      borderBottom: "1px solid rgba(241, 247, 255, 0.12)",
    },
    "& .MuiInput-root:hover:not(.Mui-disabled):before, &:after": {
      borderBottom: "1px solid rgba(229, 235, 242, 0.65)",
    },
    "& .MuiInput-root:after": {
      borderWidth: "1px",
    },

    "& .MuiInput-root > .MuiAutocomplete-endAdornment svg": {
      color: "rgba(229, 235, 242, 0.65)",
    },
    "& .MuiInput-root > .MuiAutocomplete-endAdornment button:hover": {
      backgroundColor: "inherit",
    },
    ...styles,
  };

  return (
    <Autocomplete
      disablePortal
      fullWidth
      id="combobox"
      options={[validValue, ...options]}
      filterSelectedOptions
      getOptionLabel={(option: ListItem) => option.name || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          sx={textFieldStyle}
          placeholder={placeholder}
        />
      )}
      componentsProps={{
        paper: {
          sx: popperStyle,
        },
      }}
      ListboxProps={{ onScroll: handleScrollBottom, sx: { height: "350px" } }}
    />
  );
};

export default Combobox;
