import React, { FC, useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import PersonsTable from "../PersonsTable/PersonsTable";
import { profilesInitial } from "../../../actions/profiles";
import { personController } from "../../../services/person.controller";
import CustomToggleButtons from "../../common/Buttons/CustomToggleButtons/CustomToggleButtons";
import CompanySearch from "../CompanySearch/CompanySearch";
import {
  UserFilter,
  initialFiltersValue,
} from "../../../types/UserFilters.types";
import { UserStatus } from "../../../types/UserStatus.types";
import { CompanyContext } from "../CompanyContext/CompanyContextProvider";
import { AppContext } from "../../shared/AppContextProvider";
import { roles } from "../../../constants/role";

export const CompanyContent: FC = () => {
  const dispatch = useDispatch();
  const { user } = useContext(AppContext);
  const isUserRole = user.role === roles.user;

  const {
    toggleItems,
    status,
    setStatus,
    searchStatus,
    setTotalRecords,
    setCurrentPage,
    setIsLoading,
    currentPage,
    sortingState,
    companyInitialState,
  } = useContext(CompanyContext);

  const handleToggle = (value: UserStatus) => {
    setStatus(value);
    dispatch(profilesInitial.allProfiles([]));
    setTotalRecords(0);
    setCurrentPage(0);
    setFiltersValue(initialFiltersValue);
    setSearchFilters("");
  };
  const [filtersValue, setFiltersValue] = useState<UserFilter>(
    companyInitialState.isPreserved ? companyInitialState.filtersValue : initialFiltersValue
  );

  const [searchFilters, setSearchFilters] = useState<string>(
    companyInitialState.isPreserved ? companyInitialState.searchFilters : ""
  );
  const requestSearch = searchStatus + searchFilters;

  const getFeedRecords = () => {
    setIsLoading(true);
    personController
      .allPersons(
        requestSearch,
        sortingState.length > 0 ? `${sortingState[0].field},${sortingState[0].sort}` : undefined,
        currentPage,
      )
      .then((res) => {
        dispatch(profilesInitial.allProfiles(res.data.items));
        setTotalRecords(res.data.totalItems);
      })
      .catch(() => {
        dispatch(profilesInitial.allProfiles([]));
      })
      .finally(() => setIsLoading(false))
      .then(companyInitialState.shouldGetData ? null : () => {
        setTimeout(() => {
          companyInitialState.shouldGetData = true;
          const mainScrollElement = document.getElementById("main-scroll");
          mainScrollElement?.scroll({
            top: companyInitialState.coordToScrollTo,
            left: 0,
            behavior: "smooth",
          });
        });
      });
  };

  useEffect(() => () => {
    companyInitialState.filtersValue = filtersValue;
    companyInitialState.searchFilters = searchFilters;
    /* eslint-disable-next-line */
  }, [filtersValue, searchFilters]);

  useEffect(() => {
    getFeedRecords();
    /* eslint-disable-next-line */
  }, [searchStatus, searchFilters, currentPage, sortingState]);

  return (
    <>
      <Container>
        <Box display="flex" height="40px" mb={3}>
          <CompanySearch
            filtersValue={filtersValue}
            setFiltersValue={setFiltersValue}
            setSearchFilters={setSearchFilters}
          />
        </Box>
        {!isUserRole && (
          <Box display="flex" justifyContent="space-between" mb={3}>
            <CustomToggleButtons
              items={toggleItems}
              onChange={handleToggle}
              currentValue={status}
            />
          </Box>
        )}
        <PersonsTable />
      </Container>
    </>
  );
};

export default CompanyContent;
