export const inputHoverStyle = {
  backgroundColor: "rgba(203, 225, 255, 0.17)",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  boxSizing: "border-box",
  "&:hover": {
    border: "1px solid rgba(241, 247, 255, 0.12)",
  },
  "&:focus-within, &:active": {
    border: "1px solid rgba(24, 132, 255, 1)",
    backgroundColor: "rgba(185, 215, 255, 0.08)",
  },
};
