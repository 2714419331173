import React, { FC } from "react";

import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { inputHoverStyle } from "../../../constants/inputHoverStyle";

interface LoginInputProps {
  type: string;
  htmlFor: string;
  label: string;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  endAdornment?: React.ReactNode;
  helpText?: React.ReactNode;
  error?: boolean;
  placeholder?: string;
}

export const LoginInput: FC<LoginInputProps> = ({
  type,
  htmlFor,
  label,
  value,
  handleChange,
  endAdornment,
  helpText,
  error,
  placeholder,
}) => {
  const inputStyle = {
    ...inputHoverStyle,
    borderRadius: 24,
    fontSize: 16,
    paddingRight: 2,
    height: 48,
    border: "1px solid transparent",
    "& > input": {
      paddingLeft: "20px",
    },
    "&.Mui-error": {
      border: "1px solid rgba(224, 63, 72, 1)",
    },
    //for chrome when autofill input
    "input:-webkit-autofill,input:-webkit-autofill:hover, input:-webkit-autofill:focus":
      {
        border: "none",
        WebkitTextFillColor: "rgba(230, 237, 245, 1)",
        WebkitBoxShadow: "none",
        transition: "background-color 5000s ease-in-out 0s",
        caretColor: "rgba(230, 237, 245, 1)",
      },
  };

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel
        shrink
        htmlFor={htmlFor}
        color="secondary"
        sx={{ position: "inherit", mb: 1, transform: "none" }}
      >
        {label}
      </InputLabel>
      <InputBase
        type={type}
        id={htmlFor}
        onChange={handleChange}
        sx={inputStyle}
        value={value}
        endAdornment={endAdornment}
        error={error}
        placeholder={placeholder}
      />
      {helpText}
    </FormControl>
  );
};
