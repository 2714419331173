import React, { FC, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import { DesktopPopover } from "../../common/Modals/EditPopover/DesktopPopover/DesktopPopover";

interface NotificationsPopoverProps {
  buttonAction: () => void;
  buttonText: string;
  buttonDisabled: boolean;
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = ({
  buttonAction,
  buttonText,
  buttonDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "notifications-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    return setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    return setAnchorEl(null);
  };

  const handleButton = () => {
    buttonAction();
    handleClose();
  };

  const buttonStyle = {
    color: "rgba(230, 237, 245, 1)",
    justifyContent: "flex-start",
    borderRadius: 0,
    p: "12px",
    "&:hover": {
      backgroundColor: "rgba(51, 146, 255, 0.25)",
    },
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  };

  const popoverButton = (
    <Button sx={buttonStyle} onClick={handleButton} disabled={buttonDisabled}>
      {buttonText}
    </Button>
  );

  return (
    <>
      <IconButton
        color="secondary"
        aria-describedby={id}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ width: "24px", height: "24px" }} />
      </IconButton>
      <DesktopPopover
        popoverButtons={popoverButton}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isOpen={isOpen}
        id={id}
      />
    </>
  );
};

export default NotificationsPopover;