import bold from "./format_bold_white_18dp.svg";
import italic from "./format_italic_white_18dp.svg";
import underline from "./format_underlined_white_18dp.svg";
import strikethrough from "./strikethrough_s_white_18dp.svg";
import unordered from "./list_white_18dp.svg";
import ordered from "./format_list_numbered_white_18dp.svg";
import indent from "./format_indent_increase_white_18dp.svg";
import outdent from "./format_indent_decrease_white_18dp.svg";
import left from "./format_align_left_white_18dp.svg";
import right from "./format_align_right_white_18dp.svg";
import center from "./format_align_center_white_18dp.svg";
import justify from "./format_align_justify_white_18dp.svg";
import link from "./insert_link_white_18dp.svg";
import unlink from "./link_off_white_18dp.svg";
import history from "./history_white_18dp.svg";
import update from "./update_white_18dp.svg";
import palette from "./palette_white_18dp.svg";
import file from "./insert_drive_file_white_18dp.svg";

const editorIcons = {
  bold,
  italic,
  underline,
  strikethrough,
  unordered,
  ordered,
  indent,
  outdent,
  left,
  right,
  center,
  justify,
  link,
  unlink,
  history,
  update,
  palette,
  file,
};

export default editorIcons;
