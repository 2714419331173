import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import UserHeader from "./UserHeader/UserHeader";
import MainInfo from "./MainInfo/MainInfo";
import Contacts from "./Contacts/Contacts";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import Roles from "../common/Roles/Roles";
import BlockUserButton from "./BlockUserButton/BlockUserButton";
import { PersonView } from "../../models/profile.models";
import { UserInfo } from "../../types/UserInfo.types";
import BottomActions from "../common/BottomActions/BottomActions";
import ModalButtons from "../common/Buttons/ModalButtons/ModalButtons";
import { personController } from "../../services/person.controller";
import { profilesInitial } from "../../actions/profiles";
import Snackbars from "../common/Snackbars/Snackbars";
import { roles } from "../../constants/role";
import { companyInitialState } from "../Company/CompanyContext/CompanyContextProvider";

export const UserEditing: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = localStorage.getItem("userId") || "";
  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const isCurrentUser = userId === currentUser.id;

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [editData, setEditData] = useState<UserInfo>({
    name: "",
    surname: "",
    patronymic: "",
    blocked: false,
    jobTitle: "",
    gender: "",
    dob: "",
    description: "",
    email: "",
    phone: "",
    telegram: "",
    city: { id: "", name: "" },
    department: "",
    role: "",
  });

  const [newAvatar, setNewAvatar] = useState<File | null>(null);

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

  useEffect(() => {
    personController.person(userId).then((res) => {
      dispatch(profilesInitial.editProfile(res.data));
      setEditData({
        name: res.data.name ?? "",
        surname: res.data.surname ?? "",
        patronymic: res.data.patronymic ?? "",
        blocked: res.data.blocked ?? false,
        jobTitle: res.data.job?.title?.name ?? "",
        gender: res.data.gender ?? "",
        dob: res.data.dob ?? "",
        description: res.data.description ?? "",
        email: res.data.contact?.email ?? "",
        phone: res.data.contact?.phone ?? "",
        telegram: res.data.contact?.telegram ?? "",
        city: res.data.city ?? { id: "", name: "" },
        department: res.data.job?.department?.name ?? "",
        role: res.data.role.role ?? "",
      });
    });
    /* eslint-disable */
  }, [userId]);
  const userData: PersonView = useAppSelector(
    (state) => state.profileReducer.editProfile
  );
  const isUserRole = currentUser.role === roles.user;
  const isAdminRole = currentUser.role === roles.admin;

  const profileData = [
    {
      title: "Основная информация",
      data: <MainInfo userId={userData.id} />,
    },
    {
      title: "Роли",
      data: (
        <Roles
          personData={editData}
          setPersonData={setEditData}
          disabled={!isAdminRole || isCurrentUser}
        />
      ),
    },
    {
      title: "Личные данные",
      data: (
        <PersonalInfo
          personData={editData}
          setPersonData={setEditData}
          newAvatar={newAvatar}
          setNewAvatar={setNewAvatar}
          setIsSaveDisabled={setIsSaveDisabled}
        />
      ),
    },
    {
      title: "Контакты",
      data: (
        <Contacts
          personData={editData}
          setPersonData={setEditData}
          setIsSaveDisabled={setIsSaveDisabled}
        />
      ),
    },
  ];

  const updatePersons = () => {
    personController.allPersons(`id!:${currentUser.id}`).then((res) => {
      dispatch(profilesInitial.filteredProfiles(res.data.items));
    });
  };
  const handleCancel = () => {
    if (companyInitialState.isForUserEditing) {
      companyInitialState.isPreserved = true;
      companyInitialState.shouldGetData = false;
    }
    navigate(-1);
  };

  useEffect(() => () => {
    companyInitialState.isForUserEditing = false;
  }, []);

  const afterSuccessUpdate = (result: any) => {
    isCurrentUser
      ? dispatch(profilesInitial.profile(result.data))
      : updatePersons();
  };

  const putData = () => {
    const updAvatar = () => {
      if (newAvatar) {
        const formData = new FormData();
        formData.append("file", newAvatar);
        return personController.avatar(userData.id, formData);
      }
    };
    const updJob = () => {
      if (!isUserRole) {
        return personController.job(userData.id, {
          department: editData.department,
          grade: null,
          title: editData.jobTitle,
        });
      }
    };
    const updRole = () => {
      if (isAdminRole && !isCurrentUser) {
        return personController.role(userData.id, editData.role);
      }
    };

    if (isUserRole) {
      personController
        .editByUSer(userData.id, editData)
        .then((res) => {
          Promise.resolve(updAvatar()).then((result) => {
            afterSuccessUpdate(result ? result : res);
            handleCancel();
          });
        })
        .catch(() => setOpenSnackbar(true));
    } else {
      personController
        .editPerson(userData.id, editData)
        .then((result) => {
          Promise.all([updAvatar(), updJob(), updRole()])
            .then((res) => {
              afterSuccessUpdate(res[0] ? res[0] : result);
              handleCancel();
            })
            .catch(() => setOpenSnackbar(true));
        })
        .catch(() => setOpenSnackbar(true));
    }
  };

  return (
    <>
      <Stack spacing={4} sx={{ m: isMobile ? 2 : undefined }}>
        <UserHeader profileInfo={userData} />
        {profileData
          .filter((item) => {
            if (!isCurrentUser) {
              return item.title !== "Основная информация";
            }
            return item;
          })
          .map((item, index) => (
            <Box key={index}>
              <Typography variant="h4" mb={4}>
                {item?.title}
              </Typography>
              {item?.data}
            </Box>
          ))}
        {isMobile && !editData.blocked && <BlockUserButton userId={userData.id} />}
      </Stack>
      <BottomActions>
        <ModalButtons
          nameButtonSend="Сохранить"
          handleSend={putData}
          handleCancel={handleCancel}
          disableSend={isSaveDisabled}
          fullWidth
          justifyContent="center"
          additionalStyles={{
            width: "45%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        />
      </BottomActions>
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="left"
      />
    </>
  );
};

export default UserEditing;
