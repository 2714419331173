import React, { FC } from "react";
import {
  SxProps,
  SystemCssProperties,
  Theme,
  ResponsiveStyleValue,
} from "@mui/system";
import Chip from "@mui/material/Chip";

interface LoginErrorProps {
  label: string;
}
export const LoginError: FC<LoginErrorProps> = ({ label }) => {
  const style = (
    theme:
      | SxProps<Theme>
      | SystemCssProperties<Theme>
      | ResponsiveStyleValue<any>
      | undefined
  ) => ({
    borderRadius: "24px",
    fontSize: 16,
    height: "initial",
    textAlign: "center",
    width: "100%",
    backgroundColor: theme.palette.error.dark,
    color:theme.palette.error.main,
    "& > span": {
      textOverflow: "unset",
      whiteSpace: "break-spaces",
      lineHeight: "24px",
      minHeight: "48px",
      padding: "10px",
    },
  });

  return <Chip label={label} color="error" sx={style} />;
};
