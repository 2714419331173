import React, { FC, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import { Stack, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../common/Inputs/InputLine/InputLine";
import AvatarSelection from "./AvatarSelection/AvatarSelection";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import { communityController } from "../../../services/community.controller";
import { communitiesInitial } from "../../../actions/communities";
import Snackbars from "../../common/Snackbars/Snackbars";
import { CommunityView } from "../../../models/community.models";
import { CreateCommunityRequest } from "../../../types/CreateCommunityRequest.type";
import { CommunityContext } from "../CommunityContextProvider/CommunityContextProvider";
import { AppContext } from "../../shared/AppContextProvider";
import { roles } from "../../../constants/role";
import CheckboxWrapper from "../../common/CheckboxWrapper/CheckboxWrapper";

interface CommunityModalProps {
  type: "create" | "edit";
  openModal: boolean;
  handleModal: () => void;
}
export const CommunityModal: FC<CommunityModalProps> = ({
  type,
  openModal,
  handleModal,
}) => {
  const dispatch = useDispatch();
  const isCreateType = type === "create";
  const { newAvatar, setNewAvatar } = useContext(CommunityContext);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    setTotalGroups,
    pageGroups,
    setTotalGroupList,
    pageGroupsList,
    user,
  } = useContext(AppContext);

  const [name, setName] = useState<string>("");
  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivate(event.target.checked);
  };

  const community: CommunityView = useAppSelector(
    (state) => state.communitiesReducer.community
  );

  useEffect(() => {
    if (!isCreateType) {
      setName(community.name);
      setIsPrivate(community.privateCommunity);
    }
  }, [isCreateType, community]);

  const handleCancel = () => {
    handleModal();
    setName("");
    setIsPrivate(false);
    setNewAvatar(null);
  };

  const updCommunityLists = () => {
    const updCommunitiesByPerson = communityController
      .communitiesByPerson(0, (pageGroups + 1) * 5)
      .then((res) => {
        dispatch(communitiesInitial.communitiesByPerson(res.data.items));
        setTotalGroups(res.data.totalItems);
      });
    const updOtherCommunities = () => {
      if (user.role === roles.admin) {
        communityController
          .allCommunities(0, (pageGroupsList + 1) * 5)
          .then((res) => {
            dispatch(communitiesInitial.communitiesList(res.data.items));
            setTotalGroupList(res.data.totalItems);
          });
      } else {
        communityController
          .publicCommunities(0, (pageGroupsList + 1) * 5)
          .then((res) => {
            dispatch(communitiesInitial.communitiesList(res.data.items));
            setTotalGroupList(res.data.totalItems);
          });
      }
    };
    Promise.all([updCommunitiesByPerson, updOtherCommunities()]).catch(() => {
      setOpenSnackbar(true);
    });
  };

  const serviceCreate = (body: CreateCommunityRequest) => {
    communityController
      .newCommunity(body)
      .then(() => {
        setTimeout(updCommunityLists, 1000);
        handleCancel();
      })
      .catch(() => setOpenSnackbar(true));
  };

  const serviceEdit = (body: CreateCommunityRequest) => {
    const updCommunity = communityController
      .editCommunity(community.id, body)
      .then(() => {
        const updCommunity = () =>
          communityController
            .community(community.id)
            .then((res) => dispatch(communitiesInitial.community(res.data)))
            .catch(() => setOpenSnackbar(true));
        setTimeout(updCommunity, 1000); //we have to wait because if we do it immediately, avatar won't update
      });
    const updAvatar = () => {
      if (newAvatar) {
        const formData = new FormData();
        formData.append("file", newAvatar);
        return communityController.avatarCommunity(community.id, formData);
      }
    };
    Promise.all([updCommunity, updAvatar()])
      .then(() => {
        setTimeout(updCommunityLists, 1000);
        handleCancel();
      })
      .catch(() => setOpenSnackbar(true));
  };

  const handleSave = () => {
    const body: CreateCommunityRequest = {
      description: "",
      name: name,
      privateCommunity: isPrivate,
    };
    if (isCreateType) {
      serviceCreate(body);
    } else {
      serviceEdit(body);
    }
  };

  const buttons = (
    <ModalButtons
      nameButtonSend="Сохранить"
      handleSend={handleSave}
      handleCancel={handleCancel}
      fullWidth
      disableSend={!name}
      justifyContent="flex-end"
    />
  );

  return (
    <>
      <DialogWrapper
        openModal={openModal}
        handleModal={handleModal}
        width="532px"
        contentDividers
        buttons={buttons}
      >
        <Stack gap={4}>
          <Typography variant="h4">
            {isCreateType ? "Создать группу" : "Группа"}
          </Typography>
          <InputWrapper
            flexDirection="column"
            title="Название группы"
            titleColor="secondary"
            element={
              <InputLine
                value={name}
                onChange={handleName}
                placeholder="Новая группа"
                autoFocus
              />
            }
          />
          {!isCreateType && (
            <InputWrapper
              flexDirection="column"
              title="Аватар группы"
              titleColor="secondary"
              element={<AvatarSelection avatar={community?.avatar} />}
            />
          )}
          <FormControlLabel
            control={
              <CheckboxWrapper onChange={handleCheckbox} checked={isPrivate} />
            }
            label={
              <Typography
                component="span"
                variant="body2"
                color={(theme) => theme.palette.text.secondary}
              >
                Закрытая группа
              </Typography>
            }
          />
        </Stack>
      </DialogWrapper>
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </>
  );
};

export default CommunityModal;
