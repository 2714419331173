import {AppState} from "../../store";

export const personSelect = (state: AppState) =>
    state.profileReducer.profile

export const editPersonSelect = (state: AppState) =>
    state.profileReducer.editProfile

export const personsSelect = (state: AppState) =>
    state.profileReducer.allProfiles

export const totalCountProfilesSelect = (state: AppState) =>
    state.profileReducer.totalCountProfiles