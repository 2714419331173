import React, { FC, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { MenuListItems } from "../../../../../types/MenuListItems.types";
import { MenuList } from "../../../../common/MenuList/MenuList";
import { theme } from "../../../../../constants/theme";
import { communityController } from "../../../../../services/community.controller";
import { communitiesInitial } from "../../../../../actions/communities";
import { CommunityListView } from "../../../../../models/community.models";
import ShowMoreButton from "../../../../KnowledgeBase/ShowMoreButton/ShowMoreButton";
import { AppContext } from "../../../../shared/AppContextProvider";
import { roles } from "../../../../../constants/role";

interface ListOfCommunitiesProps {
  forRemainingMenu?: boolean;
  communitiesItems: (comm: CommunityListView[]) => MenuListItems;
  onClickCommunity: (id: string) => void;
}

export const ListOfCommunities: FC<ListOfCommunitiesProps> = ({
  forRemainingMenu,
  communitiesItems,
  onClickCommunity,
}) => {
  const dispatch = useDispatch();
  const {
    totalGroupList,
    setTotalGroupList,
    pageGroupsList,
    setPageGroupList,
    user,
  } = useContext(AppContext);

  const isAdminUser = user.role === roles.admin;

  const communities: CommunityListView[] = useAppSelector(
    (state) => state.communitiesReducer.communitiesList
  );

  useEffect(() => {
    setPageGroupList(0);
    if (isAdminUser) {
      communityController.allCommunities().then((res) => {
        dispatch(communitiesInitial.communitiesList(res.data.items));
        setTotalGroupList(res.data.totalItems);
      });
    } else {
      communityController.publicCommunities().then((res) => {
        dispatch(communitiesInitial.communitiesList(res.data.items));
        setTotalGroupList(res.data.totalItems);
      });
    }
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (pageGroupsList > 0) {
      if (isAdminUser) {
        communityController.allCommunities(pageGroupsList).then((res) => {
          dispatch(
            communitiesInitial.communitiesList([
              ...communities,
              ...res.data.items,
            ])
          );
          setTotalGroupList(res.data.totalItems);
        });
      } else {
        communityController.publicCommunities(pageGroupsList).then((res) => {
          dispatch(
            communitiesInitial.communitiesList([
              ...communities,
              ...res.data.items,
            ])
          );
          setTotalGroupList(res.data.totalItems);
        });
      }
    }
    /* eslint-disable */
  }, [pageGroupsList]);
  /* eslint-enable */

  const showMore = totalGroupList > communities.length;

  const getNextCommunities = () => {
    setPageGroupList((prev) => prev + 1);
  };

  return (
    <Box>
      <Typography
        variant="body1"
        component="span"
        textAlign="center"
        color={theme?.palette?.text?.secondary}
      >
        {isAdminUser ? "Все группы" : "Публичные группы"}
      </Typography>
      <MenuList
        items={communitiesItems(communities)}
        forRemainingMenu={forRemainingMenu ?? false}
        onClick={onClickCommunity}
      />
      {showMore && <ShowMoreButton onClick={getNextCommunities} />}
    </Box>
  );
};

export default ListOfCommunities;
