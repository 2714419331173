import React, { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { theme } from "../../../../constants/theme";

interface NoGoalsProp {
  text: string;
  button?: React.ReactElement;
}
export const NoGoals: FC<NoGoalsProp> = ({ text, button }) => {
  const styleBox = {
    borderRadius: "12px",
    border: "1px solid rgba(241, 247, 255, 0.12)",
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignItems: "center",
  };

  return (
    <Box sx={styleBox}>
      <AutoAwesomeIcon color="secondary" />
      <Typography variant="subtitle1" color={theme?.palette?.text?.secondary}>
        {text}
      </Typography>
      {button}
    </Box>
  );
};

export default NoGoals;
