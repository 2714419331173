import React, { FC, useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import InputBase from "@mui/material/InputBase";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Paper } from "@mui/material";

import TextEditor from "../TextEditor/TextEditor";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import { kbController } from "../../../services/kb.controller";
import { dataInitial } from "../../../actions/data";
import { convertContent } from "../ArticlePage/ArticlePage";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import { ArticleMainData } from "../../../types/ArticleMainData.types";
import { UpdateKbArticleRequest } from "../../../models/kb.models";
import { regEx } from "../../../constants/regEx";

interface CreateArticleModalProps {
  open: boolean;
  handleModal: () => void;
  type: "create" | "edit";
}

export const CreateArticleModal: FC<CreateArticleModalProps> = ({
  open,
  handleModal,
  type,
}) => {
  const dispatch = useDispatch();
  const { handleSnackbar } = useContext(KbContext);
  const isCreate = type === "create";
  const articleMainData: ArticleMainData = JSON.parse(
    localStorage.getItem("article") || "{}"
  );

  const [title, setTitle] = useState<string>("");
  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const currentArticle = useAppSelector((state) => state.dataReducer.article);

  const convertedContent = convertContent(currentArticle?.content);

  const [editorState, setEditorState] = useState<EditorState>(
    isCreate
      ? EditorState.createEmpty()
      : EditorState.createWithContent(
          convertFromRaw(JSON.parse(convertedContent))
        )
  );

  const convertEditorState = () => {
    return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  };

  useEffect(() => {
    if (!isCreate && currentArticle) {
      setTitle(currentArticle.name);
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(convertedContent))
        )
      );
    }
  }, [isCreate, currentArticle, convertedContent]);

  const editArticleService = (body: UpdateKbArticleRequest) => {
    kbController
      .editArticle(articleMainData.sectionId, articleMainData.articleId, body)
      .then(() => {
        if (isCreate) {
          kbController.sections().then((res) => {
            dispatch(dataInitial.articleSections(res.data.items));
          });
        } else {
          kbController
            .oneArticle(articleMainData.articleId, articleMainData.sectionId)
            .then((res) => dispatch(dataInitial.article(res.data)));
        }
        handleModal();
      })
      .catch(() => {
        handleSnackbar("error");
        setEditorState(EditorState.createEmpty());
        setTitle("");
        handleModal();
      });
  };

  const postArticle = () => {
    const body: UpdateKbArticleRequest = {
      content: convertEditorState(),
      name: title,
    };
    editArticleService(body);
  };

  const disableSendButton =
    regEx.isEmpty.test(title) ||
    regEx.isEmpty.test(editorState.getCurrentContent().getPlainText());

  const handleCloseModal = () => {
    if (isCreate) {
      kbController.deleteArticle(
        articleMainData.articleId,
        articleMainData.sectionId
      );
    }
    handleModal();
  };

  const createModalButtons = (
    <ModalButtons
      handleCancel={handleCloseModal}
      handleSend={postArticle}
      fullWidth
      nameButtonSend="Сохранить"
      justifyContent="flex-end"
      disableSend={disableSendButton}
    />
  );

  return (
    <DialogWrapper
      openModal={open}
      handleModal={handleModal}
      buttons={createModalButtons}
      contentDividers
    >
      <InputBase
        placeholder="Введите название статьи"
        fullWidth
        multiline
        sx={{
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "40px",
          mb: "16px",
        }}
        value={title}
        onChange={handleTitle}
      />
      <Paper
        sx={{
          boxShadow: "none",
          border: "1px solid rgba(241, 247, 255, 0.12)",
        }}
      >
        <TextEditor
          readOnly={false}
          setEditorState={setEditorState}
          editorState={editorState}
        />
      </Paper>
    </DialogWrapper>
  );
};

export default CreateArticleModal;
