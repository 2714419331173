export const dbCalendarEventCategories = [
  "CORPORATE_EVENT",
  "HOLIDAY",
  "BIRTHDAY",
];

export const calendarEventCategories = [
  "События компании",
  "Гос. праздники",
  "Сотрудники",
];

export const dbCalendarEventRecurrences = [
  "CENTURIES",
  "DAYS",
  "WEEKS",
  "MONTHS",
  "YEARS",
  "Каждый будний день",  // TODO: No corresponding API endpoint
];

export const calendarEventRecurrences = [
  "Не повторять",
  "Ежедневно",
  "Еженедельно",
  "Ежемесячно",
  "Каждый год",
  // "Каждый будний день",
];