import React, { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import EditPopover from '../../common/Modals/EditPopover/EditPopover'
import { personController } from '../../../services/person.controller'
import { roles } from '../../../constants/role'
import { profilesInitial } from '../../../actions/profiles'
import { CompanyContext } from '../CompanyContext/CompanyContextProvider'
import { getUsersRightSidebar } from '../../../operation/getUsersRightSidebar'
import { textSnackbars } from '../../../constants/snackBarNotifications'

interface ActionButtonProps {
  id: string
}
export const ActionButton: FC<ActionButtonProps> = ({ id }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const loggedUser = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}')

  const handleEdit = (userId: string) => {
    localStorage.setItem('userId', userId)
    companyInitialState.isForUserEditing = true
    companyInitialState.coordToScrollTo = document.getElementById('main-scroll')!.scrollTop
    navigate('/profile/edit')
  }

  const { status, setTotalRecords, currentPage, handleSnackbar, setSortingState, companyInitialState } =
    useContext(CompanyContext)
  const isActive = status === 'Активные'

  const blockService = (userId: string) => {
    personController
      .blockPerson(userId)
      .then(() => {
        personController.allPersons('blocked:false', undefined, currentPage).then((res) => {
          dispatch(profilesInitial.allProfiles(res.data.items))
          setTotalRecords(res.data.totalItems)
          setSortingState([])
        })
      })
      .then(() => {
        handleSnackbar('success', textSnackbars.blockUser)
      })
      .then(() => getUsersRightSidebar(loggedUser.id, dispatch))
      .catch(() => {
        handleSnackbar('error')
      })
  }
  const unblockService = (userId: string) => {
    personController
      .unblockPerson(userId)
      .then(() => {
        personController.allPersons('blocked:true', undefined, currentPage).then((res) => {
          dispatch(profilesInitial.allProfiles(res.data.items))
          setTotalRecords(res.data.totalItems)
          setSortingState([])
        })
      })
      .then(() => {
        handleSnackbar('success', textSnackbars.unblockUser)
      })
      .then(() => getUsersRightSidebar(loggedUser.id, dispatch))
      .catch(() => {
        handleSnackbar('error')
      })
  }
  const handleBlock = (userId: string) => {
    isActive ? blockService(userId) : unblockService(userId)
  }

  const popoverButtons = [
    { name: 'Редактировать', action: () => handleEdit(id) },
    {
      name: isActive ? 'Заблокировать' : 'Разблокировать',
      action: () => handleBlock(id),
    },
  ]

  return <>{loggedUser?.role !== roles.user && <EditPopover buttonsData={popoverButtons} />}</>
}

export default ActionButton
