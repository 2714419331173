export const secondaryButton = {
  backgroundColor: "rgba(51, 146, 255, 0.25)",
  color: "rgba(24, 132, 255, 1)",
  "&:hover": {
    backgroundColor: "rgba(51, 146, 255, 0.4)",
  },
};

export const iconButton = {
  backgroundColor: "rgba(203, 225, 255, 0.17)",
  "&:hover": {
    backgroundColor: "rgba(185, 215, 255, 0.08)",
  },
  color: "rgba(229, 235, 242, 0.65)",
};

export const iconButtonBackground = {
  borderRadius: "180px",
  minWidth: "auto",
  padding: "8px",
  "& .MuiButton-startIcon": {
    margin: 0,
  },
};
