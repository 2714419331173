import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import { Box, Typography } from "@mui/material";

import CustomToggleButtons from "../../common/Buttons/CustomToggleButtons/CustomToggleButtons";
import { AchievStatus } from "../../../types/Achievements.types";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import { useAchievementList } from "../../../hooks/useAchievementList";
import AchievList from "../AchievLists/AchievList";
import AchievActions from "./Actions/AchievActions";
import { AppContext } from "../../shared/AppContextProvider";
import { AchievContext } from "../AchievContextProvider/AchievContextProvider";
import { achievementController } from "../../../services/achievement.controller";
import { achievementsInitial } from "../../../actions/achievements";
import ViewModal from "../Tasks/TaskModals/ViewModal/ViewModal";

export const AchievPage: FC = () => {
  const dispatch = useDispatch();
  const statusItems: AchievStatus[] = ["Активные", "Архив"];
  const [status, setStatus] = useState<AchievStatus>(statusItems[0]);
  const archived = status === "Архив" ? true : false;
  const handleActiveStatus = () => setStatus(statusItems[0]);

  const { isLoadList, isAddLoadList } = useAchievementList({
    archived: archived,
    updStatus: handleActiveStatus,
  });
  const { listSize, achivementAcces, handleSnackbar, userRole, isCurrentUser } =
    useContext(AchievContext);
  const { isMobile } = useContext(AppContext);

  const handleStatus = (value: AchievStatus) => {
    setStatus(value);
  };

  const actionButton = (
    <AchievActions archived={archived} fullWidth={isMobile && listSize > 0} />
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const path = useLocation().pathname;
  const pathParts = path.split("/");
  const achievementId = pathParts[pathParts.indexOf("achievements") + 1];
  const taskId = pathParts[pathParts.indexOf("tasks") + 1];

  const hasTask = window.location.href.includes("tasks");

  useEffect(() => {
    if (hasTask) {
      sessionStorage.setItem("achievementId", achievementId);
      achievementController
        .getTask(achievementId, taskId)
        .then((res) => {
          dispatch(achievementsInitial.achievementTask(res.data));
          handleModal();
        })
        .catch(() => handleSnackbar("error"));
    }
    /* eslint-disable-next-line */
  }, [hasTask]);

  return (
    <>
      {achivementAcces && (
        <Box>
          <Typography variant="h4" mb={2}>
            Цели
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <CustomToggleButtons
              items={
                userRole && !isCurrentUser ? [statusItems[0]] : statusItems
              }
              onChange={handleStatus}
              currentValue={status}
            />
            {listSize > 0 && !isLoadList && !isMobile && actionButton}
          </Box>
          <Box mt={2} mb={isMobile ? 2 : 0}>
            {isLoadList ? (
              <LoadProgress />
            ) : (
              <AchievList archived={archived} newAchievButton={actionButton} />
            )}
            {isAddLoadList ? <LoadProgress /> : null}
          </Box>
          {isMobile && listSize > 0 && actionButton}
        </Box>
      )}
      <ViewModal openModal={openModal} handleOpen={handleModal} />
    </>
  );
};

export default AchievPage;
