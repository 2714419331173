export const initialCommunities = {
  communitiesByPerson: [],
  messages: [],
  community: {
    avatar: {
      attachmentType: "",
      extension: "",
      id: "",
      location: "",
      name: "",
      size: 0,
    },
    createdAt: "",
    description: "",
    id: "",
    name: "",
    privateCommunity: false,
    role: "",
  },
  members: [],
  communitiesList: [],
};
