import React, { FC } from "react";

import Avatar from "@mui/material/Avatar";

interface LetterAvatarProps {
  userName?: string;
  style: any;
}

export const LetterAvatar: FC<LetterAvatarProps> = ({ userName, style }) => {
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        background: `linear-gradient(to bottom, rgba(255, 252, 252, 0.1),
        rgba(0, 0, 0, 0.5)) ${stringToColor(name)}`,
        ...style,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "rgba(230, 237, 245, 1)",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  return <Avatar {...stringAvatar(userName ?? " ")} />;
};

export default LetterAvatar;
