import React, { FC } from "react";

import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";

interface JoyHelpButtonProp {
  color: "secondary" | "primary";
  disabled?: boolean;
  fullWidth?: boolean;
}

export const JoyHelpButton: FC<JoyHelpButtonProp> = ({
  color,
  disabled,
  fullWidth,
}) => {
  const telegramUsername = ""; //wait telegram chatBot

  const handleButton = () => {
    window.open(`https://telegram.me/${telegramUsername}`, "_blank");
  };

  return (
    <CustomButton
      color={color}
      text="Написать в Telegram"
      onClick={handleButton}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  );
};

export default JoyHelpButton;
