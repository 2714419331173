import React from 'react';
import {Box, Typography} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {theme} from "../../../constants/theme";
import {CreateReview} from "./CreateReview";
import {ReviewCard} from './ReviewCard';
import {ReviewList} from "../../../models/review.models";
import EditReviewModal from "../EditReviewModal/EditReviewModal";

const styleBoxWrapper = {
    display: "flex", flexDirection: "column", gap: '8px'
}

const styleBox = {
    borderRadius: "12px",
    border: "1px solid rgba(241, 247, 255, 0.12)",
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: 2.4,
    alignItems: "center",
}

interface IProps {
    reviews: ReviewList[]
}

const ReviewCardWrapper = ({reviews}: IProps) => {
    const isReviewList = reviews.length === 0

    return (
        <>
            <Box sx={styleBoxWrapper}>
                {
                    isReviewList
                        ? <Box sx={styleBox}>
                            <AutoAwesomeIcon color="secondary"/>
                            <Typography variant="subtitle1" color={theme?.palette?.text?.secondary}>
                                Ревью не пройдено
                            </Typography>
                            <CreateReview/>
                        </Box>
                        : <>{reviews.map(el => <ReviewCard review={el} key={el.id}/>)}</>
                }
            </Box>
            <EditReviewModal/>
        </>
    );
};

export default ReviewCardWrapper;



