import React, { FC, useState, useEffect, MouseEvent } from "react";

import { useAppSelector } from "../../../hooks/useAppSelector";

import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";

import { reactionController } from "../../../services/reaction.controller";
import ReactionsButtons from "./ReactionsButtons/ReactionsButtons";

import type { SmileView } from "../../../models/reaction.model";
import type { ReactionView } from "../../../models/reaction.model";

interface ReactionsProps {
  reactionId: string;
  handleError: () => void;
}

export const Reactions: FC<ReactionsProps> = ({ reactionId, handleError }) => {
  const smiles: SmileView[] = useAppSelector(
    (state) => state.dataReducer.smiles
  );
  const [reactions, setReactions] = useState<ReactionView[]>([]);
  const smileButtonStyle = {
    backgroundColor: "rgba(19, 23, 34, 1)",
    ":hover": {
      backgroundColor: "rgba(24, 132, 255, 1)",
    },
  };

  const insertReactionButtonStyle = {
    width: "32px",
    height: "32px",
    alignSelf: "end",
  };

  useEffect(() => {
    reactionController
      .reactions(reactionId)
      .then((res) => {
        setReactions(res.data.items);
      })
      .catch(() => {
        handleError();
      });
    /* eslint-disable-next-line */
  }, [reactionId]);

  const handleSelect = (smile: SmileView) => {
    const changedReactionIndex = reactions.findIndex(
      (reaction) => reaction.smile.id === smile.id
    );
    if (!reactions[changedReactionIndex]?.pressed) {
      const changedReactions = [...reactions];
      if (changedReactionIndex !== -1) {
        changedReactions[changedReactionIndex].clickCount++;
        changedReactions[changedReactionIndex].pressed = true;
      } else {
        const newReaction: ReactionView = {
          clickCount: 1,
          pressed: true,
          smile: {
            id: smile.id,
            symbol: smile.symbol,
          },
        };
        changedReactions.push(newReaction);
      }
      setReactions(changedReactions);
      handleClose();
      reactionController.setReaction(reactionId, smile.id).catch(() => {
        handleError();
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? "reaction-popover" : undefined;

  return (
    <Box display="flex" alignItems="center">
      {reactions.length > 0 && (
        <ReactionsButtons
          reactionId={reactionId}
          reactions={reactions}
          setReactions={setReactions}
          handleError={handleError}
        />
      )}
      <IconButton
        aria-describedby={popoverId}
        aria-label="reaction"
        onClick={handleOpen}
        color="secondary"
        sx={insertReactionButtonStyle}
      >
        <InsertEmoticonIcon />
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            backgroundColor: "rgba(19, 23, 34, 1)",
            boxShadow: "none",
            borderRadius: "4px",
            maxWidth: { tablet: "448px" },
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          },
        }}
      >
        {smiles.map((smile) => (
          <Button
            key={smile.id}
            sx={smileButtonStyle}
            onClick={() => handleSelect(smile)}
          >
            {smile.symbol}
          </Button>
        ))}
      </Popover>
    </Box>
  );
};

export default Reactions;
