import React, { FC } from "react";

import Box from "@mui/material/Box";

interface BottomActionsProps {
  children: React.ReactNode;
}

export const BottomActions: FC<BottomActionsProps> = ({ children }) => {
  const style = {
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    borderTop: "1px solid rgba(185, 215, 255, 0.08)",
    backgroundColor: "background.default",
    zIndex: "10",
  };

  return (
    <Box p={2} sx={style}>
      {children}
    </Box>
  );
};

export default BottomActions;
