import {apiUrlPortal} from "../constants/apiUrl";
import axios from "axios";

export const authController = {
    login: async (username: string, password: string): Promise<any> => {
        const body = {login: username, password: password};
        return await axios.post(`${apiUrlPortal}/auth/login`, body);
    },
    token: async (refreshToken: string): Promise<any> => {
        const body = {refreshToken: refreshToken};
        return await axios.post(`${apiUrlPortal}/auth/token`, body);
    },
    refreshToken: async (refreshToken: string): Promise<any> => {
        const body = {refreshToken: refreshToken};
        return await axios.post(`${apiUrlPortal}/auth/refreshToken`, body);
    },
    loginNew: async (email: string): Promise<any> => {
        return await axios.post(`${apiUrlPortal}/persons/yandex`, {email});
    },
    linkLogin: async (loginToken: string): Promise<any> => {
        return await axios.post(`${apiUrlPortal}/auth/login/${loginToken}`);
    },
}