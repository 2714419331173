import React, { FC, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { NavLink } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { ReactComponent as FavoritesIcon } from "../../../assets/icons/favorites.svg";
import { ReactComponent as FavoritesIconActive } from "../../../assets/icons/favoritesActive.svg";
import IdeaLikes from "../IdeaLikes/IdeaLikes";
import { dataInitial } from "../../../actions/data";
import { ideasInitialFetchingStatus, IdeasContext } from "../IdeasContext/IdeasContextProvider";
import { ideasController } from "../../../services/ideas.controller";

import type { Idea as IdeaView } from "../../../types/Idea.type";

interface IdeaProps {
  ideaData: IdeaView;
}

const ideaColors = [
  "rgba(38, 80, 76, 1)",
  "rgba(42, 59, 32, 1)",
  "rgba(28, 48, 73, 1)",
  "rgba(71, 58, 29, 1)",
  "rgba(80, 42, 65, 1)",
];

const ideaTranslucentColors = [
  "rgba(38, 80, 76, 0.9)",
  "rgba(42, 59, 32, 0.9)",
  "rgba(28, 48, 73, 0.9)",
  "rgba(71, 58, 29, 0.9)",
  "rgba(80, 42, 65, 0.9)",
];

export const Idea: FC<IdeaProps> = ({
  ideaData,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const ideaColorIndex = useMemo(() => (
    Math.floor(Math.random() * ideaColors.length)
  ), []);

  const ideas: IdeaView[] = useAppSelector(
    (state) => state.dataReducer.ideas
  );

  const {
    onIdeasError: handleError,
  } = useContext(IdeasContext);

  const cardStyle = {
    borderRadius: "12px",
    height: isMobile ? "240px" : "260px",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "12px",
    backgroundColor: ideaColors[ideaColorIndex],
    textDecoration: "inherit",
    cursor: "default",
    "&:hover": {
      backgroundColor: ideaTranslucentColors[ideaColorIndex],
      ".favorite-idea-button": {
        visibility: "visible",
      },
    },
    ".favorite-idea-button": {
      visibility: { mobile: "visible", laptop: ideaData.favorite ? "visible" : "hidden" },
    },
  };

  const favoriteButtonStyle = {
    width: "40px",
    height: "40px",
    backgroundColor: "transparent",
    color: ideaData.favorite ? "rgba(24, 132, 255, 1)" : "rgba(229, 235, 242, 0.65)",
  };

  const cardClickHandler = () => {
    dispatch(dataInitial.idea(ideaData));
    sessionStorage.setItem("idea", JSON.stringify(ideaData));
    ideasInitialFetchingStatus.value = "none";
  };
  
  const favoritesButtonClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const newIdeas = structuredClone(ideas);
    const favoriteIdea = newIdeas.find(idea => idea.id === ideaData.id);
    favoriteIdea!.favorite = !ideaData.favorite;
    dispatch(dataInitial.ideas(newIdeas));
    favoriteIdea!.favorite ? (
      ideasController
        .markIdea(ideaData.id)
    ) : (
      ideasController
        .unmarkIdea(ideaData.id)
    ).catch(() => {
      handleError();
    });
  };

  return (
    <Card
      sx={cardStyle}
      component={NavLink}
      to={`/ideas/${ideaData.id}`}
      onClick={cardClickHandler}
    >
      <CardContent sx={{ p: "0px", display: "flex", justifyContent: "space-between", gap: "12px" }}>
        <Typography
          variant="h4"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isMobile ? 4 : 5,
            overflowWrap: "break-word",
          }}
        >
          <span style={{ cursor: "pointer" }}>{ideaData.header}</span>
        </Typography>
        <IconButton onClick={favoritesButtonClickHandler} sx={favoriteButtonStyle} className="favorite-idea-button">
          {ideaData.favorite ? <FavoritesIconActive /> : <FavoritesIcon />}
        </IconButton>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "start", p: "0px" }}>
        <IdeaLikes ideaData={ideaData} />
      </CardActions>
    </Card>
  );
};
export default Idea;