import React, { FC, SetStateAction, useState } from "react";

import Box from "@mui/material/Box";
import StoryGroupWrapper from "../StoryGroupWrapper/StoryGroupWrapper";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

import CreateModal from "./CreateModal/CreateModal";
import IconCustomButton from "../../../common/Buttons/IconCustomButton/IconCustomButton";

interface CreateStoryProps {
  setOpenSnackbar: SetStateAction<any>;
}
export const CreateStory: FC<CreateStoryProps> = ({ setOpenSnackbar }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => setOpenModal(!openModal);

  return (
    <>
      <StoryGroupWrapper colorBorder="secondary">
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          onClick={handleModal}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="12px"
          >
            <IconCustomButton
              icon={<AddIcon fontSize="small" />}
              styles={{ padding: "16px" }}
            />
            <Typography
              variant="body2"
              color={(theme) => theme?.palette?.text?.secondary}
            >
              Новая история
            </Typography>
          </Box>
        </Box>
        <CreateModal
          openModal={openModal}
          handleModal={handleModal}
          setOpenSnackbar={setOpenSnackbar}
        />
      </StoryGroupWrapper>
    </>
  );
};

export default CreateStory;
