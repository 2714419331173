import React, { FC, useContext, useState } from "react";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Droppable } from "react-beautiful-dnd";

import { Task } from "../Task/Task";
import { CreateTaskButton } from "../../CreateTaskButton/CreateTaskButton";
import { Column as ColumnType } from "../initialTaskTable";
import { TaskView } from "../../../../../models/achievement.models";
import { AppContext } from "../../../../shared/AppContextProvider";
import { AchievContext } from "../../../AchievContextProvider/AchievContextProvider";

interface ColumnProps {
  column: ColumnType;
  tasks: TaskView[];
  selectedItem?: number;
  index: number;
}

export const Column: FC<ColumnProps> = ({
  column,
  tasks,
  selectedItem,
  index,
}) => {
  const { isMobile } = useContext(AppContext);
  const { editableAcces } = useContext(AchievContext);

  const availableCreateButton = column.title === "Ожидает" && editableAcces;

  const [showButton, setShowButton] = useState<boolean>(false);

  const styleContainer = {
    "&:not(:last-child)": {
      borderRight: !isMobile
        ? "1px solid rgba(241, 247, 255, 0.12)"
        : undefined,
    },
    p: "4px",
    flexDirection: "column",
    flex: 1,
    display:
      selectedItem === undefined || index === selectedItem ? "flex" : "none",
  };

  const styleTaskList = {
    pt: "4px",
    flexGrow: 1,
  };

  return (
    <Grid
      item
      sx={styleContainer}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      <Typography
        p="4px 12px"
        variant="caption"
        color={(theme) => theme.palette.text.secondary}
      >
        {column.title}
      </Typography>
      <Droppable droppableId={column.id}>
        {(provided) => (
          <Box
            sx={styleTaskList}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {tasks.map((task: any, index: number) => (
              <Task task={task} key={task.id} index={index} />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      {availableCreateButton && <CreateTaskButton showButton={showButton} />}
    </Grid>
  );
};
