import React, { FC, SetStateAction } from "react";

import { LoginInput } from "../LoginInput/LoginInput";

interface EmailProps {
  value: string;
  setValue: SetStateAction<any>;
  placeholder?: string;
}

export const Email: FC<EmailProps> = ({ value, setValue, placeholder }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <LoginInput
      type="text"
      htmlFor="email"
      label="E-mail"
      value={value}
      handleChange={handleChange}
      placeholder={placeholder}
    />
  );
};
