import React, { FC, useContext, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";

import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import EditableModal from "../TaskModals/EditableModal/EditableModal";
import { AppContext } from "../../../shared/AppContextProvider";

interface CreateTaskButtonProps {
  showButton: boolean;
}
export const CreateTaskButton: FC<CreateTaskButtonProps> = ({ showButton }) => {
  const { isMobile } = useContext(AppContext);
  const sizeForTextButton = useMediaQuery("(min-width:1600px)");

  const styleButton = {
    visibility: isMobile || showButton ? "visible" : "hidden",
  };

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const handleModal = () => {
    setOpenCreateModal((prev) => !prev);
  };
  return (
    <>
      <CustomButton
        variant="text"
        text={sizeForTextButton || isMobile ? "Добавить задачу" : ""}
        startIcon={<AddIcon />}
        styles={styleButton}
        onClick={handleModal}
      />
      <EditableModal
        open={openCreateModal}
        handleOpen={handleModal}
        type="create"
      />
    </>
  );
};
