import { COMMUNITY } from "../constants/actions";
import { ReturnActionsTypes } from "../store";
import {
  CommunityListView,
  CommunityRecordView,
  CommunityView,
  PersonCommunityView,
} from "../models/community.models";
import { initialCommunities } from "./initialStates/initialCommunities";
import { communitiesInitial } from "../actions/communities";

type Communities = {
  communitiesByPerson: CommunityListView[];
  messages: CommunityRecordView[];
  community: CommunityView;
  members: PersonCommunityView[];
  communitiesList: CommunityListView[];
};

const initialState: Communities = initialCommunities;

export type communitiesType = ReturnActionsTypes<typeof communitiesInitial>;

export const communitiesReducer = (
  state = initialState,
  action: communitiesType
) => {
  switch (action.type) {
    case COMMUNITY.PERSON_COMMUNITIES:
      return { ...state, communitiesByPerson: action.payload };
    case COMMUNITY.MESSAGES:
      return { ...state, messages: action.payload };
    case COMMUNITY.COMMUNITY:
      return { ...state, community: action.payload };
    case COMMUNITY.MEMBERS:
      return { ...state, members: action.payload };
    case COMMUNITY.COMMUNITIES_LIST:
      return { ...state, communitiesList: action.payload };
    default:
      return state;
  }
};
