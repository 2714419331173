import React, { FC, SetStateAction, useContext } from "react";

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import EditorFile from "./EditorFile/EditorFile";
import CustomImageComponent from "./CustomImageComponent/CustomImageComponent";
import editorIcons from "../../../assets/icons/textEditor";
import { kbController } from "../../../services/kb.controller";
import { KbContext } from "../KbContextProvider/KbContextProvider";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.css"; //For customazing library components we have to use css styles
import { ArticleMainData } from "../../../types/ArticleMainData.types";

interface TextEditorProps {
  readOnly: boolean;
  editorState: EditorState;
  setEditorState: SetStateAction<any>;
}

export const TextEditor: FC<TextEditorProps> = ({
  readOnly,
  editorState,
  setEditorState,
}) => {
  const { handleSnackbar } = useContext(KbContext);
  const articleMainData: ArticleMainData = JSON.parse(
    localStorage.getItem("article") || "{}"
  );

  const editorStateChange = (state: EditorState) => {
    setEditorState(state);
  };

  const uploadImageCallBack = (file: File) => {
    //this logic is required for react-draft-wysiwyg library
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      kbController
        .attachmentsArticle(
          articleMainData.sectionId,
          articleMainData.articleId,
          formData
        )
        .then((res) => {
          resolve({
            data: {
              link: `/loyalty-portal/api/attachments/${res.data.attachments[0].name}${res.data.attachments[0].extension}`,
            },
          });
          formData.delete("file");
        })
        .catch(() =>
          reject(() => {
            handleSnackbar("error");
          })
        );
    });
  };

  const toolbarSettings = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "image",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
      bold: { icon: editorIcons.bold },
      italic: { icon: editorIcons.italic },
      underline: { icon: editorIcons.underline },
      strikethrough: { icon: editorIcons.strikethrough },
    },
    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    list: {
      options: ["unordered", "ordered", "indent", "outdent"],
      unordered: { icon: editorIcons.unordered },
      ordered: { icon: editorIcons.ordered },
      indent: { icon: editorIcons.indent },
      outdent: { icon: editorIcons.outdent },
    },
    textAlign: {
      options: ["left", "center", "right", "justify"],
      left: { icon: editorIcons.left },
      center: { icon: editorIcons.center },
      right: { icon: editorIcons.right },
      justify: { icon: editorIcons.justify },
    },
    colorPicker: {
      icon: editorIcons.palette,
      colors: [
        "rgb(97,189,109)",
        "rgb(26,188,156)",
        "rgb(84,172,210)",
        "rgb(44,130,201)",
        "rgb(147,101,184)",
        "rgb(71,85,119)",
        "rgb(204,204,204)",
        "rgb(65,168,95)",
        "rgb(0,168,133)",
        "rgb(61,142,185)",
        "rgb(41,105,176)",
        "rgb(85,57,130)",
        "rgb(40,50,78)",
        "rgb(0,0,0)",
        "rgb(247,218,100)",
        "rgb(251,160,38)",
        "rgb(235,107,86)",
        "rgb(226,80,65)",
        "rgb(163,143,132)",
        "rgb(239,239,239)",
        "rgb(255,255,255)",
        "rgb(250,197,28)",
        "rgb(243,121,52)",
        "rgb(209,72,65)",
        "rgb(184,49,47)",
        "rgb(124,112,107)",
        "rgb(209,213,216)",
        "inherit", //return w/o background
      ],
    },
    link: {
      showOpenOptionOnHover: true,
      defaultTargetOption: "_self",
      options: ["link", "unlink"],
      link: { icon: editorIcons.link },
      unlink: { icon: editorIcons.unlink },
    },
    image: {
      component: CustomImageComponent,
      icon: editorIcons.file,
      urlEnabled: false,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: uploadImageCallBack,
      previewImage: true,
      inputAccept:
        "image/*, .pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      alt: { present: false, mandatory: false },
    },
    history: {
      options: ["undo", "redo"],
      undo: { icon: editorIcons.history },
      redo: { icon: editorIcons.update },
    },
  };

  const toolbarStyle = {
    backgroundColor: "rgba(185, 215, 255, 0.08)",
    borderColor: "rgba(241, 247, 255, 0.12)",
  };

  const editorStyle = !readOnly
    ? {
        paddingLeft: "10px",
        paddingRight: "10px",
        minHeight: "250px",
        overflow: "hidden",
      }
    : undefined;

  const blockRendererFn = (contentBlock: any) => {
    const type = contentBlock.getType();

    if (type === "atomic") {
      const contentState = editorState.getCurrentContent();
      const entityKey = contentBlock.getEntityAt(0);

      const entity = entityKey ? contentState.getEntity(entityKey) : null;
      const data = entity?.getData();
      if (entity && entity.getType() === "IMAGE") {
        const src = data.src;
        return {
          component: EditorFile,
          editable: false,
          props: {
            src: src,
          },
        };
      }
    }
  };

  const hasListItems = (editorState: EditorState) => {
    return editorState
      .getCurrentContent()
      .getBlockMap()
      .some(
        (block) =>
          block?.getType() === "ordered-list-item" ||
          block?.getType() === "unordered-list-item"
      );
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={editorStateChange}
      editorStyle={editorStyle}
      toolbar={toolbarSettings}
      toolbarStyle={toolbarStyle}
      toolbarHidden={readOnly}
      readOnly={readOnly}
      placeholder={
        hasListItems(editorState) || readOnly
          ? undefined
          : "Начните вводить текст"
      }
      customBlockRenderFunc={blockRendererFn}
    />
  );
};

export default TextEditor;
