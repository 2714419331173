import React, { FC, useEffect, useContext, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { EditorState, convertFromRaw } from "draft-js";

import ArticleHeader from "./ArticleHeader/ArticleHeader";
import { kbController } from "../../../services/kb.controller";
import { dataInitial } from "../../../actions/data";
import { AppContext } from "../../shared/AppContextProvider";
import TextEditor from "../TextEditor/TextEditor";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import { ArticleMainData } from "../../../types/ArticleMainData.types";

//Check content because test data containce only string, may be later delete
export const convertContent = (articleContent: any) => {
  return articleContent?.includes("entityMap")
    ? articleContent
    : JSON.stringify({
        entityMap: {},
        blocks: [
          {
            key: "637gr",
            text: articleContent,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      });
};

export const ArticlePage: FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useContext(AppContext);

  const [showLoader, setShowLoader] = useState<boolean>(true);

  const article = useAppSelector((state) => state.dataReducer.article);

  const articleContent = convertContent(article?.content);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      convertFromRaw(JSON.parse(convertContent(articleContent)))
    )
  );
  const articleMainData: ArticleMainData = JSON.parse(
    localStorage.getItem("article") || "{}"
  );

  useEffect(() => {
    setShowLoader(true);
    kbController
      .oneArticle(articleMainData.articleId, articleMainData.sectionId)
      .then((res) => {
        dispatch(dataInitial.article(res.data));
        setShowLoader(false);
      });
    /* eslint-disable */
  }, [articleMainData.articleId]);

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(convertContent(articleContent)))
      )
    );
    /* eslint-disable */
  }, [articleContent]);

  return (
    <>
      <ArticleHeader />
      {showLoader ? (
        <LoadProgress />
      ) : (
        <Box paddingX={isMobile ? 2 : undefined}>
          <Typography variant="h4">{article?.name}</Typography>
          <TextEditor
            readOnly
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </Box>
      )}
      {/* <JoyHelpSection /> */}
      {/* TODO: wait telegram bot */}
    </>
  );
};

export default ArticlePage;
