import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceoptor from "./jwtInterceptor";

export const downloadController = {
  download: async (fileName: string): Promise<any> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/download/${fileName}`, {
      responseType: "blob",
    });
  },
};
