import React, { FC, useContext } from "react";
import { useDispatch } from "react-redux";

import { DragDropContext, DropResult } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";

import { Column } from "./Column/Column";
import { AchievContext } from "../../AchievContextProvider/AchievContextProvider";
import { achievementController } from "../../../../services/achievement.controller";
import { achievementsInitial } from "../../../../actions/achievements";
import { AppContext } from "../../../shared/AppContextProvider";
import { Dots } from "./Dots/Dots";

export const TaskTable: FC = () => {
  const dispatch = useDispatch();
  const {
    taskTable,
    setTaskTable,
    handleSnackbar,
    handleTasks,
    selectedDot,
    setSelectedDot,
  } = useContext(AchievContext);
  const { isMobile } = useContext(AppContext);

  const achievementId = sessionStorage.getItem("achievementId") || "";

  const updateTasks = () => {
    achievementController
      .tasks(achievementId)
      .then((res) => handleTasks(res.data.items))
      .catch(() => handleSnackbar("error"));
  };

  const updateTaskItem = (
    draggableId: string,
    destinationIndex: number,
    destinationDroppableId?: string,
    updAchiv?: boolean //for upd progress line
  ) => {
    const body = {
      sequence: destinationIndex,
      status: destinationDroppableId ?? null,
    };
    achievementController
      .moveTask(achievementId, draggableId, body)
      .then(() => {
        updateTasks();
        if (updAchiv) {
          achievementController
            .getAchiv(achievementId)
            .then((res) => {
              dispatch(achievementsInitial.achievement(res.data));
            })
            .catch(() => handleSnackbar("error"));
        }
      })
      .catch(() => handleSnackbar("error"));
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    // draggableId - task's id we are moved
    // source - task location where we started (sequence and column)
    // destination - task location where to moved (sequence and column)
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const columnStart = taskTable.columns[source.droppableId];
    const columnFinish = taskTable.columns[destination.droppableId];
    if (columnStart === columnFinish) {
      const newTaskIds = Array.from(columnStart.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...columnStart,
        taskIds: newTaskIds,
      };

      const newState = {
        ...taskTable,
        columns: {
          ...taskTable.columns,
          [newColumn.id]: newColumn,
        },
      };
      setTaskTable(newState);
      updateTaskItem(draggableId, destination.index);

      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(columnStart.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...columnStart,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(columnFinish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...columnFinish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...taskTable,
      columns: {
        ...taskTable.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setTaskTable(newState);
    updateTaskItem(
      draggableId,
      destination.index,
      destination.droppableId,
      destination.droppableId === "DONE" || source.droppableId === "DONE"
    );
  };

  const handleDotItem = (index: number) => {
    setSelectedDot(index);
  };
  const colums = taskTable.columnOrder.map((columnId, index) => {
    const column = taskTable.columns[columnId];
    const tasks = column.taskIds.map((taskId) => taskTable.tasks[taskId]);

    return (
      <Column
        key={index}
        column={column}
        tasks={tasks}
        selectedItem={isMobile ? selectedDot : undefined}
        index={index}
      />
    );
  });

  const styleGrid = {
    mt: 2,
    border: "1px solid rgba(241, 247, 255, 0.12)",
    borderRadius: "12px",
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container sx={styleGrid}>
        {colums}
      </Grid>
      {isMobile && <Dots index={selectedDot} handleIndex={handleDotItem} />}
    </DragDropContext>
  );
};
