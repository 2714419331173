import React, { FC } from "react";

import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import StoryGroupWrapper from "../StoryGroupWrapper/StoryGroupWrapper";
import UserAvatar from "../../../common/User/UserAvatar/UserAvatar";
import Confetti from "../../../common/Confetti/Confetti";
import { StoryGroupView } from "../../../../models/story.model";

interface CelebrationStoryGroupProps {
  type: "birthday" | "welcome";
  storyGroup: StoryGroupView;
  onClick?: () => void;
}

export const CelebrationStoryGroup: FC<CelebrationStoryGroupProps> = ({
  type,
  storyGroup,
  onClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("mobile"));

  const styleContainer = {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    height: "100%",
    px: isMobile ? "12px" : "24px",
    borderRadius: "12px",
  };

  const styleGroup = {
    flexWrap: "wrap",
    p: isMobile ? "10px" : "40px",
    justifyContent: "center",
    "& > .MuiAvatar-root": {
      backgroundColor: "rgba(55, 189, 93, 1)",
      color: "rgba(230, 237, 245, 1)",
    },
    "& > .MuiBox-root:nth-of-type(3)": {
      marginTop: "-7px",
    },
    "& > .MuiBox-root:nth-of-type(4)": {
      marginTop: "-7px",
      marginRight: "-7px",
    },
    "& .MuiAvatar-root": {
      border: "none",
    },
  };

  return (
    <StoryGroupWrapper colorBorder="primary" onClick={onClick}>
      <Confetti />
      <Paper sx={styleContainer}>
        <AvatarGroup max={3} sx={styleGroup}>
          {storyGroup.firstStories.map((item, index) => (
            <UserAvatar key={index} userInfo={item.author} />
          ))}
        </AvatarGroup>
        <Typography variant={isMobile ? "caption" : "subtitle1"} align="center">
          {type === "birthday"
            ? "Поздравляем в этом месяце!"
            : "Добро пожаловать!"}
        </Typography>
      </Paper>
    </StoryGroupWrapper>
  );
};

export default CelebrationStoryGroup;
