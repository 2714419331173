import React, { FC } from "react";

import { IconButton } from "@mui/material";

interface IconCustomButtonProps {
  icon: React.ReactNode;
  onClick?: () => void;
  styles?: any;
  disabled?: boolean;
  color?: "secondary" | "primary";
}

//with background color
export const IconCustomButton: FC<IconCustomButtonProps> = ({
  icon,
  onClick,
  styles,
  disabled,
  color = "primary",
}) => {
  const primaryButton = {
    backgroundColor: "#1884FF",
    color: "rgba(230, 237, 245, 1)",
    "&:hover": {
      backgroundColor: "rgba(26, 117, 222, 1)",
    },
  };
  const secondaryButton = {
    backgroundColor: "rgba(51, 146, 255, 0.25)",
    color: "rgba(24, 132, 255, 1)",
    backdropFilter: "blur(12.5px)",
    "&:hover": {
      backgroundColor: "rgba(51, 146, 255, 0.4)",
    },
  };
  const buttonStyles = {
    ...(color === "primary" && primaryButton),
    ...(color === "secondary" && secondaryButton),
    ...styles,
  };

  const styleButton = {
    borderRadius: "60px",
    padding: "8px",
    ...buttonStyles,
  };

  return (
    <IconButton
      size="small"
      color={color}
      onClick={onClick}
      sx={styleButton}
      disabled={disabled}
    >
      {icon}
    </IconButton>
  );
};

export default IconCustomButton;
