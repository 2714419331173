import React, { FC, useContext } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { PersonView } from "../../../models/profile.models";
import UserInfo from "../../common/User/UserInfo/UserInfo";
import ActionButton from "../ActionButton/ActionButton";
import RoleInfo from "../RoleInfo/RoleInfo";
import { CompanyContext } from "../CompanyContext/CompanyContextProvider";

export const PersonsTable: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const {
    setCurrentPage,
    currentPage,
    totalRecords,
    isLoading,
    sortingState,
    setSortingState,
    companyInitialState,
  } = useContext(CompanyContext);

  const handleEdit = (userId: string) => {
    localStorage.setItem("userId", userId);
    companyInitialState.isForUserEditing = true;
    companyInitialState.coordToScrollTo =
      document.getElementById("main-scroll")!.scrollTop;
  };

  const profilesRowsData: PersonView[] = useAppSelector(
    (state) => state.profileReducer.allProfiles
  );
  const profilesRows: PersonView[] =
    companyInitialState.isPreserved ? profilesRowsData : [];

  const columns: GridColDef[] = isMobile
    ? [
      {
        field: "info",
        flex: 1,
        headerClassName: "table-header--hide",
        renderCell: (params: GridRenderCellParams<Date>) => {
          return (
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box display="flex" flexDirection="column" gap={"12px"} style={{ cursor: "pointer" }} onClick={() => handleEdit(params.row.id)}>
                <UserInfo userInfo={params.row} withEllipsis={false} withNavigation />
                <RoleInfo data={params.row} />
              </Box>
              <ActionButton id={params.row.id} />
            </Box>
          );
        },
      },
    ]
    : [
      {
        field: "name",
        headerName: "Имя",
        flex: 1,
        headerClassName: "table-header",
        renderCell: (params: GridRenderCellParams<Date>) => {
          return (
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(params.row.id)}
            >
              <UserInfo userInfo={params.row} withEllipsis={false} withNavigation />
            </Box>
          );
          /* return <UserInfo userInfo={params.row} withEllipsis={false} withNavigation onClick={() => handleEdit(params.row.id)}/>; */
        },
      },
      {
        field: "department",
        headerName: "Департамент и роль",
        flex: 1,
        headerClassName: "table-header",
        sortable: false,
        renderCell: (params: GridRenderCellParams<Date>) => {
          return <RoleInfo data={params.row} />;
        },
      },
      {
        field: "hired",
        headerName: "Дата найма",
        flex: 1,
        headerClassName: "table-header",
        renderCell: (params: GridRenderCellParams<Date>) => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography>
                {moment(params.row.hired).format("DD.MM.YYYY")}
              </Typography>
              <ActionButton id={params.row.id} />
            </Box>
          );
        },
      },
    ];

  const pageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortingState(sortModel);
    setCurrentPage(0);
  };

  const tableStyle = {
    border: "none",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
      minHeight: "auto !important",
      maxHeight: "none !important",
      lineHeight: "normal !important",
      fontSize: "14px",
    },
    "& .MuiDataGrid-row": {
      minHeight: "auto !important",
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "background.paper",
    },
    "& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
      borderBottomColor: "rgba(59, 68, 81, 1) !important",
    },
    "& .MuiDataGrid-footerContainer": {
      display: !totalRecords || totalRecords <= 10 ? "none" : "block",
    },
    "& .MuiDataGrid-cell": {
      padding: "12px",
    },
    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within":
    {
      outline: "none",
    },
    "& .MuiDataGrid-cell:nth-of-type(1)": {
      borderTop: isMobile ? "1px solid rgba(59, 68, 81, 1)" : undefined,
    },
    "& .MuiDataGrid-virtualScroller": {
      mt: isMobile ? "0 !important" : undefined,
    },
  };

  const headerStyle = {
    "& .table-header": {
      backgroundColor: "background.paper",
      // height: "auto !important",
      padding: "10px 12px !important",
    },
    "& .table-header--hide": {
      visibility: "hidden",
    },
    "& .table-header:nth-of-type(1)": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      borderRight: "1px solid rgba(185, 215, 255, 0.08)",
    },
    "& .table-header:nth-of-type(3)": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      borderLeft: "1px solid rgba(185, 215, 255, 0.08)",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "space-between",
    },
  };

  const displayLabelCount = ({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) => {
    return `${from}–${to} из ${count !== -1 ? count : `больше, чем ${to}`}`;
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          ...headerStyle,
        }}
      >
        <DataGrid
          rows={profilesRows}
          columns={columns}
          autoHeight
          disableColumnMenu
          sx={tableStyle}
          rowCount={totalRecords}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowHeight={() => "auto"}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          paginationMode="server"
          page={currentPage}
          onPageChange={pageChange}
          loading={isLoading}
          componentsProps={{
            pagination: {
              labelDisplayedRows: displayLabelCount,
            },
          }}
          localeText={{
            noResultsOverlayLabel: "Нет данных",
            noRowsLabel: "Нет данных",
            columnHeaderSortIconLabel: "Сортировка",
          }}
          sortModel={sortingState}
        />
      </Box>
    </>
  );
};

export default PersonsTable;
