import React, { FC } from "react";

import { Popover, Card } from "@mui/material";

type Vertical = "top" | "center" | "bottom" | number;
type Horizontal = "left" | "center" | "right" | number;

interface PopoverWrapperProps {
  id?: string;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  children: React.ReactNode;
  handleClose: () => void;
  cardStyle?: any;
  popoverStyle?: any;
  anchorOriginVertical?: Vertical;
  anchorOriginHorizontal?: Horizontal;
  transformOriginVertical?: Vertical;
  transformOriginHorizontal?: Horizontal;
}

export const PopoverWrapper: FC<PopoverWrapperProps> = ({
  id,
  open,
  anchorEl,
  children,
  handleClose,
  cardStyle,
  popoverStyle,
  anchorOriginVertical = "bottom",
  anchorOriginHorizontal = "center",
  transformOriginVertical = "top",
  transformOriginHorizontal = "center",
}) => {
  const styleCard = {
    backgroundColor: "#212633",
    p: "12px",
    boxShadow:
      "0px 4px 16px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset",
    backdropFilter: " blur(12.5px)",
    ...cardStyle,
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: transformOriginHorizontal,
      }}
      PaperProps={{
        sx: {
          borderRadius: "12px",
        },
      }}
      sx={{ ...popoverStyle }}
    >
      <Card sx={styleCard}>{children}</Card>
    </Popover>
  );
};

export default PopoverWrapper;
