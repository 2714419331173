import React, { FC } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MemberItem from "../Members/MemberItem/MemberItem";
import { PersonView } from "../../../../models/profile.models";

export const SearchResultMembers: FC = () => {
  const filteredProfiles: PersonView[] = useAppSelector(
    (state) => state.profileReducer.filteredProfiles
  );

  return (
    <Box>
      <Typography variant="body1" color="secondary" py={2}>
        Результаты
      </Typography>
      {filteredProfiles.map((item, index) => (
        <MemberItem userInfo={item} key={index} />
      ))}
    </Box>
  );
};

export default SearchResultMembers;
