import React, { FC, SetStateAction, useEffect, useRef } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Stories from "react-insta-stories";

import DialogWrapper from "../../../common/Modals/DialogWrapper/DialogWrapper";
import { StoryView } from "../../../../models/story.model";
import StoryWrapper from "./StoryWrapper/StoryWrapper";
import { GroupInfo } from "../Stories";
import { StoryGroupView } from "../../../../models/story.model";
import { storyController } from "../../../../services/story.controller";
import { dataInitial } from "../../../../actions/data";

interface ModalStoriesProps {
  openModal: boolean;
  handleModal: () => void;
  groupInfo?: GroupInfo;
  setGroupInfo?: SetStateAction<any>;
  handleError: () => void;
}

export const ModalStories: FC<ModalStoriesProps> = ({
  openModal,
  handleModal,
  groupInfo,
  setGroupInfo,
  handleError,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const storyGroups: StoryGroupView[] = useAppSelector(
    (state) => state.dataReducer.storyGroups
  );

  const closeModal = () => {
    handleModal();
    nextGroupStories.current = null;
    nextGroup.current = null;
  };
  
  const nextGroup = useRef<StoryGroupView | null | undefined>(null);
  const nextGroupStories = useRef<StoryView[] | null>(null);

  useEffect(() => {
    if (groupInfo) {
      nextGroupStories.current && dispatch(dataInitial.groupStories(nextGroupStories.current));
      nextGroup.current =
        storyGroups[
          storyGroups.findIndex((element) => element.id === groupInfo?.id) + 1
        ];
      nextGroup.current
        ? storyController
          .storiesFromGroup(nextGroup.current.id)
          .then((res) => nextGroupStories.current = res.data.items)
        : nextGroupStories.current = null;
    }
    /* eslint-disable */
  }, [groupInfo]);

  const stories = useAppSelector(
    (state) => state.dataReducer.groupStories
  )?.map((item: StoryView) => ({
    content: ({ action }: { action: any }) => (
      <StoryWrapper
        story={item}
        groupInfo={groupInfo}
        closeModal={closeModal}
        contentAction={action}
        handleError={handleError}
      />
    ),
  }));

  const nextStoryGroup = () => {
    if (nextGroup.current?.id) {
      setGroupInfo({
        id: nextGroup.current.id,
        type: nextGroup.current.groupType,
        pinned: nextGroup.current.pinned,
      });
    } else {
      closeModal();
    }
  };

  const styleModal = {
    "& .MuiDialog-paper": {
      width: isMobile ? "100%" : "375px",
      height: "100%",
      maxHeight: !isMobile ? "812px" : undefined,
    },
  };

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={closeModal}
      stylesContent={{ p: 0 }}
      styles={styleModal}
    >
      {stories && nextGroup.current?.id !== groupInfo?.id && <Stories
        stories={stories}
        width="100%"
        height="100%"
        onAllStoriesEnd={nextStoryGroup}
      />}
    </DialogWrapper>
  );
};

export default ModalStories;
