import React from 'react';
import {ReviewContextProvider} from "./RewievContextProvider/ReviewContextProvider";
import ReviewPage from "./ReviewPage";

const Review = () => {

    return (
        <ReviewContextProvider>
            <ReviewPage/>
        </ReviewContextProvider>
    )
};

export default Review;