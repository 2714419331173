import React, {Dispatch, MouseEvent, SetStateAction, useContext, useState} from 'react';
import {Box} from "@mui/material";
import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import PopoverWrapper from "../../common/Modals/PopoverWrapper/PopoverWrapper";
import {AppContext} from "../../shared/AppContextProvider";
import {UserSelect} from "../ReviewModal/Selects/UserSelect";
import {PersonView} from "../../../models/profile.models";
import {VotedReviewers} from "../../../models/review.models";

interface IProps {
    selectedReviewers: PersonView[]
    setSelectedReviewers: Dispatch<SetStateAction<PersonView[]>>
    votedReviewers: VotedReviewers[]
}

const cardStyle = {
    width: "307px",
    height: "316px",
    overflowY: "auto",
};

const EditObservers = ({selectedReviewers, setSelectedReviewers, votedReviewers}: IProps) => {
    const {isMobile} = useContext(AppContext)

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const isOpen = Boolean(anchorEl)
    const id = isOpen ? "tags-popover" : undefined

    const handlePopover = ()=>{
        setAnchorEl(null)
    }

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <Box>
            <CustomButton
                text="Изменить ревьюеров"
                color="secondary"
                fullWidth
                onClick={handleClick}
            />
            <PopoverWrapper
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                handleClose={handlePopover}
                cardStyle={cardStyle}
                anchorOriginHorizontal={isMobile ? "center" : "right"}
                transformOriginHorizontal={isMobile ? "center" : "right"}
                popoverStyle={{mt: 1}}>
                <UserSelect selectedReviewers={selectedReviewers} setSelectedReviewers={setSelectedReviewers} votedReviewers={votedReviewers}/>
            </PopoverWrapper>
        </Box>
    );
};

export default EditObservers;