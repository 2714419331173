export const initialProfiles = {
  profile: {
    achievements: [],
    avatar: null,
    background: null,
    blocked: false,
    city: { id: "", name: "" },
    contact: {
      email: "",
      github: "",
      id: "",
      instagram: "",
      linkedin: "",
      phone: "",
      skype: "",
      telegram: "",
      vk: "",
    },
    description: "",
    dob: "",
    fired: "",
    gender: "",
    hired: "",
    id: "",
    job: {
      department: { id: "", name: "" },
      grade: "",
      id: "",
      title: { id: "", name: "" },
    },
    name: "",
    patronymic: "",
    role: { role: "" },
    surname: "",
  },
  filteredProfiles: [],
  editProfile: {
    achievements: [],
    avatar: null,
    background: null,
    blocked: false,
    city: { id: "", name: "" },
    contact: {
      email: "",
      github: "",
      id: "",
      instagram: "",
      linkedin: "",
      phone: "",
      skype: "",
      telegram: "",
      vk: "",
    },
    description: "",
    dob: "",
    fired: "",
    gender: "",
    hired: "",
    id: "",
    job: {
      department: { id: "", name: "" },
      grade: "",
      id: "",
      title: { id: "", name: "" },
    },
    name: "",
    patronymic: "",
    role: { role: "" },
    surname: "",
  },
  allProfiles: [],
  totalCountProfiles: 0
};
