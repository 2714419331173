import React, { FC, useState } from 'react'

import { Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { useTheme } from '@mui/material/styles'

import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper'
import InputLine from '../../common/Inputs/InputLine/InputLine'
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons'
import { passwordController } from '../../../services/password.controller'
import Snackbars, { SnackBarType } from '../../common/Snackbars/Snackbars'
import { regEx } from '../../../constants/regEx'
import { textSnackbars } from '../../../constants/snackBarNotifications'

export const ForgotPasswordModal: FC = () => {
  const theme = useTheme()

  const styleText = {
    mt: 1,
    cursor: 'pointer',
    color: 'rgba(24, 132, 255, 1)',
    width: 'fit-content',
  }

  const [openModal, setOpenModal] = useState<boolean>(false)

  const [value, setValue] = useState<string>('')
  const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    setErrorMessage('')
  }

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [snackbarType, setSnackbarType] = useState<SnackBarType>('success')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleModal = () => {
    setOpenModal(!openModal)
    setValue('')
    setErrorMessage('')
  }

  const handleSend = () => {
    !errorMessage &&
      passwordController
        .forgot(value)
        .then(() => {
          setOpenSnackbar(true)
          setSnackbarType('success')
          handleModal()
        })
        .catch((err) => {
          if (err.response.data.errorCode === 61) {
            setErrorMessage('Пользователя с таким email не существует')
            return
          }
          setOpenSnackbar(true)
          setSnackbarType('error')
          handleModal()
        })
  }

  return (
    <>
      <Typography variant='body1' sx={styleText} onClick={handleModal}>
        Забыли пароль?
      </Typography>
      <DialogWrapper
        openModal={openModal}
        handleModal={handleModal}
        width='532px'
        styles={{ '.MuiDialog-paper': { height: { laptop: '366px' } } }}
        contentDividers
        buttons={
          <ModalButtons
            handleCancel={handleModal}
            handleSend={handleSend}
            disableSend={regEx.isEmpty.test(value)}
            justifyContent='flex-end'
            fullWidth
          />
        }
      >
        <Typography variant='h4' mb={4}>
          Сбросить пароль
        </Typography>
        <Typography variant='body1' color={theme?.palette?.text?.secondary} mb={4}>
          Введите свой корпоративный адрес электронной почты. Вы получите ссылку для сброса пароля.
        </Typography>
        <Typography variant='body1' color={theme?.palette?.text?.secondary} mb={1}>
          Адрес электронной почты
        </Typography>
        <FormControl fullWidth sx={{ mb: 0 }}>
          <InputLine
            placeholder='username@loylabs.ru'
            value={value}
            onChange={handleValue}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
          />
        </FormControl>
      </DialogWrapper>
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type={snackbarType}
        position='center'
        message={snackbarType === 'success' ? textSnackbars.forgotPassword : undefined}
      />
    </>
  )
}

export default ForgotPasswordModal
