import { personController } from "../services/person.controller";
import { profilesInitial } from "../actions/profiles";

export const getUsersRightSidebar = (userId: string, dispatch: any) => {
  personController
    .allPersons(`id!:${userId},blocked:false`, undefined, undefined)
    .then((res) => {
      dispatch(profilesInitial.filteredProfiles(res.data.items));
    });
};
