import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box, Stack, Typography } from "@mui/material";

import { CommentView } from "../../../../../models/comment.models";
import CommentItem from "./CommentItem/CommentItem";
import CreateComment from "./CreateComment/CreateComment";
import { achievementController } from "../../../../../services/achievement.controller";
import { achievementsInitial } from "../../../../../actions/achievements";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

interface AchievCommentsProps {
  taskId: string;
}

export const AchievComments: FC<AchievCommentsProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const achievementId = sessionStorage.getItem("achievementId") || "";

  const comments: CommentView[] = useAppSelector(
    (state) => state.achievementsReducer.achievementComments
  );

  useEffect(() => {
    achievementController.getComments(achievementId, taskId).then((result) => {
      dispatch(achievementsInitial.achievementComments(result.data.items));
    });

    return () => {
      dispatch(achievementsInitial.achievementComments([]));
    };
    /* eslint-disable-next-line */
  }, [taskId]);

  return (
    <Box>
      <Typography variant="subtitle1" mb={3}>
        Комментарии
      </Typography>
      {comments.length > 0 ? (
        <Stack>
          {comments?.map((comment, index, arr) => (
            <CommentItem
              comment={comment}
              key={index}
              lastItem={index + 1 === arr.length}
            />
          ))}
        </Stack>
      ) : (
        <Typography mb={3} color={(theme) => theme.palette.text.secondary}>
          Еще нет комментариев
        </Typography>
      )}

      <CreateComment taskId={taskId} />
    </Box>
  );
};

export default AchievComments;
