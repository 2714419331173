import React, { FC, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import PreviewFiles from "../../../common/Attachments/PreviewFiles/PreviewFiles";
import PreviewImages from "../../../common/Attachments/PreviewImages/PreviewImages";
import DialogWrapper from "../../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../../common/Buttons/ModalButtons/ModalButtons";
import IdeaDialogContent from "../IdeaDialogContent/IdeaDialogContent";
import { AppContext } from "../../../shared/AppContextProvider";
import { secondaryButton } from "../../../../constants/buttonStyle";
import { roles } from "../../../../constants/role";
import { IdeasContext, ideasInitialFetchingStatus } from "../../IdeasContext/IdeasContextProvider";
import { ideasController } from "../../../../services/ideas.controller";
import { dataInitial } from "../../../../actions/data";
import IdeaLikes from "../../IdeaLikes/IdeaLikes";

import type { Idea as IdeaView } from "../../../../types/Idea.type";
import type { IdeaStatusView } from "../../../../models/ideas.model";
import type { AttachmentView } from "../../../../models/attachment.model";

interface IdeaContentProps {
  ideaData: IdeaView;
}
export const IdeaContent: FC<IdeaContentProps> = ({ ideaData }) => {
  const dispatch = useDispatch();
  const ideas: IdeaView[] = useAppSelector(
    (state) => state.dataReducer.ideas
  );

  const { user } = useContext(AppContext);
  const notUserRole = user.role !== roles.user;

  const [dialogStatus, setDialogStatus] = useState<IdeaStatusView>("NEW");
  const [dialogIsOpened, setDialogIsOpened] = useState<boolean>(false);
  const openDialog = () => setDialogIsOpened(true);
  const closeDialog = () => setDialogIsOpened(false);
  const handleAccDialog = () => {
    setDialogStatus("APPROVED");
    openDialog();
  };
  const handleRejDialog = () => {
    setDialogStatus("DISMISSED");
    openDialog();
  };

  const {
    pageSize,
    currentPage,
    saveIdeasSessionData,
    totalPages,
    totalItems,
    onIdeasError: handleError,
  } = useContext(IdeasContext);

  const [response, setResponse] = useState<string>("");
  const setIdeaStatus = () => {
    const newIdea = {
      ...ideaData,
      response: response,
      status: dialogStatus,
    };
    dispatch(dataInitial.idea(newIdea));
    closeDialog();
    ideasController
      [dialogStatus === "APPROVED" ? "acceptIdea" : "rejectIdea"](ideaData.id, { response: response })
      .then(() => {
        const newIdeas = structuredClone(ideas);
        const ideaIndex = newIdeas.findIndex(idea => idea.id === ideaData.id);
        newIdeas.splice(ideaIndex, 1);
        const newPage = ideas.length % pageSize === 1 && ideaIndex === ideas.length - 1 && newIdeas.length !== 0
          ? currentPage - 1 : currentPage;
        totalItems.current % pageSize === 1 && totalPages.current--;
        saveIdeasSessionData({ currentPage: newPage, totalPages: totalPages.current });
        sessionStorage.setItem("idea", JSON.stringify(newIdea));
        ideasInitialFetchingStatus.value = "single";
        dispatch(dataInitial.ideas(newIdeas));
      })
      .catch(() => handleError());
  };

  const images =
    ideaData?.attachments?.filter(
      (item: AttachmentView) => item.attachmentType === "IMAGE"
    ) ?? [];
  const files =
    ideaData?.attachments?.filter(
      (item: AttachmentView) => item.attachmentType !== "IMAGE"
    ) ?? [];

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "32px",
    backgroundColor: "transparent",
  };

  const cardContentStyle = {
    pb: "0px",
    ".MuiImageList-root": {
      m: "32px 0px 0px",
    },
  };

  const cardActionsStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "32px",
    "& :not(:first-of-type)": {
      ml: "0px",
    },
    p: "0px 16px",
  };

  const accRejButtonStyle = {
    borderRadius: "60px",
    padding: "12px 24px",
    width: "100%",
    typography: "body1",
  };

  const modalButtons = (
    <ModalButtons
      nameButtonSend={dialogStatus === "APPROVED" ? "Принять" : "Отклонить"}
      handleCancel={closeDialog}
      handleSend={setIdeaStatus}
      justifyContent="end"
      disableSend={response.length === 0}
      fullWidth
    />
  );

  return (
    <>
      <Card sx={cardStyle} elevation={0}>
        <CardContent sx={cardContentStyle}>
          <Typography variant="h4" sx={{ overflowWrap: "break-word" }}>
            {ideaData?.header}
          </Typography>
          <Typography variant="body2" mt="32px" sx={{ overflowWrap: "break-word" }}>
            {ideaData?.content}
          </Typography>
          {images?.length > 0 && <PreviewImages images={images} />}
          {files?.length > 0 && <PreviewFiles files={files} type="download" />}
        </CardContent>
        <CardActions sx={cardActionsStyle}>
          <Box display="flex" justifyContent="start" gap="8px">
            <IdeaLikes ideaData={ideaData} />
          </Box>
          {notUserRole
            && <Box display="flex" justifyContent="space-between" width="100%" gap="12px">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleRejDialog}
                sx={{
                  ...accRejButtonStyle,
                  ...secondaryButton,
                }}
                disabled={ideaData?.status !== "NEW"}
              >
                Отклонить
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={accRejButtonStyle}
                size="small"
                onClick={handleAccDialog}
                disabled={ideaData?.status !== "NEW"}
              >
                Принять
              </Button>
            </Box>
          }
        </CardActions>
      </Card>
      <DialogWrapper
        openModal={dialogIsOpened}
        handleModal={closeDialog}
        width="532px"
        contentDividers
        buttons={modalButtons}
      >
        <IdeaDialogContent status={dialogStatus} response={response} setResponse={setResponse} />
      </DialogWrapper>
    </>
  );
};
export default IdeaContent;