import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";

import Logo from "../../../common/Logo/Logo";
import UserAvatar from "../../../common/User/UserAvatar/UserAvatar";
import { personController } from "../../../../services/person.controller";
import { PersonView } from "../../../../models/profile.models";
import { profilesInitial } from "../../../../actions/profiles";
import NotificationsButton from "../../RightSidebar/LoggedUser/NotificationsButton/NotificationsButton";
import NotificationsPopup from "../../../Notifications/NotificationsPopup/NotificationsPopup";

export const Header: FC = () => {
  const trigger = useScrollTrigger();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const personId = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).id;

  const [notificationsPopupIsOpened, setNotificationsPopupIsOpened] = useState<boolean>(false);
  const handleNotificationsButtonClick = () => {
    setNotificationsPopupIsOpened(true);
  };
  const closeNotificationsPopup = () => {
    setNotificationsPopupIsOpened(false);
  };

  const styleToolbar = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
    alignItems: "start",
  };

  useEffect(() => {
    personController
      .person(personId)
      .then((res) => dispatch(profilesInitial.profile(res.data)));
    /* eslint-disable */
  }, []);

  const personData: PersonView = useAppSelector(
    (state) => state.profileReducer.profile
  );

  const handleAvatar = () => {
    localStorage.setItem("userId", personId);
    navigate(`/profile/${personId}`);
  };

  return (
    <Slide appear={false} direction={"down"} in={!trigger}>
      <div>
        <AppBar
          elevation={0}
          sx={{ top: 0, bottom: "auto", backgroundColor: "#131722" }}
        >
          <Toolbar sx={styleToolbar}>
            <Logo withTitle />
            <Box display="flex" gap="12px">
              <NotificationsButton handleClick={handleNotificationsButtonClick} />
              <NotificationsPopup isOpened={notificationsPopupIsOpened} handleClose={closeNotificationsPopup} />
              <UserAvatar userInfo={personData} onClick={handleAvatar} />
            </Box>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </Slide>
  );
};

export default Header;
