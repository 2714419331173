import React, { FC, useState, useEffect } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import { PersonView } from "../../../../models/profile.models";
import LetterAvatar from "./LetterAvatar/LetterAvatar";
import ActiveAvatar from "./ActiveAvatar/ActiveAvatar";
import { PersonCommunityView } from "../../../../models/community.models";

interface UserAvatarProps {
  withBorder?: boolean;
  onClick?: () => void;
  userInfo: PersonView | PersonCommunityView;
  styles?: any;
  newAvatar?: File | null;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  withBorder,
  onClick,
  userInfo,
  styles,
  newAvatar,
}) => {
  const loggedUsers = useAppSelector(
    (state) => state.socketsReducer.loggedUsers
  );
  const [isLogged, setIsLogged] = useState<boolean>(false);
  useEffect(() => {
    setIsLogged(
      loggedUsers?.includes(userInfo?.id) || userInfo?.name === "Джой"
    );
    /* eslint-disable */
  }, [loggedUsers]);

  const borderStyle = withBorder
    ? {
        border: "2px solid #fff",
      }
    : undefined;

  const isJoy = userInfo?.name === "Джой";
  const avatarName = isJoy
    ? userInfo.avatar
    : newAvatar
    ? "new_avatar"
    : userInfo?.avatar
    ? encodeURIComponent(userInfo?.avatar?.name) + userInfo?.avatar?.extension
    : undefined;

  const userName = userInfo?.name + " " + userInfo?.surname;

  const imgSrc = isJoy
    ? userInfo.avatar
    : newAvatar
    ? URL.createObjectURL(newAvatar)
    : avatarName
    ? `/loyalty-portal/api/attachments/${avatarName}`
    : undefined;

  const imgStyle = {
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  };

  const NotActiveAvatar = () => {
    return avatarName ? (
      <Avatar alt={userName} sx={{ ...borderStyle, ...styles }}>
        <Box sx={imgStyle} />
      </Avatar>
    ) : (
      <LetterAvatar userName={userName} style={{ ...borderStyle, ...styles }} />
    );
  };

  return (
    <Box onClick={onClick}>
      {isLogged ? (
        <ActiveAvatar avatar={<NotActiveAvatar />} />
      ) : (
        <NotActiveAvatar />
      )}
    </Box>
  );
};

export default UserAvatar;
