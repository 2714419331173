import React, { FC } from "react";

import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { inputHoverStyle } from "../../../constants/inputHoverStyle";

interface SearchProps {
  value: string;
  endAdornment?: React.ReactNode;
  styles?: any;
  inputStyles?: any;
  placeholder?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const Search: FC<SearchProps> = ({
  value,
  handleChange,
  endAdornment,
  styles,
  inputStyles,
  placeholder,
}) => {
  const inputStyle = {
    ...inputHoverStyle,
    borderRadius: 16,
    paddingRight: 2,
    ...styles,
    "& input": { overflow: "hidden", textOverflow: "ellipsis", ...inputStyles },
    "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus": {
      border: "none",
      WebkitTextFillColor: "rgba(230, 237, 245, 1)",
      WebkitBoxShadow: "none",
      transition: "background-color 5000s ease-in-out 0s",
      caretColor: "rgba(230, 237, 245, 1)",
    },
    border: "1px solid transparent",
  };

  const styleAdornment = {
    marginLeft: "8px",
    "&:not(.MuiInputAdornment-hiddenLabel)": { marginTop: "0 !important" },
  };
  return (
    <FormControl variant="filled" fullWidth>
      <InputBase
        type="text"
        id="searhMember"
        onChange={handleChange}
        sx={inputStyle}
        value={value}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start" sx={styleAdornment}>
            <SearchIcon color="secondary" />
          </InputAdornment>
        }
        endAdornment={endAdornment}
        autoComplete="off"
      />
    </FormControl>
  );
};

export default Search;
