import React, { FC, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Typography } from "@mui/material";

import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import { kbController } from "../../../services/kb.controller";
import InputLine from "../../common/Inputs/InputLine/InputLine";
import { dataInitial } from "../../../actions/data";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import { KbSectionView } from "../../../models/kb.models";

interface SectionModalProps {
  open: boolean;
  handleModal: () => void;
  type: "create" | "edit";
  updSections?: () => void;
  sectionInfo?: KbSectionView;
}
export const SectionModal: FC<SectionModalProps> = ({
  open,
  handleModal,
  type,
  updSections,
  sectionInfo,
}) => {
  const isCreate = type === "create";
  const dispatch = useDispatch();
  const { handleSnackbar, setTotalItems } = useContext(KbContext);
  const [name, setName] = useState<string>("");
  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (sectionInfo) {
      setName(sectionInfo?.name);
    }
  }, [sectionInfo]);

  const closeModal = () => {
    setName("");
    handleModal();
  };

  const manageError = (errorCode: number) => {
    const sameSection = errorCode === 38;
    handleSnackbar(
      sameSection ? "info" : "error",
      sameSection ? "Раздел с таким названием уже существует" : undefined
    );
    closeModal();
  };

  const createService = () => {
    kbController
      .createSection({ name: name })
      .then(() => {
        kbController.sections().then((res) => {
          dispatch(dataInitial.articleSections(res.data.items));
          setTotalItems(res.data.totalItems);
        });
        closeModal();
      })
      .catch((error) => {
        manageError(error.response.data.errorCode);
      });
  };

  const editService = () => {
    if (sectionInfo) {
      kbController
        .editSection(sectionInfo?.id, { name: name })
        .then(() => {
          updSections?.();
          closeModal();
        })
        .catch((error) => {
          manageError(error.response.data.errorCode);
        });
    }
  };

  const handleSaveButton = () => {
    if (isCreate) {
      createService();
    } else {
      editService();
    }
  };

  const createModalButtons = (
    <ModalButtons
      handleCancel={closeModal}
      handleSend={handleSaveButton}
      fullWidth
      nameButtonSend="Сохранить"
      justifyContent="flex-end"
      disableSend={!name}
    />
  );

  return (
    <DialogWrapper
      openModal={open}
      handleModal={closeModal}
      buttons={createModalButtons}
      contentDividers
      width="450px"
    >
      <Typography variant="h4" mb={3}>
        {isCreate ? "Создать раздел" : "Редактировать раздел"}
      </Typography>
      <InputWrapper
        flexDirection="column"
        title="Название раздела"
        element={
          <InputLine
            value={name}
            onChange={handleName}
            placeholder="Введите название"
            autoFocus
          />
        }
      />
    </DialogWrapper>
  );
};

export default SectionModal;
