import React, { Dispatch, FC, SetStateAction } from "react";

import { ListItemButton } from "@mui/material";

import MemberItem from "../../../layout/RightSidebar/Members/MemberItem/MemberItem";
import { PersonCommunityView } from "../../../../models/community.models";
import { PersonView } from "../../../../models/profile.models";

interface SelectMemberProps {
  userInfo: PersonView | PersonCommunityView;
  setIdList: Dispatch<SetStateAction<string[]>>;
  idList: string[];
}

export const SelectMember: FC<SelectMemberProps> = ({
  userInfo,
  setIdList,
  idList,
}) => {
  const handleClick = () => {
    setIdList([userInfo.id]);
  };

  return (
    <ListItemButton
      selected={idList[0] === userInfo.id}
      onClick={handleClick}
      sx={{ borderRadius: "8px", p: 0 }}
    >
      <MemberItem withPopover={false} userInfo={userInfo} />
    </ListItemButton>
  );
};

export default SelectMember;
