import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceoptor from "./jwtInterceptor";

export const reactionController = {
  reactions: async (reactionId: string) => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/reactions/${reactionId}`);
  },
  setReaction: async (reactionId: string, smileId: string): Promise<any> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/reactions/${reactionId}/${smileId}`);
  },
  deleteReaction: async (reactionId: string, smileId: string) => {
    return await jwtInterceoptor.delete(`${apiUrlPortal}/reactions/${reactionId}/${smileId}`);
  },
  smiles: async (size?: number, page?: number) => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/reactions/smiles?size=${size ?? 70}&page=${page ?? 0}`);
  },
};