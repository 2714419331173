import React, { FC, useContext } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";

import { Box } from "@mui/material";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { IdeasContext } from "../IdeasContext/IdeasContextProvider";
import CreateEditIdea from "../CreateEditIdea/CreateEditIdea";
import IdeasPage from "../IdeasPage/IdeasPage";
import IdeasButtons from "../IdeasButtons/IdeasButtons";

import type { Idea as IdeaView } from "../../../types/Idea.type";
import type { IdeaStatus } from "../../../types/IdeaStatus.type";

export const IdeasContent: FC = () => {
  const {
    pageSize,
    totalPages,
    currentPage,
    setCurrentPage,
    toggleItems,
    status,
    setStatus,
    getIdeas,
    ideasAreLoading,
    saveIdeasSessionData,
  } = useContext(IdeasContext);

  const ideas: IdeaView[] = useAppSelector(
    (state) => state.dataReducer.ideas
  );

  const handleToggle = (value: IdeaStatus) => {
    setStatus(value);
    setCurrentPage(0);
    getIdeas(0, value, []);
    saveIdeasSessionData({ status: value, currentPage: 0 });
  };

  const onBackButtonClick = () => {
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    saveIdeasSessionData({ currentPage: prevPage });
  };

  const onNextButtonClick = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    const isLastPage = currentPage === ideas.length / pageSize - 1;
    isLastPage && getIdeas(nextPage, status, ideas);
    saveIdeasSessionData({ currentPage: nextPage });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={{ mobile: "24px", laptop: "32px" }} height="40px">
        <IdeasButtons toggleItems={toggleItems} handleToggle={handleToggle} status={status} />
        <Box display="flex" justifyContent="center" alignItems="center" mt="6px" mx={{ mobile: "16px", laptop: "0px" }}>
          <IconButton onClick={onBackButtonClick} color="secondary" disabled={currentPage === 0}>
            <KeyboardArrowLeftIcon fontSize="medium"/>
          </IconButton>
          <IconButton
            onClick={onNextButtonClick}
            color="secondary"
            disabled={currentPage === totalPages.current - 1 || ideasAreLoading}
          >
            <KeyboardArrowRightIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
      <Box mx="16px">
        <List sx={{ pt: "0px", pb: { mobile: "38px", laptop: "0px" } }}>
          <IdeasPage
            currentPage={currentPage}
            showLoader={ideasAreLoading}
          />
        </List>
      </Box>
      <CreateEditIdea />
    </>
  );
};

export default IdeasContent;