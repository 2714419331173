import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ReactComponent as DoneIcon } from "../../../../assets/icons/loginNewDone.svg";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";

interface LoginNewDoneProps {
  email: string;
}
export const LoginNewDone: FC<LoginNewDoneProps> = ({ email }) => {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/login");
  };

  const buttonStyle = {
    typography: "button",
    height: "48px",
    borderRadius: "60px",
    padding: "4px 24px",
  };

  return (
    <>
      <Box><DoneIcon width="100%" /></Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h4">
          Всё почти готово!
        </Typography>
        <Typography variant="body2" color="secondary">
          Мы отправили данные для входа на почту {email}
        </Typography>
      </Box>
      <CustomButton
        onClick={goToLogin}
        color="secondary"
        text="Перейти ко входу"
        fullWidth
        styles={buttonStyle}
      />
    </>
  );
};

export default LoginNewDone;