import React, { FC, SetStateAction, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import SearchResultMembers from "../../SearchResultMembers/SearchResultMembers";
import Search from "../../../../common/Search/Search";
import { personController } from "../../../../../services/person.controller";
import { searchFIO } from "../../../../../operation/searchFIO";
import { profilesInitial } from "../../../../../actions/profiles";
import {sanitizeAndTrimStart} from "../../../../../operation/sanitizeAndTrimStart";

interface SearchMemberProps {
  valueSearch: string;
  setValueSearch: SetStateAction<any>;
}
export const SearchMember: FC<SearchMemberProps> = ({
  valueSearch,
  setValueSearch,
}) => {
  const dispatch = useDispatch();
  const handleClear = () => {
    setValueSearch("");
  };

  const loggedUserId = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).id;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = sanitizeAndTrimStart(value);
    setValueSearch(sanitizedValue);
  };

  const styleAdornment = {
    marginLeft: "8px",
    "&:not(.MuiInputAdornment-hiddenLabel)": { marginTop: "0 !important" },
  };

  const endAdornment: React.ReactNode = valueSearch && (
    <InputAdornment position="end" sx={styleAdornment}>
      <IconButton
        disableRipple
        aria-label="clear search"
        onClick={handleClear}
        color="secondary"
        edge="end"
      >
        <CloseIcon color="secondary" fontSize="small" />
      </IconButton>
    </InputAdornment>
  );

  const filterUsers = useCallback(
    (inputValue: string) => {
      personController
        .allPersons(searchFIO(inputValue, `id!:${loggedUserId},blocked:false`))
        .then((res) =>
          dispatch(profilesInitial.filteredProfiles(res.data.items))
        );
    },
    [dispatch, loggedUserId]
  );

  useEffect(() => {
    filterUsers(valueSearch);
  }, [valueSearch, filterUsers]);

  return (
    <>
      <Search
        endAdornment={endAdornment}
        value={valueSearch}
        handleChange={handleChange}
      />
      {valueSearch && <SearchResultMembers />}
    </>
  );
};

export default SearchMember;
