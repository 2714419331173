import React, { FC } from "react";

import Box from "@mui/material/Box";
import { Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

import CustomToggleButtons from "../../common/Buttons/CustomToggleButtons/CustomToggleButtons";

const StyledBox = styled(Box, {
	shouldForwardProp(prop: string) {
		return (
			prop !== "textColor"
			&& prop !== "selectionFollowsFocus"
			&& prop !== "indicator"
			&& prop !== "fullWidth"
		);
	},
})``;

interface NotificationsToggleButtonsProps {
	onToggle: (seen: boolean) => void;
	seen: boolean;
}

const NotificationsToggleButtons: FC<NotificationsToggleButtonsProps> = ({
	onToggle,
	seen,
}) => {
	const toggleItems = ["Непрочитанные", "Архив"];

	const handleToggle = (value: string) => {
    onToggle(value === toggleItems[1] ? true : false);
  };

	const tabsStyle = {
		mx: { mobile: "-16px", laptop: "0px" },
		minHeight: "40px",
		".MuiTabs-indicator": {
			height: "0px",
		},
		".MuiToggleButtonGroup-root": {
			flexWrap: "nowrap",
			gap: "8px",
			".MuiToggleButtonGroup-grouped.MuiToggleButton-root": {
				margin: "0px",
			},
		},
	};

	return (
		<Tabs
			variant="scrollable"
			scrollButtons={false}
			sx={tabsStyle}
			value={0}
		>
			<StyledBox px={{ mobile: "16px", laptop: "0px" }}>
				<CustomToggleButtons
					items={toggleItems}
					onChange={handleToggle}
					currentValue={seen ? toggleItems[1] : toggleItems[0]}
				/>
			</StyledBox>
		</Tabs>
	);
}

export default NotificationsToggleButtons;