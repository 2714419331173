import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceoptor from "./jwtInterceptor";

import moment from "moment";
import { dbCalendarEventCategories, dbCalendarEventRecurrences } from "../constants/calendar";
import type { CalendarEvent } from "../types/CalendarEvent.type";

export const calendarController = {
  allEvents: async (): Promise<any> => {
    const events = {
      data: {} as any,
    };
    await jwtInterceoptor.get(`${apiUrlPortal}/calendar/corporate/events`)
      .then(res => {
        res.data.forEach((event: any) => {
          const key = moment(event.startDateTime).format("DD-MM-YYYY");
          const eventInfo = {
            calendarEventType: dbCalendarEventCategories.indexOf(event.eventType),
            name: event.eventName,
            description: event.description,
            date: key,
            startTime: moment(event.startDateTime).format("HH:mm"),
            finishTime: moment(event.endDateTime).format("HH:mm"),
            recurrence: 0,
            id: event.id,
            repeatId: event.repeatId,
          };
          events.data[key]
            ? events.data[key].push(eventInfo)
            : events.data[key] = [eventInfo];
        });
      });
    return events;
  },
  addEvent: async (eventInfo: CalendarEvent): Promise<any> => {
    const bodyRequest = {
      dateEnd: moment(eventInfo.date, "DD-MM-YYYY")
        .add(Number(eventInfo.finishTime.slice(0, 2)), "hours")
        .add(Number(eventInfo.finishTime.slice(3)), "minutes")
        .toISOString(),
      dateStart: moment(eventInfo.date, "DD-MM-YYYY")
        .add(Number(eventInfo.startTime.slice(0, 2)), "hours")
        .add(Number(eventInfo.startTime.slice(3)), "minutes")
        .toISOString(),
      description: eventInfo.description,
      eventName: eventInfo.name,
      eventType: dbCalendarEventCategories[eventInfo.calendarEventType],
      ...eventInfo.recurrence && { recurrence: {
        unit: dbCalendarEventRecurrences[eventInfo.recurrence],
      }},
    };
    return await jwtInterceoptor.post(`${apiUrlPortal}/calendar/corporate/events`, bodyRequest);
  },
  deleteEvent: async (eventId: number, repeatable?: boolean): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/calendar/corporate/events/${eventId}?repeatable=${repeatable ?? false}`
    );
  },
  editEvent: async (eventInfo: CalendarEvent, repeatable?: boolean): Promise<any> => {
    calendarController.deleteEvent(eventInfo.id!, repeatable);
    return await calendarController.addEvent(eventInfo);
  },
  weekends: async (year: number = moment().year()): Promise<any> => {
    const res = await jwtInterceoptor.get(`${apiUrlPortal}/calendar/${year}`);
    const weekendsSet = new Set();
    res.data.items.forEach((item: any) => {
      const month = item.month[0] + item.month.substring(1, 3).toLowerCase();
      item.holidays.forEach((holiday: number) => {
        weekendsSet.add(month + " " + holiday.toString().padStart(2, "0") + " " + year);
      });
    });
    return weekendsSet;
  },
};