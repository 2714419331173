import React, { FC, Dispatch, SetStateAction } from "react";

import { Typography } from "@mui/material";

import InputLine from "../../../common/Inputs/InputLine/InputLine";
import { checkLanguage } from "../../../../constants/errorText";

import type { IdeaStatusView } from "../../../../models/ideas.model";

interface IdeaDialogContentProps {
  status: IdeaStatusView;
  response: string;
  setResponse: Dispatch<SetStateAction<string>>;
}
export const IdeaDialogContent: FC<IdeaDialogContentProps> = ({
  status,
  response,
  setResponse,
}) => {
  const handleChangeInputText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponse(event.target.value);
  };

  return (
    <>
      <Typography variant="h4" mb="24px">
        {`${status === "APPROVED" ? "Принять" : "Отклонить"} идею?`}
      </Typography>
      <InputLine
        value={response}
        onChange={handleChangeInputText}
        helperText={checkLanguage}
        placeholder={`Опишите причину ${status === "APPROVED" ? "принятия" : "отказа"}`}
        multiline
        autoFocus
        styles={{
          "&:before, &:hover:not(.Mui-disabled):before, &:after, &:focus-within:before, &:after, &.Mui-error:after": {
            borderBottom: "none",
          },
        }}
      />
    </>
  );
};

export default IdeaDialogContent;