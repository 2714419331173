//portal user roles
const admin = "ADMIN";
const editor = "EDITOR";
const user = "USER";

export const roles = { admin, editor, user };

//group member roles
const groupAdmin = "ADMIN";
const member = "MEMBER";
const owner = "OWNER";

export const memberGroupRoles = { groupAdmin, member, owner };
