import React, { FC } from "react";

import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

interface DotsProps {
  index: number;
  handleIndex: (index: number) => void;
}

export const Dots: FC<DotsProps> = ({ index, handleIndex }) => {
  const styleDot = { width: "8px", height: "8px", cursor: "pointer" };
  const styleBox = {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: 2,
    justifyContent: "center",
    mt: 2,
  };

  return (
    <Box sx={styleBox}>
      {[...Array(4).keys()].map((item) => (
        <CircleIcon
          sx={styleDot}
          key={item}
          onClick={() => handleIndex(item)}
          color={index === item ? "action" : "secondary"}
        />
      ))}
    </Box>
  );
};
