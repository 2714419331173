import React, { FC, useState, useEffect } from "react";

import Box from "@mui/material/Box";

import ReactionsButtons from "../ReactionsButtons/ReactionsButtons";
import { getReactionSymbolId } from "../../../../operation/getReactionSymbolId";
import { reactionController } from "../../../../services/reaction.controller";

import type { ReactionView } from "../../../../models/reaction.model";

interface FixedReactionsProps {
  reactionId: string;
  type: "BIRTHDAY" | "WELCOME";
  handleError: () => void;
}

export const FixedReactions: FC<FixedReactionsProps> = ({
  reactionId,
  type,
  handleError,
}) => {
  const changeSymbol = (symbol: string) => {
    switch (true) {
      case symbol === "😏" || symbol === "👍":
        return "🎉";
      case symbol === "😡" || symbol === "👎":
        return "🥳";
      default:
        return type === "BIRTHDAY" ? "🎂" : "👋";
    }
  };
  const [reactions, setReactions] = useState<ReactionView[]>(
    ["👍", "👎", ""].map(el => (
      {
        clickCount: 0,
        pressed: false,
        smile: {
          id: getReactionSymbolId(el),
          symbol: changeSymbol(el),
        },
      }
    ))
  );

  useEffect(() => {
    reactionController
      .reactions(reactionId)
      .then((res) => {
        res.data.items.forEach((item: ReactionView) => (
          item.smile.symbol = changeSymbol(item.smile.symbol)
        ));
        const reactionsData = reactions.map(reaction => (
          res.data.items.find((el: ReactionView) => el.smile.id === reaction.smile.id) ?? reaction
        ));
        setReactions(reactionsData);
      })
      .catch(() => {
        handleError();
      });
    /* eslint-disable */
  }, [reactionId]);

  return (
    <Box zIndex={10000} mt="16px">
      <ReactionsButtons reactionId={reactionId} reactions={reactions} setReactions={setReactions} handleError={handleError} />
    </Box>
  );
};

export default FixedReactions;