import { dataInitial } from "../actions/data";
import { DATA } from "../constants/actions";
import { ReturnActionsTypes } from "../store";
import { FeedRecordView } from "../models/feed.model";
import { CommentView } from "../models/comment.models";
import { initialData } from "./initialStates/initialData";
import { StoryGroupView, StoryView } from "../models/story.model";
import { KbArticleView, KbSectionView } from "../models/kb.models";
import { Idea as IdeaView } from "../types/Idea.type";
import { SmileView } from "../models/reaction.model";
import { NotificationView } from "../models/notifications.model";

type Data = {
  feed: FeedRecordView[];
  feedItem: FeedRecordView;
  feedComments: CommentView[];
  storyGroups: StoryGroupView[];
  groupStories: StoryView[];
  articleSections: KbSectionView[];
  article: KbArticleView;
  ideas: IdeaView[];
  idea: IdeaView;
  ideaComments: CommentView[];
  smiles: SmileView[];
  notifications: NotificationView[];
  liveNotifications: NotificationView[];
  seenNotifications: NotificationView[];
};

const initialState: Data = initialData;

export type dataType = ReturnActionsTypes<typeof dataInitial>;

export const dataReducer = (state = initialState, action: dataType) => {
  switch (action.type) {
    case DATA.DATA_FEED:
      return { ...state, feed: action.payload };
    case DATA.DATA_FEED_ITEM:
      return { ...state, feedItem: action.payload };
    case DATA.DATA_FEED_COMMENTS:
      return { ...state, feedComments: action.payload };
    case DATA.DATA_STORY_GROUPS:
      return { ...state, storyGroups: action.payload };
    case DATA.DATA_GROUP_STORIES:
      return { ...state, groupStories: action.payload };
    case DATA.DATA_ARTICLE_SECTIONS:
      return { ...state, articleSections: action.payload };
    case DATA.DATA_ARTICLE:
      return { ...state, article: action.payload };
    case DATA.DATA_IDEAS:
      return { ...state, ideas: action.payload };
    case DATA.DATA_IDEA:
      return { ...state, idea: action.payload };
    case DATA.DATA_IDEA_COMMENTS:
      return { ...state, ideaComments: action.payload };
    case DATA.DATA_SMILES:
      return { ...state, smiles: action.payload };
    case DATA.DATA_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case DATA.DATA_LIVE_NOTIFICATIONS:
      return { ...state, liveNotifications: action.payload };
    case DATA.DATA_SEEN_NOTIFICATIONS:
      return { ...state, seenNotifications: action.payload };
    default:
      return state;
  }
};
