import {getColorByValue} from "../components/Review/utils/getColorByValue";
import {Estimations} from "../models/review.models";

export const useGetReviewEstimations = (estimations:Estimations, reviewId:string) => {
    const getEstimationsForReview = () => {
        if (estimations && estimations[reviewId]) {
            return {
                totalItems: estimations[reviewId].totalItems,
                counts: estimations[reviewId].items.map(el => el.rating)
            }
        }
        return {totalItems: 0, counts: []};
    }

    const {totalItems, counts} = getEstimationsForReview()

    const middleValue = (sum: number[], reviewersCount: number) => {
        if (reviewersCount === 0) return 0
        return Number((sum.reduce((acc, el) => acc + el, 0) / reviewersCount).toFixed(1));
    }

    const estimationValue = counts && totalItems > 0 ? middleValue(counts, totalItems) : 0

    const estimationColor = getColorByValue(estimationValue)

    return {estimationValue, estimationColor}
}