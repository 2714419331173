import React, { FC } from "react";

import PreviewFiles from "../../../common/Attachments/PreviewFiles/PreviewFiles";
import { Box } from "@mui/material";

interface EditorFileProps {
  blockProps: { src: string; height: string; width: string };
}

export const EditorFile: FC<EditorFileProps> = ({ blockProps }) => {
  const extension = blockProps.src?.substring(blockProps.src?.indexOf("."));
  const fileName = blockProps.src?.substring(
    blockProps.src?.lastIndexOf("/") + 1,
    blockProps.src?.indexOf(".")
  );

  const imageExtensions = [".jpeg", ".svg", ".bmp", ".gif", ".jfif", ".png"];

  const isImg = imageExtensions.includes(extension);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
  };

  return (
    <>
      {!isImg && (
        <PreviewFiles
          type="download"
          files={[{ name: fileName, extension: extension }]}
          size="compact"
        />
      )}
      {isImg && (
        <Box sx={containerStyle}>
          <img src={blockProps.src} alt={`${fileName}`} />
        </Box>
      )}
    </>
  );
};

export default EditorFile;
