import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import EditPopover from "../../../../common/Modals/EditPopover/EditPopover";
import { GroupInfo } from "../../Stories";
import { roles } from "../../../../../constants/role";
import { storyController } from "../../../../../services/story.controller";
import { dataInitial } from "../../../../../actions/data";
import ConfirmModal from "../../../../common/Modals/ConfirmModal/ConfirmModal";
import CreateModal from "../../CreateStory/CreateModal/CreateModal";

interface ModalStoriesButtonProps {
  closeModal?: () => void;
  groupInfo?: GroupInfo;
  contentAction: any;
}

export const ModalStoriesButton: FC<ModalStoriesButtonProps> = ({
  closeModal,
  groupInfo,
  contentAction,
}) => {
  const dispatch = useDispatch();
  const nameFirstButton = groupInfo?.pinned ? "Открепить" : "Закрепить";
  const currentUserRole = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).role;

  const accessPopover =
    groupInfo?.type === "ADMIN" && currentUserRole !== roles.user;

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handlConfirModal = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const updStoryGroups = () => {
    storyController.allStoryGroup().then((res) => {
      dispatch(dataInitial.storyGroups(res.data.items));
    });
  };

  const handlePinn = () => {
    storyController
      .updStoryGroup(groupInfo?.id, !groupInfo?.pinned)
      .then(() => updStoryGroups());
  };
  const handleDelete = () => {
    storyController
      .deleteStoryGroup(groupInfo?.id)
      .then(() => updStoryGroups())
      .then(() => handlConfirModal())
      .then(() => closeModal?.());
  };

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const handleEdit = () => {
    setOpenEdit((prev) => !prev);
  };

  const popoverButtons = [
    { name: nameFirstButton, action: handlePinn },
    { name: "Редактировать", action: handleEdit },
    {
      name: "Удалить",
      action: handlConfirModal,
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={accessPopover ? "space-between" : "flex-end"}
        width={"100%"}
        sx={{
          zIndex: "10000",
          position: "absolute",
          top: "36px",
        }}
        px={2}
      >
        {accessPopover && (
          <EditPopover
            addOpenAction={() => contentAction?.("pause")}
            buttonsData={popoverButtons}
          />
        )}
        <IconButton
          aria-label="close"
          size="small"
          color="secondary"
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        handleModal={handlConfirModal}
        nameConfirmButton="Удалить"
        handleConfirm={handleDelete}
        text="Удалить группу историй."
      />
      <CreateModal
        openModal={openEdit}
        handleModal={handleEdit}
        isEdit={true}
        groupId={groupInfo?.id}
      />
    </>
  );
};

export default ModalStoriesButton;
