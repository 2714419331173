import React, {
  FC,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { SnackBarType } from "../../common/Snackbars/Snackbars";
import { snackbarFunction } from "../../../operation/snackbarFunction";

interface KbContextProviderProps {
  children: React.ReactNode;
}

interface KbContextValue {
  openSnackbar: boolean;
  setOpenSnackbar: Dispatch<SetStateAction<boolean>>;
  searchKbValue: string;
  setSearchKbValue: Dispatch<SetStateAction<string>>;
  snackbarMessage: string | undefined;
  setSnackbarMessage: Dispatch<SetStateAction<string | undefined>>;
  snackbarType: SnackBarType;
  setSnackbarType: Dispatch<SetStateAction<SnackBarType>>;
  handleSnackbar: (type: SnackBarType, message?: string) => void;
  totalItems: number;
  setTotalItems: Dispatch<SetStateAction<number>>;
}

let defaultValue: KbContextValue = {
  openSnackbar: false,
  setOpenSnackbar: () => {},
  searchKbValue: "",
  setSearchKbValue: () => {},
  snackbarMessage: "",
  setSnackbarMessage: () => {},
  snackbarType: "error",
  setSnackbarType: () => {},
  handleSnackbar: () => {},
  totalItems: 0,
  setTotalItems: () => {},
};
export const KbContext = createContext(defaultValue);

export const KbContextProvider: FC<KbContextProviderProps> = ({ children }) => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [searchKbValue, setSearchKbValue] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
  const [snackbarType, setSnackbarType] = useState<SnackBarType>("error");
  const [totalItems, setTotalItems] = useState<number>(0);

  const handleSnackbar = (type: SnackBarType, message?: string) => {
    snackbarFunction(
      setOpenSnackbar,
      type,
      setSnackbarType,
      message,
      setSnackbarMessage
    );
  };

  return (
    <KbContext.Provider
      value={{
        openSnackbar,
        setOpenSnackbar,
        searchKbValue,
        setSearchKbValue,
        snackbarMessage,
        setSnackbarMessage,
        snackbarType,
        setSnackbarType,
        handleSnackbar,
        totalItems,
        setTotalItems,
      }}
    >
      {children}
    </KbContext.Provider>
  );
};

export default KbContextProvider;
