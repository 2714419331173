import { SnackBarType } from "../components/common/Snackbars/Snackbars";
import { Dispatch, SetStateAction } from "react";

export const snackbarFunction = (
  setOpenSnackbar: Dispatch<SetStateAction<boolean>>,
  type: SnackBarType,
  setSnackbarType: Dispatch<SetStateAction<SnackBarType>>,
  message?: string,
  setSnackbarMessage?: Dispatch<SetStateAction<string | undefined>>
) => {
  setSnackbarType(type);
  setOpenSnackbar(true);
  if (message) {
    setSnackbarMessage?.(message);
  } else {
    setSnackbarMessage?.(undefined);
  }
};
