import React, {useEffect} from 'react';
import './linearProgressBar.css'

interface IProps {
    progress: number
    color?: string
}

const DEFAULT_COLOR = '#37BD5DFF'

const LinearProgressBar = ({progress, color = DEFAULT_COLOR}: IProps) => {
    const segmentCount = 10
    const fullSegments = Math.floor(progress / 10)
    const partialPercentage = progress % 10

    const animationName = `fillAnimationPartial${partialPercentage * 10}`;

    useEffect(() => {
        const keyframesPartial = `
            @keyframes ${animationName} {
                from { width: 0%; }
                to { width: ${partialPercentage * 10}%; }
            }`;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframesPartial;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, [animationName, partialPercentage]);

    return (
        <div className="linear-wrapper">
            {
                Array.from({length: segmentCount}, (_, index) => {
                    const isFull = index < fullSegments
                    const isPartial = index === fullSegments
                    let segmentStyle = {
                        height: '8px',
                        flex: '1',
                        backgroundColor: isFull ? color : '#CBE1FF2B',
                    };

                    return (
                        <div className={`linear-segment`} style={segmentStyle} key={index}>
                            {isPartial && (
                                <div className={`linear-segment`}
                                     style={{
                                         backgroundColor: color,
                                         animation: `${animationName} 0.5s ease-in forwards`,
                                     }}
                                />
                            )}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default LinearProgressBar;


