import { apiUrlPortal } from "../constants/apiUrl";
import axios from "axios";

export const passwordController = {
  forgot: async (email: string): Promise<any> => {
    return await axios.put(`${apiUrlPortal}/passwords/reset`, { email });
  },
  restore: async (token: string, password: string): Promise<any> => {
    return await axios.put(`${apiUrlPortal}/passwords/reset/${token}`, { password });
  },
};
