import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Image } from "mui-image";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

import logo from "../../../assets/images/logo.png";

interface LogoProp {
  withTitle?: boolean;
}

export const Logo: FC<LogoProp> = ({ withTitle }) => {
  const navigate = useNavigate();

  const handleLogo = () => {
    navigate("/feed");
  };

  return (
    <Box
      component="div"
      display="flex"
      gap={1}
      mb={withTitle ? "16px" : "0"}
      onClick={handleLogo}
      sx={{ cursor: "pointer" }}
    >
      <Image src={logo} alt="Logo" duration={0} height={40} width={52} />
      {withTitle && (
        <Typography variant="subtitle2" component="span" fontWeight={700}>
          Loyalty <br />
          Labs
        </Typography>
      )}
    </Box>
  );
};

export default Logo;
