import { regEx } from "../constants/regEx";

export const checkLetters = (word: string | undefined) => {
  if (word) {
    return (
      regEx.isCyrillic.test(word) ||
      (word ? word[0] !== word[0].toUpperCase() : false) ||
      word.length < 1
    );
  }
  return;
};
