import axios from "axios";
import { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { authController } from "./auth.controller";

const jwtInterceoptor: AxiosInstance = axios.create();

jwtInterceoptor.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const tokensData = JSON.parse(
      localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
    );
    if (tokensData?.accessToken) {
      config.headers.Authorization = `Bearer ${tokensData?.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

jwtInterceoptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error?.config;
    const authData = JSON.parse(
      localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
    );
    if (error.response.status === 403 && !config?.sent) {
      config.sent = true;
      let apiResponseRefreshToken = await authController
        .refreshToken(authData.refreshToken)
        .catch((error) => {
          if (error.response.status === 400) {
            localStorage.removeItem("REACT_TOKEN_AUTH_KEY");
            window.location.replace("/");
          }
        });
      authData.refreshToken = apiResponseRefreshToken.data.refreshToken;
      authData.accessToken = apiResponseRefreshToken.data.accessToken;

      localStorage.setItem("REACT_TOKEN_AUTH_KEY", JSON.stringify(authData));
      config.headers.Authorization = `Bearer ${apiResponseRefreshToken?.data.accessToken}`;
      return axios(config);
    }
    return Promise.reject(error);
  }
);

export default jwtInterceoptor;
