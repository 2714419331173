import React, { FC } from "react";
import Box from "@mui/material/Box";

import MyCommunities from "./MyCommunities/MyCommunities";
import ListOfCommunities from "./ListOfCommunities/ListOfCommunities";
import { MenuListItems } from "../../../../types/MenuListItems.types";
import { CommunityListView } from "../../../../models/community.models";
import CommunityAvatar from "../../../Community/CommunityAvatar/CommunityAvatar";

interface GroupsProps {
  forRemainingMenu?: boolean;
}

export const CommunityList: FC<GroupsProps> = ({ forRemainingMenu }) => {
  const communitiesItems = (comm: CommunityListView[]): MenuListItems => {
    return comm.map((item) => ({
      name: item.name,
      icon: <CommunityAvatar size="small" avatar={item.avatar} />,
      href: `/community/${item.id}`,
      id: item.id,
    }));
  };

  const onClickCommunity = (id: string) => {
    localStorage.setItem("groupId", id);
  };

  return (
    <Box>
      <MyCommunities
        forRemainingMenu={forRemainingMenu}
        communitiesItems={communitiesItems}
        onClickCommunity={onClickCommunity}
      />
      <ListOfCommunities
        forRemainingMenu={forRemainingMenu}
        communitiesItems={communitiesItems}
        onClickCommunity={onClickCommunity}
      />
    </Box>
  );
};

export default CommunityList;
