import React, { FC } from "react";

import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";

interface StepsStoryProps {
  steps: number;
  onClick: (props: any) => void;
}

export const StepsStory: FC<StepsStoryProps> = ({ steps, onClick }) => {
  const arrayStyle = { display: "flex", gap: 1, width: " 100%" };

  const stepStyle = (theme: Theme) => ({
    height: "4px",
    width: "100%",
    backgroundColor: theme.palette.text.primary,
    borderRadius: "8px",
    cursor: "pointer",
  });

  return (
    <Box sx={arrayStyle}>
      {[...Array(steps).keys()].map((item, index) => (
        <Box key={index} sx={stepStyle} onClick={() => onClick(index)} />
      ))}
    </Box>
  );
};

export default StepsStory;
