import React, { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import StoryGroupWrapper from "../StoryGroupWrapper/StoryGroupWrapper";

interface UserStoryGroupProps {
  title: string;
  backgroundUrl: any;
  onClick?: () => void;
}
export const UserStoryGroup: FC<UserStoryGroupProps> = ({
  title,
  backgroundUrl,
  onClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleContainer = {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    height: "100%",
    px: `${isMobile ? "12px" : "24px"}`,
    pb: "18px",
    backgroundImage: `linear-gradient(0deg, rgba(33, 38, 51, 0.2), rgba(33, 38, 51, 0.2)), linear-gradient(180deg, rgba(33, 38, 51, 0.15) 69.27%, #212633 100%), url("${backgroundUrl}")`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
    borderRadius: "12px",
  };
  return (
    <StoryGroupWrapper colorBorder="primary" onClick={onClick}>
      <Box sx={styleContainer}>
        <Typography
          variant={isMobile ? "caption" : "subtitle1"}
          align="center"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {title}
        </Typography>
      </Box>
    </StoryGroupWrapper>
  );
};

export default UserStoryGroup;
