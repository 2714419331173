export type UserFilter = {
  dateFrom: string;
  dateTo: string;
  department: string;
  role: string;
};

export const initialFiltersValue = {
  dateFrom: "",
  dateTo: "",
  department: "",
  role: "",
};
