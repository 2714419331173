import React, { FC, useContext } from "react";

import { Box } from "@mui/material";

import Search from "../../common/Search/Search";
import CreateKnowledgeButton from "./CreateKnowledgeButton/CreateKnowledgeButton";
import { AppContext } from "../../shared/AppContextProvider";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import {sanitizeAndTrimStart} from "../../../operation/sanitizeAndTrimStart";

export const TopPanel: FC = () => {
  const { isMobile } = useContext(AppContext);
  const { searchKbValue, setSearchKbValue } = useContext(KbContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = sanitizeAndTrimStart(value);
    setSearchKbValue(sanitizedValue);
  };

  return (
    <Box display="flex" justifyContent="space-between" mb={3} gap={2}>
      <Search
        value={searchKbValue}
        placeholder="Ключевое слово, например “Зарплата”"
        inputStyles={{ pt: 1, pb: 1 }}
        handleChange={handleChange}
      />
      {!isMobile && <CreateKnowledgeButton />}
    </Box>
  );
};

export default TopPanel;
