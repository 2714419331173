import React, { FC } from "react";

import Typography from "@mui/material/Typography";

import DialogWrapper from "../../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../../common/Buttons/ModalButtons/ModalButtons";

interface ConfirmModalProps {
  openConfirmModal: boolean;
  handleModal: () => void;
  handleConfirm: () => void;
  nameConfirmButton: string;
  text: string | JSX.Element;
  title?: string;
}
export const ConfirmModal: FC<ConfirmModalProps> = ({
  openConfirmModal,
  handleModal,
  handleConfirm,
  nameConfirmButton,
  text,
  title = "Подтвердите действие",
}) => {
  const buttons = (
    <ModalButtons
      handleCancel={handleModal}
      handleSend={handleConfirm}
      fullWidth
      nameButtonSend={nameConfirmButton}
      disableSend={false}
      justifyContent="flex-end"
    />
  );

  return (
    <DialogWrapper
      openModal={openConfirmModal}
      handleModal={handleModal}
      buttons={buttons}
      contentDividers
      width={"532px"}
    >
      <Typography mb={4} variant="h4">
        {title}
      </Typography>
      <Typography
        variant="body2"
        color={(theme) => theme.palette.text.secondary}
      >
        {text} Вы уверены?
      </Typography>
    </DialogWrapper>
  );
};

export default ConfirmModal;
