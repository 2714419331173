import React, { FC } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface DialogWrapperProp {
  openModal: boolean;
  handleModal: () => void;
  children: React.ReactNode;
  buttons?: React.ReactNode;
  width?: string;
  height?: string;
  contentDividers?: boolean;
  pActions?: string;
  styles?: any;
  stylesContent?: any;
  styleActionButtons?: any;
  header?: React.ReactNode;
}

export const DialogWrapper: FC<DialogWrapperProp> = ({
  openModal,
  handleModal,
  children,
  buttons,
  width,
  height,
  contentDividers,
  pActions,
  styles,
  stylesContent,
  header, styleActionButtons
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleDialog = {
    "& .MuiDialog-paper": { width: width, height: height },
    ...styles,
  };

  const styleContent = {
    p: isMobile ? "32px 16px" : "36px 32px",
    ...stylesContent,
  };

  const styleActions = {
    p: pActions ? pActions : isMobile ? "16px" : "16px 32px",
      ...styleActionButtons
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleModal}
      aria-labelledby="dialog"
      aria-describedby="dialog-open"
      fullScreen={isMobile}
      sx={styleDialog}
    >
      {header && <DialogTitle>{header}</DialogTitle>}
      <DialogContent sx={styleContent} dividers={contentDividers}>
        {children}
      </DialogContent>
      {buttons && <DialogActions sx={styleActions}>{buttons}</DialogActions>}
    </Dialog>
  );
};

export default DialogWrapper;
