export const declinationNoun = (
  number: number,
  wordForms: [string, string, string]
) => {
  let absoluteNumber = Math.abs(number);
  absoluteNumber %= 100;
  if (absoluteNumber >= 5 && absoluteNumber <= 20) {
    return wordForms[2];
  }
  absoluteNumber %= 10;
  if (absoluteNumber === 1) {
    return wordForms[0];
  }
  if (absoluteNumber >= 2 && absoluteNumber <= 4) {
    return wordForms[1];
  }
  return wordForms[2];
};
