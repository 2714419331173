import { apiUrlPortal } from "../constants/apiUrl";
import {
  CreateAchievementRequest,
  CreateTaskTagRequest,
  UpdateTaskRequest,
  UpdateTaskStatus,
} from "../models/achievement.models";
import jwtInterceoptor from "./jwtInterceptor";

export const achievementController = {
  //Goals
  byUser: async (
    personId: string | null,
    archived: boolean,
    page?: number,
    size?: number
  ): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/achievements/personal/${personId}?archived=${archived}&page=${
        page ?? ""
      }&size=${size ?? 5}&sort=creationDate,desc`
    );
  },
  getAchiv: async (id: string): Promise<any> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/achievements/${id}`);
  },
  delete: async (id: string): Promise<any> => {
    return await jwtInterceoptor.delete(`${apiUrlPortal}/achievements/${id}`);
  },
  clearArchive: async (personId: string): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/achievements/personal/${personId}/archived`
    );
  },
  newAchievement: async (body: CreateAchievementRequest): Promise<any> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/achievements`, body);
  },
  confirm: async (id: string): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/achievements/${id}/confirm`
    );
  },
  reassign: async (id: string): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/achievements/${id}/reassign`
    );
  },
  edit: async (id: string, body: { name: string }): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/achievements/${id}`,
      body
    );
  },
  archive: async (id: string): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/achievements/${id}/archive`
    );
  },

  //Tags
  getTags: async (achievementId: string): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/achievements/${achievementId}/tags`
    );
  },
  newTag: async (
    achievementId: string,
    body: CreateTaskTagRequest
  ): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/achievements/${achievementId}/tags`,
      body
    );
  },
  editTag: async (
    achievementId: string,
    tagId: string,
    body: CreateTaskTagRequest
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/achievements/${achievementId}/tags/${tagId}`,
      body
    );
  },
  deleteTag: async (achievementId: string, tagId: string): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/achievements/${achievementId}/tags/${tagId}`
    );
  },
  addTaskTag: async (
    achievementId: string,
    taskId: string,
    body: {
      tagIds: string[];
    }
  ): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}/tags`,
      body
    );
  },
  deleteTaskTag: async (
    achievementId: string,
    taskId: string,
    tagId: string
  ): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}/tags/${tagId}`
    );
  },

  //Tasks
  tasks: async (
    achievementId: string,
    page?: number,
    size?: number
  ): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/achievements/${achievementId}/tasks?page=${
        page ?? ""
      }&size=${size ?? 20}`
    );
  },
  createTask: async (achievementId: string, body: FormData): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/achievements/${achievementId}/tasks`,
      body
    );
  },
  getTask: async (achievementId: string, taskId: string): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}`
    );
  },
  editTask: async (
    achievementId: string,
    taskId: string,
    body: UpdateTaskRequest
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}`,
      body
    );
  },
  deleteTask: async (achievementId: string, taskId: string): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}`
    );
  },
  moveTask: async (
    achievementId: string,
    taskId: string,
    body: UpdateTaskStatus
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}/move`,
      body
    );
  },

  //Comments
  getComments: async (achievementId: string, taskId: string): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}/comments?size=20&sort=date,asc`
    );
  },
  createComment: async (
    achievementId: string,
    taskId: string,
    body: FormData
  ): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}/comments/`,
      body
    );
  },
  deleteComment: async (
    achievementId: string,
    taskId: string,
    commentId: string
  ): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/achievements/${achievementId}/tasks/${taskId}/comments/${commentId}/`
    );
  },
};
