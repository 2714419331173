import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import { personController } from "../../../services/person.controller";
import ConfirmModal from "../../common/Modals/ConfirmModal/ConfirmModal";
import { roles } from "../../../constants/role";

interface BlockUserButtonProps {
  userId: string;
}
export const BlockUserButton: FC<BlockUserButtonProps> = ({ userId }) => {
  const navigate = useNavigate();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handleModal = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const blockUser = () => {
    personController.blockPerson(userId).then(() => {
      handleModal();
      navigate(-1);
    });
  };

  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const availableButton =
    currentUser.role === roles.admin && currentUser.id !== userId;

  return (
    <>
      {availableButton && (
        <CustomButton
          text="Заблокировать"
          color="secondary"
          startIcon={<PersonRemoveIcon />}
          onClick={handleModal}
        />
      )}
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        handleModal={handleModal}
        nameConfirmButton="Заблокировать"
        handleConfirm={blockUser}
        text="Данный сотрудник будет заблокирован."
      />
    </>
  );
};

export default BlockUserButton;
