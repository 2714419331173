export const translateGender = (geder: string) => {
  switch (geder) {
    case "MALE":
      return (geder = "Муж.");
    case "FEMALE":
      return (geder = "Жен.");
    default:
      return geder;
  }
};
