import React, { FC, SetStateAction } from "react";

import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

interface DesktopPopoverProps {
  popoverButtons: React.ReactElement;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: SetStateAction<any>;
  isOpen: boolean;
  id?: string;
}

export const DesktopPopover: FC<DesktopPopoverProps> = ({
  popoverButtons,
  anchorEl,
  setAnchorEl,
  isOpen,
  id,
}) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styleCard = {
    minWidth: 189,
    height: "auto",
    backgroundColor: "#212633",
    py: "12px",
    boxShadow:
      "0px 4px 16px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset",
  };

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        sx: {
          borderRadius: "12px",
        },
      }}
    >
      <Card sx={styleCard}>
        <Stack>{popoverButtons}</Stack>
      </Card>
    </Popover>
  );
};
