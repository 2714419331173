import React, { FC } from "react";

import Box from "@mui/material/Box";
import moment from "moment/moment";

import { StoryView } from "../../../../../models/story.model";
import { monthTranslate } from "../../../../../translators/month.translators";
import StoryContent from "./StoryContent/StoryContent";
import ModalStoriesButton from "../ModalStoriesButton/ModalStoriesButton";
import { GroupInfo } from "../../Stories";
import FixedReactions from "../../../../common/Reactions/FixedReactions/FixedReactions";

interface StoryContentProps {
  story: StoryView;
  closeModal?: () => void;
  groupInfo?: GroupInfo;
  contentAction?: any;
  handleError: () => void;
}

export const StoryWrapper: FC<StoryContentProps> = ({
  story,
  groupInfo,
  closeModal,
  contentAction,
  handleError,
}) => {
  const adminStory = groupInfo?.type === "ADMIN";
  const urlImg = `url("/loyalty-portal/api/attachments/${
    encodeURIComponent(story.attachments[0]?.name) + story?.attachments[0]?.extension
  }")`;

  const bgImageStyle = {
    backgroundImage: adminStory
      ? `linear-gradient(0deg, rgba(33, 38, 51, 0.2), rgba(33, 38, 51, 0.2)), linear-gradient(180deg, rgba(33, 38, 51, 0.15) 57.52%, #212633 82.66%),${urlImg}`
      : urlImg,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundClip: "border-box",
  };

  const styleWrapper = {
    padding: adminStory ? "20px 16px 77px" : "20px 16px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    justifyContent: adminStory ? "flex-end" : "center",
    alignItems: adminStory ? "initial" : "center",
    background: "rgba(33, 38, 51, 1)",
    ...(adminStory && bgImageStyle),
  };

  const content: string =
    groupInfo?.type === "BIRTHDAY"
      ? `${moment(story.author?.dob).format("DD")} ${monthTranslate(
          moment(story.author?.dob).format("MMMM")
        )} ${moment().year()}`
      : groupInfo?.type === "WELCOME"
      ? story.author?.job?.title?.name
      : story.content;

  return (
    <>
      <ModalStoriesButton
        groupInfo={groupInfo}
        closeModal={closeModal}
        contentAction={contentAction}
      />
      <Box sx={styleWrapper}>
        <StoryContent
          author={groupInfo?.type === "ADMIN" ? undefined : story.author}
          header={story.header}
          content={content}
          groupType={groupInfo?.type}
        />
        {groupInfo?.type && groupInfo.type !== "ADMIN" && (
          <FixedReactions
            reactionId={story.reaction.id}
            type={groupInfo.type}
            handleError={handleError}
          />
        )}
      </Box>
    </>
  );
};

export default StoryWrapper;
