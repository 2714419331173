import React, { FC, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

import CommunityModal from "../../../../Community/CommunityModal/CommunityModal";

export const CreateCommunity: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleAddButton = () => {
    handleModal();
  };

  return (
    <>
      <IconButton
        size="small"
        color="secondary"
        aria-describedby="add group"
        onClick={handleAddButton}
      >
        <AddIcon fontSize="small" />
      </IconButton>
      <CommunityModal
        type="create"
        openModal={openModal}
        handleModal={handleModal}
      />
    </>
  );
};

export default CreateCommunity;
