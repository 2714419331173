import React, { FC } from "react";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

interface RemoveImageButtonProps {
  onClick?: (props?: any) => void;
  color: "primary" | "secondary";
  style?: any;
  disabled?: boolean;
}
export const RemoveImageButton: FC<RemoveImageButtonProps> = ({
  onClick,
  color,
  style,
  disabled,
}) => {
  const secondaryButton = {
    backgroundColor: "rgba(51, 146, 255, 0.25)",
    color: "rgba(24, 132, 255, 1)",
    "&:hover": {
      backgroundColor: "rgba(51, 146, 255, 0.4)",
    },
  };
  const styleAttachButton = {
    borderRadius: "180px",
    minWidth: "auto",
    padding: "8px",
    "& .MuiButton-startIcon": {
      margin: 0,
    },
    ...(color === "secondary" && secondaryButton),
    ...style,
  };

  return (
    <Button
      variant="contained"
      color={color}
      sx={styleAttachButton}
      startIcon={<DeleteIcon />}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default RemoveImageButton;