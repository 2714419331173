import React, { FC } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import IconButton from "@mui/material/IconButton";

interface PasswordProps {
  showPassword: boolean;
  handleShowPassword: () => void;
}

export const ShowPasswordAndornment: FC<PasswordProps> = ({
  showPassword,
  handleShowPassword,
}) => {
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleShowPassword}
        onMouseDown={handleMouseDownPassword}
        color="secondary"
        edge="end"
        disableRipple
      >
        {showPassword ? (
          <VisibilityOffOutlinedIcon />
        ) : (
          <VisibilityOutlinedIcon />
        )}
      </IconButton>
    </InputAdornment>
  );
};
