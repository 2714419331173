import React, { FC } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { AttachmentView } from "../../../models/attachment.model";

import Group from "../../../assets/icons/group.svg";

interface CommunityAvatarProps {
  avatar?: AttachmentView | null;
  size?: "small" | "default";
  newAvatar?: File | null;
}
export const CommunityAvatar: FC<CommunityAvatarProps> = ({
  avatar,
  size = "default",
  newAvatar,
}) => {
  const imgSrc = avatar
    ? `/loyalty-portal/api/attachments/${avatar?.name + avatar?.extension}`
    : newAvatar
    ? URL.createObjectURL(newAvatar)
    : Group;

  const imgStyle = {
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  };

  const sizeStyle =
    size === "small"
      ? {
          width: "32px",
          height: "32px",
        }
      : undefined;

  return (
    <Avatar sx={sizeStyle}>
      <Box sx={imgStyle} />
    </Avatar>
  );
};

export default React.memo(CommunityAvatar);
