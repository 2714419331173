import React, { FC, useContext } from "react";
import { useDispatch } from "react-redux";

import ConfirmModal from "../../../../common/Modals/ConfirmModal/ConfirmModal";
import { achievementController } from "../../../../../services/achievement.controller";
import { achievementsInitial } from "../../../../../actions/achievements";
import { AchievContext } from "../../../AchievContextProvider/AchievContextProvider";

interface ClearArchiveProps {
  isOpen: boolean;
  handleModal: () => void;
}
export const ClearArchive: FC<ClearArchiveProps> = ({
  isOpen,
  handleModal,
}) => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId") || "";

  const { handleSnackbar } = useContext(AchievContext);

  const handleDelete = () => {
    achievementController
      .clearArchive(userId)
      .then(() => dispatch(achievementsInitial.achievementsList([])))
      .catch(() => handleSnackbar("error"))
      .finally(() => handleModal());
  };

  return (
    <ConfirmModal
      nameConfirmButton="Очистить"
      openConfirmModal={isOpen}
      text="Все цели в архиве будут удалены."
      handleModal={handleModal}
      handleConfirm={handleDelete}
    />
  );
};

export default ClearArchive;
