import React, { FC } from "react";

import { Checkbox } from "@mui/material";

import { ReactComponent as CheckboxTask } from "../../../assets/icons/checkbox.svg";
import { ReactComponent as UncheckboxTask } from "../../../assets/icons/uncheckbox.svg";

interface CheckboxWrapperProps {
  disabled?: boolean;
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const CheckboxWrapper: FC<CheckboxWrapperProps> = ({
  disabled,
  checked,
  onChange,
}) => {
  return (
    <Checkbox
      size="small"
      onChange={onChange}
      checked={checked}
      checkedIcon={<CheckboxTask />}
      icon={<UncheckboxTask />}
      disableRipple
      disabled={disabled}
    />
  );
};

export default CheckboxWrapper;
