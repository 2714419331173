import React, { FC } from "react";

import "./Confetti.css";

export const Confetti: FC = () => {
  return (
    <div id="confettis">
      {[...Array(15).keys()].map((item, index) => (
        <div className="confetti" key={index} />
      ))}
    </div>
  );
};

export default Confetti;
