import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import { Tabs, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import { calendarInitial } from "../../../actions/calendar";
import { calendarEventCategories } from "../../../constants/calendar";

const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp(prop: string) {
    return (
      prop !== "textColor" &&
      prop !== "selectionFollowsFocus" &&
      prop !== "indicator"
    );
  },
})``;

const StyledBox = styled(Box, {
  shouldForwardProp(prop: string) {
    return (
      prop !== "textColor" &&
      prop !== "selectionFollowsFocus" &&
      prop !== "indicator" &&
      prop !== "fullWidth"
    );
  },
})``;

interface CalendarButtonsProps {
  translator?: (props?: any) => string;
  styles?: any;
}

const CalendarButtons: FC<CalendarButtonsProps> = ({
	translator,
	styles,
}) => {
	const eventsFilter: string[] = useAppSelector(
		(state) => state.calendarReducer.eventsFilter
	);
	const dispatch = useDispatch();

	const handleFilterChange = (
		event: React.MouseEvent<HTMLElement>,
		newEventsFilter: string[],
	) => {
		dispatch(
			calendarInitial.filterEvents(newEventsFilter)
		);
	};

	const theme = useTheme();

	const styleGroup = {
		gap: "8px",
		"& .MuiToggleButtonGroup-grouped": {
			border: 0,
			"&.MuiToggleButton-root": {
				borderRadius: "60px",
				backgroundColor: "rgba(203, 225, 255, 0.17)",
				fontSize: "14px",
				lineHeight: "20px",
				color: theme.palette.text.secondary,
				padding: "10px 12px",
			},
			"&.MuiToggleButton-root:hover": {
				backgroundColor: "rgba(185, 215, 255, 0.08)",
			},
			"&.Mui-selected, &.Mui-selected:hover": {
				color: theme.palette.text.primary,
				"&.calendar-event-0": {
					backgroundColor: "rgba(107, 55, 219, 1)",
				},
				"&.calendar-event-1": {
					backgroundColor: "rgba(205, 62, 62, 1)",
				},
				"&.calendar-event-2": {
					backgroundColor: "rgba(173, 67, 156, 1)",
				},
			},
			"&.MuiToggleButton-root.Mui-disabled": {
				border: 0,
			},
		},
		".MuiTabs-indicator": {
			height: "0px",
		},
		...styles,
	};

	return (
		<>
			<Tabs
				value={0}
				variant="scrollable"
				scrollButtons={false}
				sx={styleGroup}
			>
				<StyledBox px="16px">
					<ToggleButtonGroup
						value={eventsFilter}
						onChange={handleFilterChange}
						aria-label="scrollable events"
						sx={styleGroup}
					>
						{calendarEventCategories.map((item, index) => (
							<StyledToggleButton value={item} aria-label={item} key={index} className={`calendar-event-${index}`}>
								{translator ? translator(item) : item}
							</StyledToggleButton>
						))}
					</ToggleButtonGroup>
				</StyledBox>
			</Tabs>
		</>
	);
}

export default CalendarButtons;
