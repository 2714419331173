import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputBase from "@mui/material/InputBase";

import DialogWrapper from "../../../../common/Modals/DialogWrapper/DialogWrapper";
import CustomButton from "../../../../common/Buttons/CustomButton/CustomButton";
import ImageButton from "../../../../common/Buttons/ImageButton/ImageButton";
import ModalHeader from "../ModalHeader/ModalHeader";
import {
  Story,
  defaultStory,
  EditedStoryIds,
  defaultEditedStoryIds,
} from "../../../../../types/Story.types";
import { storyController } from "../../../../../services/story.controller";
import { dataInitial } from "../../../../../actions/data";
import { StoryView } from "../../../../../models/story.model";
import { LoadProgress } from "../../../../common/LoadProgress/LoadProgress";

export interface CreateModalProps {
  openModal: boolean;
  handleModal: () => void;
  setOpenSnackbar?: SetStateAction<any>;
  isEdit?: boolean;
  groupId?: string;
}

export const CreateModal: FC<CreateModalProps> = React.memo(
  ({ openModal, handleModal, setOpenSnackbar, isEdit, groupId }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

    const editStories = useAppSelector(
      (state) => state.dataReducer.groupStories
    ).map((item: StoryView) => ({
      content: item.content,
      header: item.header,
      background: item.attachments[0]?.name + item.attachments[0]?.extension,
      backgroundURL: `/loyalty-portal/api/attachments/${
        encodeURIComponent(item.attachments[0]?.name) + item.attachments[0]?.extension
      }`,
      id: item.id,
    }));

    const [stories, setStories] = useState<Story[]>(
      isEdit ? editStories : [defaultStory]
    );
    const [currentStep, setCurrentStep] = useState<number>(0);

    const [editedIds, setEditedIds] = useState<EditedStoryIds>(
      defaultEditedStoryIds
    );

    const handleContent = useCallback(
      (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        name: string
      ) => {
        setStories((prev: any) =>
          prev.map((item: Story, index: number) =>
            index === currentStep
              ? { ...item, [name]: event.target.value }
              : item
          )
        );
      },
      [currentStep]
    );

    const handleAttachmentButton = (event: any) => {
      event.target.files[0].type.startsWith("image") ? (
        setStories(
          stories.map((item: Story, index: number) =>
            index === currentStep
              ? {
                  ...item,
                  background: event.target.files[0],
                  backgroundURL: URL.createObjectURL(event.target.files[0]),
                }
              : item
          )
        )
      ) : (
        setOpenSnackbar(true)
      );
      event.target.value = "";
    };

    const handleCancel = useCallback(() => {
      handleModal();
      setCurrentStep(0);
      if (isEdit) {
        setEditedIds(defaultEditedStoryIds);
      } else {
        setStories([defaultStory]);
      }
    }, [handleModal, isEdit]);

    const createService = useCallback(() => {
      const formData = new FormData();
      formData.append(
        "request",
        new Blob(
          [
            JSON.stringify({
              pinned: "false",
              requests: stories.map((item: Story) => {
                return { content: item.content, header: item.header };
              }),
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
      stories.forEach((item: Story) => {
        formData.append("files", item.background);
      });
      setLoader(true);
      storyController
        .addStoryGroup(formData)
        .then(() =>
          storyController.allStoryGroup().then((res) => {
            dispatch(dataInitial.storyGroups(res.data.items));
            handleCancel();
            setLoader(false);
          })
        )
        .catch(() => {
          setLoader(false);
          setOpenSnackbar(true);
        });
    }, [dispatch, handleCancel, setOpenSnackbar, stories]);

    const [loader, setLoader] = useState<boolean>(false);

    const editService = useCallback(() => {
      setLoader(true);
      const deleteStories = () => (
        Promise.all(
          editedIds.delete.map(item => (
            storyController.deleteOneStory(groupId, item)
          ))
        )
      );
      deleteStories()
        .then(() => (
          Promise.all(stories.map((item: Story) => (
            storyController.updOneStory(groupId, item.id, {
              content: item.content,
              header: item.header,
            })
          )))
        ))
        .then(() => (
          Promise.all(stories.map((item: Story) => {
            if (item.id && editedIds.attachment?.includes(item.id)) {
              const formData = new FormData();
              formData.append("file", item.background);
              return storyController
                .attachmentOneStory(groupId, item.id, formData);
            }
            return null;
          }))
        ))
        .then(() => {
          storyController.storiesFromGroup(groupId)
            .then((res) => {
              dispatch(dataInitial.groupStories(res.data.items));
              handleCancel();
              setLoader(false);
            })
            .then(() => {
              storyController.allStoryGroup().then((res) => {
                dispatch(dataInitial.storyGroups(res.data.items));
              });
            });
        })
        .catch(() => {
          setLoader(false);
          setOpenSnackbar(true);
        });
    }, [
      editedIds.attachment,
      editedIds.delete,
      groupId,
      stories,
      handleCancel,
      setOpenSnackbar,
      dispatch,
    ]);

    const handleSent = useCallback(() => {
      if (isEdit) {
        editService();
      } else {
        createService();
      }
    }, [isEdit, editService, createService]);

    const disabledSent = useMemo(
      () =>
        stories.some((item: Story) => {
          return !item.content || !item.header || !item.background;
        }),
      [stories]
    );

    const modalButtons = useMemo(
      () => (
        <>
          <CustomButton
            fullWidth
            color="secondary"
            onClick={handleCancel}
            text="Отмена"
          />
          <CustomButton
            fullWidth
            color="primary"
            text="Отправить"
            disabled={disabledSent}
            onClick={handleSent}
          />
        </>
      ),
      [disabledSent, handleSent, handleCancel]
    );

    const styleModal = {
      "& .MuiDialog-paper": {
        backgroundImage:
          stories[currentStep].background && !loader
            ? `linear-gradient(0deg, rgba(33, 38, 51, 0.2), rgba(33, 38, 51, 0.2)), linear-gradient(180deg, rgba(33, 38, 51, 0.15) 57.52%, #212633 82.66%),url("${stories[currentStep].backgroundURL}")`
            : "none",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundClip: "border-box",
        width: isMobile ? "100%" : "375px",
        height: "100%",
        maxHeight: !isMobile ? "812px" : undefined,
      },
    };

    const styleContent = loader
      ? { display: "flex", justifyContent: "center", alignItems: "center" }
      : {
          display: "grid",
          gridTemplateRows: stories[currentStep].background ? "1fr" : "2fr 1fr",
          alignItems: stories[currentStep].background ? "end" : "center",
        };

    const styleAddPhoto = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
    };

    return (
      <>
        <DialogWrapper
          openModal={openModal}
          handleModal={handleCancel}
          buttons={!loader && modalButtons}
          pActions="16px"
          styles={styleModal}
          stylesContent={styleContent}
          header={
            !loader && (
              <ModalHeader
                currentStory={stories[currentStep]}
                steps={stories.length}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setStories={setStories}
                isEdit={isEdit}
                setEditedIds={setEditedIds}
              />
            )
          }
        >
          {loader ? (
            <LoadProgress />
          ) : (
            <>
              {!stories[currentStep].background && (
                <Box sx={styleAddPhoto}>
                  <ImageButton
                    padding="14px"
                    color="primary"
                    onChange={handleAttachmentButton}
                  />
                  <Typography
                    variant="body2"
                    color={(theme) => theme?.palette?.text?.secondary}
                    sx={{ textAlign: "center" }}
                  >
                    Выберите <br /> изображение
                  </Typography>
                </Box>
              )}
              <Box display="flex" flexDirection="column">
                <InputBase
                  placeholder="Ваш текст"
                  value={stories[currentStep].header}
                  sx={{ fontSize: "32px", fontWeight: 700, lineHeight: "40px" }}
                  onChange={(event) => handleContent(event, "header")}
                  multiline
                  maxRows={3}
                />
                <InputBase
                  sx={{ fontSize: "16px", lineHeight: "24px" }}
                  placeholder="Расскажите о чём-нибудь"
                  multiline
                  maxRows={6}
                  value={stories[currentStep].content}
                  onChange={(event) => handleContent(event, "content")}
                />
              </Box>
            </>
          )}
        </DialogWrapper>
      </>
    );
  }
);

export default CreateModal;
