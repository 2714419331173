import React, { FC, useContext } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { createSelector } from "@reduxjs/toolkit";

import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

import Idea from "../Idea/Idea";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import { IdeasContext } from "../IdeasContext/IdeasContextProvider";

import type { Idea as IdeaView } from "../../../types/Idea.type";

interface IdeasPageProps {
  currentPage: number;
  showLoader: boolean;
}
export const IdeasPage: FC<IdeasPageProps> = ({
  currentPage,
  showLoader,
}) => {
  const { pageSize } = useContext(IdeasContext);
  const selectIdeas = createSelector(
    [
      (state) => state.dataReducer.ideas,
      (state, currentPage, pageSize) => pageSize * currentPage,
      (state, currentPage, pageSize) => pageSize * (currentPage + 1),
    ],
    (ideas, start, end) => ideas.slice(start, end)
  );
  const ideas: IdeaView[] = useAppSelector(
    (state) => selectIdeas(state, currentPage, pageSize)
  );

  const styleItem = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    "@media (max-width: 1700px) and (min-width: 1200px), (max-width: 768px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media (max-width: 520px)": {
      gridTemplateColumns: "1fr",
    },
    gap: "12px",
    p: "0px",
  };

  return (
    <>
      {showLoader ? (
        <LoadProgress />
      ) : (
        ideas.length > 0 ? (
          <ListItem sx={styleItem}>
            {ideas.map((item) => (
              <Idea key={item.id} ideaData={item} />
            ))}
          </ListItem>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
            <TipsAndUpdatesOutlinedIcon sx={{
              width: "96px",
              height: "96px",
              color: "rgba(51, 146, 255, 0.3)",
              m: "42px 0px 0px 22px",
            }} />
            <Typography variant="body1" color="rgba(229, 235, 242, 0.65)">
              Нет идей
            </Typography>
          </Box>
        )
      )}
    </>
  );
};

export default IdeasPage;