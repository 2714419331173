import React, { FC } from "react";

import CompanyContextProvider from "./CompanyContext/CompanyContextProvider";
import CompanyContent from "./CompanyContent/CompanyContent";

export const Company: FC = () => {
  return (
    <CompanyContextProvider>
      <CompanyContent />
    </CompanyContextProvider>
  );
};

export default Company;
