import { createTheme, ThemeOptions } from "@mui/material/styles";
import darkScrollbar from "@mui/material/darkScrollbar";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `default` breakpoints
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `custom` breakpoints
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

export const theme: ThemeOptions = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#1884FF", dark: "#1884FF" },
    secondary: {
      main: "rgba(229, 235, 242, 0.65)",
      dark: "rgba(229, 235, 242, 0.65)",
      contrastText: "rgba(203, 225, 255, 0.17)",
    },
    error: {
      main: "rgba(224, 63, 72, 1)",
      dark: "rgba(224, 63, 72, 0.1)",
      contrastText: "rgba(224, 63, 72, 0.1)",
    },
    warning:{
      main: "rgba(242, 153, 74, 1)",
    },
    success:{
      main: "rgba(55, 189, 93, 1)",
    },
    text: {
      primary: "rgba(230, 237, 245, 1)",
      secondary: "rgba(229, 235, 242, 0.65)",
    },
    divider: "rgba(185, 215, 255, 0.08)",
    background: {
      default: "#131722",
      paper: "rgba(185, 215, 255, 0.08)",
    },
    action: {
      hover: "rgba(185, 215, 255, 0.08)",
      focus: "rgba(51, 146, 255, 0.25)",
      selected: "rgba(51, 146, 255, 0.25)",
    },
  },
  typography: {
    fontFamily: "'IBM Plex Sans',sans-serif",
    fontSize: 16,
    h1: {
      fontSize: 60,
      fontWeight: 600,
      lineHeight: "72px",
    },
    h2: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: "56px",
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: "40px",
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "28px",
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "24px",
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "20px",
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "20px",
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
    },
    button: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "24px",
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 769,
      laptop: 1200,
      desktop: 1920,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "rgba(33, 38, 51, 1)",
          borderRadius: "12px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: themeParam.palette.mode === "dark" ? darkScrollbar() : null,
      }),
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       "&:-internal-autofill-selected": {
    //         backgroundColor: "unset !important",
    //       },
    //     },
    //   },
    // },
  },
});
