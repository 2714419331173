import { useState, useEffect } from "react";

import { titleController } from "../services/title.controller";
import { TitleView } from "../models/profile.models";

export const useJobTitiles = (page?: number) => {
  const [titles, setTitles] = useState<string[]>([]);
  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    titleController.titles(page).then((res) => {
      const allTitles = res.data.items.map((item: TitleView) => item.name);
      setTitles([...titles, ...allTitles]);
      setSize(res.data.totalItems);
    });
    /* eslint-disable-next-line */
  }, [page]);

  return { titles, setTitles, size, setSize };
};
