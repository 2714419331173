import React, { FC } from "react";

import Button from "@mui/material/Button";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

interface ImageButtonProps {
  onChange?: (props?: any) => void;
  padding?: string;
  color: "primary" | "secondary";
  styles?: any;
}

export const ImageButton: FC<ImageButtonProps> = ({
  onChange,
  padding = "8px",
  color,
  styles,
}) => {
  const secondaryButton = {
    backgroundColor: "rgba(51, 146, 255, 0.25)",
    color: "rgba(24, 132, 255, 1)",
    "&:hover": {
      backgroundColor: "rgba(51, 146, 255, 0.4)",
    },
  };
  const styleAttachButton = {
    borderRadius: "180px",
    minWidth: "auto",
    padding: padding,
    "& .MuiButton-startIcon": {
      margin: 0,
    },
    ...(color === "secondary" && secondaryButton),
    ...styles,
  };

  return (
    <Button
      variant="contained"
      color={color}
      component="label"
      sx={styleAttachButton}
      startIcon={<InsertPhotoIcon />}
      onChange={onChange}
    >
      <input hidden accept="image/*" type="file" />
    </Button>
  );
};

export default ImageButton;
