import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'

import { Email } from '../Email/Email'
import { LoginError } from '../LoginError/LoginError'

import { authController } from '../../../services/auth.controller'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron.svg'
import LoginNewDone from './LoginNewDone/LoginNewDone'
import { textSnackbars } from '../../../constants/snackBarNotifications'

export const LoginNew: FC = () => {
  const navigate = useNavigate()
  const goBack = () => {
    navigate('..', { relative: 'path' })
  }

  const [emailValue, setEmailValue] = useState<string>('')
  const [isDone, setIsDone] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleLoginNew = () => {
    authController
      .loginNew(emailValue)
      .then(() => {
        setIsDone(true)
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setError(textSnackbars.default)
        } else {
          setError('Некорректный адрес электронной почты')
        }
        setIsLoading(false)
      })
    setIsLoading(true)
  }

  const buttonStyle = {
    typography: 'button',
    height: '48px',
    borderRadius: '60px',
    padding: '4px 24px',
  }

  const backButtonStyle = {
    transform: 'rotate(180deg)',
    svg: { transform: 'translateX(1.5px)' },
    backgroundColor: 'rgba(203, 225, 255, 0.17)',
  }

  const boxWrapperStyle = {
    maxWidth: '428px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    justifyContent: 'center',
    alignItems: 'start',
    width: '100%',
    px: '16px',
    position: 'fixed',
    top: { mobile: '32px', tablet: '96px' },
    left: '50%',
    transform: 'translateX(-50%)',
  }

  return (
    <Box sx={boxWrapperStyle}>
      {isDone ? (
        <LoginNewDone email={emailValue} />
      ) : (
        <>
          <IconButton sx={backButtonStyle} onClick={goBack}>
            <ArrowIcon />
          </IconButton>
          <Box display='flex' flexDirection='column' gap='8px'>
            <Typography variant='h4'>Вход для нового пользователя</Typography>
            <Typography variant='body2' color='secondary'>
              Для продолжения введите свой корпоративный адрес электронной почты
            </Typography>
          </Box>
          {error && <LoginError label={error} />}
          <Email value={emailValue} setValue={setEmailValue} placeholder='username@loyaltylabs.ru' />
          {isLoading ? (
            <CircularProgress color='inherit' sx={{ alignSelf: 'center' }} size='48px' />
          ) : (
            <Button variant='contained' fullWidth sx={buttonStyle} onClick={handleLoginNew}>
              Продолжить
            </Button>
          )}
        </>
      )}
    </Box>
  )
}

export default LoginNew
