import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import CommunityAvatar from "../CommunityAvatar/CommunityAvatar";
import { communityController } from "../../../services/community.controller";
import { communitiesInitial } from "../../../actions/communities";
import { CommunityView } from "../../../models/community.models";
import ConfirmModal from "../../common/Modals/ConfirmModal/ConfirmModal";
import { CommunityContext } from "../CommunityContextProvider/CommunityContextProvider";
import CommunityModal from "../CommunityModal/CommunityModal";
import MembersModal from "../MembersModal/MembersModal";
import { declinationNoun } from "../../../operation/declinationNoun";
import { BackButton } from "../../common/Buttons/BackButton/BackButton";
import { AppContext } from "../../shared/AppContextProvider";
import { memberGroupRoles, roles } from "../../../constants/role";

export type TypeMembersModal = "add" | "view" | "admin" | "owner";

type CommunityModals = {
  confirmDeleteModal: boolean;
  editModal: boolean;
  membersModal: boolean;
};

export const CommunityHeader: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSnackbar, membersTotalItems, setMembersTotalItems } =
    useContext(CommunityContext);
  const { isMobile, setTotalGroups, pageGroups } = useContext(AppContext);

  const communityId = localStorage.getItem("groupId") || "";
  const community: CommunityView = useAppSelector(
    (state) => state.communitiesReducer.community
  );

  const getComminityInfo = () => {
    communityController
      .community(communityId)
      .then((res) => dispatch(communitiesInitial.community(res.data)));

    communityController.allMembers(communityId).then((res) => {
      dispatch(communitiesInitial.members(res.data.items));
      setMembersTotalItems(res.data.totalItems);
    });
  };

  useEffect(() => {
    getComminityInfo();
    /* eslint-disable */
  }, [communityId]);
  /* eslint-enable */

  const variantMembers: [string, string, string] = [
    "участник",
    "участника",
    "участников",
  ];

  const [modals, setModals] = useState<CommunityModals>({
    confirmDeleteModal: false,
    editModal: false,
    membersModal: false,
  });

  const handleModal = (modal: keyof CommunityModals) => () => {
    setModals((prev: CommunityModals) => ({
      ...prev,
      [modal]: !prev[modal],
    }));
  };

  const updCommunityList = () => {
    communityController
      .communitiesByPerson(0, (pageGroups + 1) * 5)
      .then((res) => {
        dispatch(communitiesInitial.communitiesByPerson(res.data.items));
        setTotalGroups(res.data.totalItems);
      });
  };

  const deleteCommunity = () => {
    communityController
      .deleteCommunity(communityId)
      .then(() => {
        updCommunityList();
        handleSnackbar("success", "Группа была удалена");
        navigate("/feed");
      })
      .catch(() => handleSnackbar("error"))
      .finally(() => handleModal("confirmDeleteModal"));
  };

  const [membersModalType, setMembersModalType] =
    useState<TypeMembersModal>("add");

  const handleMembersModal = (type: TypeMembersModal) => {
    handleModal("membersModal")();
    setMembersModalType(type);
  };

  const leaveCommunity = () => {
    communityController
      .leaveCommunity(communityId)
      .then(() => {
        updCommunityList();
        getComminityInfo();
        if (community.privateCommunity) {
          navigate("/feed");
        }
      })
      .catch(() => handleSnackbar("error"));
  };

  const handleLeaveButton = () => {
    if (community.role === memberGroupRoles.owner) {
      handleMembersModal("owner");
    } else {
      leaveCommunity();
    }
  };

  const joinCommunity = () => {
    communityController
      .joinCommunity(communityId)
      .then(() => {
        updCommunityList();
        getComminityInfo();
        handleSnackbar("success", "Вы вступили в группу");
      })
      .catch(() => handleSnackbar("error"));
  };

  const backButtonStyle = { m: 0, "& .MuiIconButton-root": { mb: 0 } };

  const allPopoverButtons = {
    edit: { name: "Редактировать", action: handleModal("editModal") },
    newMembers: { name: "Пригласить", action: () => handleMembersModal("add") },
    newAdmins: {
      name: "Назначить администраторов",
      action: () => handleMembersModal("admin"),
    },
    delete: {
      name: "Удалить группу",
      action: handleModal("confirmDeleteModal"),
    },
    leaveGroup: {
      name: "Покинуть группу",
      action: handleLeaveButton,
    },
    joinGroup: {
      name: "Вступить в группу",
      action: joinCommunity,
    },
  };

  const currentUserRole = JSON.parse(
      localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).role;

  const availablePopoverButtons =
    community.role === memberGroupRoles.owner || currentUserRole === roles.admin
      ? [
          allPopoverButtons.edit,
          allPopoverButtons.newMembers,
          allPopoverButtons.newAdmins,
          allPopoverButtons.delete,
          allPopoverButtons.leaveGroup,
        ]
      : community.role === memberGroupRoles.groupAdmin
      ? [allPopoverButtons.newMembers, allPopoverButtons.leaveGroup]
      : community.role === memberGroupRoles.member
      ? [allPopoverButtons.leaveGroup]
      : [allPopoverButtons.joinGroup];

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box display="flex" gap={2} alignItems="center">
          {isMobile && <BackButton style={backButtonStyle} />}
          <CommunityAvatar
            avatar={community?.avatar?.name ? community?.avatar : undefined}
          />
          <Box>
            <Typography variant="h4">{community?.name}</Typography>
            <Typography
              variant="body1"
              color={(theme) => theme?.palette?.primary.main}
              onClick={() => handleMembersModal("view")}
              sx={{ cursor: "pointer" }}
            >
              {membersTotalItems}{" "}
              {declinationNoun(membersTotalItems, variantMembers)}
            </Typography>
          </Box>
        </Box>
        <EditPopover buttonsData={availablePopoverButtons} />
      </Box>
      {modals.confirmDeleteModal && (
        <ConfirmModal
          openConfirmModal={modals.confirmDeleteModal}
          handleModal={handleModal("confirmDeleteModal")}
          nameConfirmButton="Удалить"
          handleConfirm={deleteCommunity}
          text="Группа будет удалена."
        />
      )}
      {modals.editModal && (
        <CommunityModal
          type="edit"
          openModal={modals.editModal}
          handleModal={handleModal("editModal")}
        />
      )}
      {modals.membersModal && (
        <MembersModal
          type={membersModalType}
          openModal={modals.membersModal}
          handleModal={handleModal("membersModal")}
          currentUserRole={community.role}
          leaveCommunity={
            membersModalType === "owner" ? leaveCommunity : undefined
          }
        />
      )}
    </>
  );
};

export default CommunityHeader;
