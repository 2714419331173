import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

import MemberItem from "../MemberItem/MemberItem";

import { PersonView } from "../../../../../models/profile.models";
import { personController } from "../../../../../services/person.controller";
import ShowMoreButton from "../../../../KnowledgeBase/ShowMoreButton/ShowMoreButton";

export const OnlineMembers: FC = () => {
  const loggedUserId = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).id;

  const loggedUsers = useAppSelector(
    (state) => state.socketsReducer.loggedUsers
  );

  const [page, setPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [onlineMembers, setOnlineMembers] = useState<PersonView[]>([]);

  const getFilters = () => {
    if (loggedUsers) {
      const filter = JSON.parse(loggedUsers)
        ?.filter((item: string) => item !== loggedUserId)
        .map((user: string) => `id:${user}`)
        .join("%2Bor%2B");

      return filter;
    }
  };

  useEffect(() => {
    const userFilters = getFilters();
    if (userFilters) {
      personController.allPersons(userFilters).then((res) => {
        setOnlineMembers(res.data.items);
        setTotalItems(res.data.totalItems);
      });
    } else {
      setOnlineMembers([]);
      setTotalItems(0);
    }
    /* eslint-disable */
  }, [loggedUsers]);
  /* eslint-enable */

  const getNextOnlineMembers = () => {
    personController.allPersons(getFilters(), undefined, page).then((res) => {
      setOnlineMembers([...onlineMembers, ...res.data.items]);
      setTotalItems(res.data.totalItems);
      setPage((prev) => prev + 1);
    });
  };

  const showMore = totalItems > onlineMembers.length;

  return (
    <>
      {onlineMembers?.map((item, index) => (
        <MemberItem userInfo={item} key={index} />
      ))}
      {showMore && <ShowMoreButton onClick={getNextOnlineMembers} />}
    </>
  );
};

export default OnlineMembers;
