import {useContext, useEffect, useMemo, useState} from "react";
import {decodeBase64} from "../components/Review/utils/decodeId";
import {useAppSelector} from "./useAppSelector";
import {reviewListSelect} from "../reducer/selectors/reviewSelectors";
import {ReviewContext} from "../components/Review/RewievContextProvider/ReviewContextProvider";
import {PersonView} from "../models/profile.models";

export const useSelectedReviewers = () => {
    const reviewList = useAppSelector(reviewListSelect)
    const {reviewId} = useContext(ReviewContext);

    const { initialSelectedSkill, initialSelectedReviewers, votedReviewers  } = useMemo(() => {
        const initialSelectedReview = reviewList.find(el => el.id === reviewId)
        if (initialSelectedReview) {
            const initialSelectedSkill = initialSelectedReview.skill.name
            const initialSelectedReviewers = initialSelectedReview.reviewers.map(el => el.reviewer)

            const votedReviewers = initialSelectedReview.reviewers
                .filter(el => el.voted)
                .map(el => ({ id: decodeBase64(el.reviewer.id), isVoted: el.voted }))

            return { initialSelectedSkill, initialSelectedReviewers, votedReviewers }
        }
        return { initialSelectedSkill: '', initialSelectedReviewers: [], votedReviewers:[]}
    }, [reviewList, reviewId])

    const [selectedReviewers, setSelectedReviewers] = useState<PersonView[]>(initialSelectedReviewers)
    const [newSkill, setNewSkill] = useState(initialSelectedSkill)

    useEffect(() => {
            setSelectedReviewers(initialSelectedReviewers);
            setNewSkill(initialSelectedSkill);
    }, [initialSelectedReviewers, initialSelectedSkill])

    return {selectedReviewers, newSkill, votedReviewers, setSelectedReviewers, setNewSkill}
}