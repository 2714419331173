import React, { FC, useContext } from "react";

import { KbContext } from "../../KbContextProvider/KbContextProvider";

interface CustomImageComponentProps {
  config: any;
  onChange: (props: string) => void;
}
export const CustomImageComponent: FC<CustomImageComponentProps> = ({
  config,
  onChange,
}) => {
  const { handleSnackbar } = useContext(KbContext);

  const fileUpload = (e: any) => {
    const file = e.target.files[0];
    config
      .uploadCallback(file)
      .then((res: { data: { link: string } }) => onChange(res.data.link))
      .catch(() => {
        handleSnackbar("error");
      });
  };

  return (
    <label className="rdw-option-wrapper" title="File" role="button">
      <img src={config.icon} alt="file" />
      <input
        type="file"
        accept="image/*, .pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={fileUpload}
        hidden
      />
    </label>
  );
};

export default CustomImageComponent;
