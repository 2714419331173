import React, { FC } from "react";

import IdeasContent from "./IdeasContent/IdeasContent";
import IdeasContextProvider from "./IdeasContext/IdeasContextProvider";

export const Ideas: FC = () => {
	return (
		<IdeasContextProvider>
			<IdeasContent />
		</IdeasContextProvider>
	);
};

export default Ideas;