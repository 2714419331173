import React, { FC } from "react";

import KbContextProvider from "./KbContextProvider/KbContextProvider";
import KbContent from "./KbContent/KbContent";

export const KnowledgeBase: FC = () => {
  return (
    <KbContextProvider>
      <KbContent />
    </KbContextProvider>
  );
};

export default KnowledgeBase;
