import React, { FC } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { ReactComponent as TelegramIcon } from "../../../assets/icons/logo_telegram.svg";
import { ReactComponent as WhatsAppIcon } from "../../../assets/icons/logo_whatsapp.svg";

import { PersonView } from "../../../models/profile.models";

interface ContactsProps {
  userInfo: PersonView;
}

export const Contacts: FC<ContactsProps> = ({ userInfo }) => {
  const data = [
    {
      name: "email",
      info: userInfo?.contact?.email,
      icon: <EmailIcon color="secondary" />,
    },
    {
      name: "phone",
      info: userInfo?.contact?.phone,
      icon: <PhoneIcon color="secondary" />,
    },
    {
      name: "whatsApp",
      info: userInfo?.contact?.phone,
      icon: <WhatsAppIcon color="secondary" />,
    },
    {
      name: "telegram",
      info: userInfo?.contact?.telegram,
      icon: <TelegramIcon color="secondary" />,
    },
  ];

  const stylePaper = {
    p: 3,
    borderRadius: "12px",
    display: "flex",
    gap: "18px",
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" mb={2}>
        Контакты
      </Typography>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item mobile={12} laptop={6} key={index}>
            <Paper sx={stylePaper}>
              {item.icon}
              <Typography variant="body2">{item.info}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Contacts;
