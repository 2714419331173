import React, { FC, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DoneIcon from "@mui/icons-material/Done";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

import moment from "moment";

import { dataInitial } from "../../../actions/data";
import { NavigateButton } from "../../common/comments/NavigateButton/NavigateButton";
import { BackButton } from "../../common/Buttons/BackButton/BackButton";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import Snackbars from "../../common/Snackbars/Snackbars";
import IdeasContextProvider, { ideasInitialFetchingStatus } from "../IdeasContext/IdeasContextProvider";
import { ideasController } from "../../../services/ideas.controller";
import { reactionController } from "../../../services/reaction.controller";
import Comment, { CommentInfo } from "../../common/comments/Comment/Comment";
import { roles } from "../../../constants/role";
import IdeaContent from "./IdeaContent/IdeaContent";
import CreateIdeaComment from "./CreateIdeaComment/CreateIdeaComment";
import IdeaEditButton from "./IdeaEditButton/IdeaEditButton";
import { buttonInfo } from "../../common/Modals/EditPopover/EditPopover";
import { ReactComponent as FavoritesIcon } from "../../../assets/icons/favorites.svg";
import { ReactComponent as FavoritesIconActive } from "../../../assets/icons/favoritesActive.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { getReactionSymbolId } from "../../../operation/getReactionSymbolId";
import ShareIdeaPopover from "./ShareIdeaPopover/ShareIdeaPopover";

import type { Idea as IdeaView } from "../../../types/Idea.type";
import type { CommentView } from "../../../models/comment.models";
import type { ReactionView } from "../../../models/reaction.model";

export const IdeaComments: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const [newComment, setNewComment] = useState<string>("");

  const currentPage = useRef<number>(0);
  const totalComments = useRef<number>(0);
  const [commentsLoader, setCommentsLoader] = useState<boolean>(false);
  const [newCommentLoader, setNewCommentLoader] = useState<boolean>(false);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const onIdeaError = () => setOpenSnackbar(true);

  const idea: IdeaView = useAppSelector((state) => state.dataReducer.idea);
  const ideaData =
    idea.id === "" ? JSON.parse(sessionStorage.getItem("idea")!) : idea;
  const ideaComments: CommentView[] = useAppSelector(
    (state) => state.dataReducer.ideaComments
  );

  const ideas: IdeaView[] = useAppSelector(
    (state) => state.dataReducer.ideas
  );

  const pathId = useLocation().pathname.split("/")[2];
  const isPathNew = pathId !== ideaData?.id;
  const [ideaLoader, setIdeaLoader] = useState<boolean>(isPathNew);
  useEffect(() => {
    if (isPathNew) {
      setIdeaLoader(true);
      ideasController
        .idea(pathId)
        .then((res) => {
          const newIdea = res.data;
          reactionController
            .reactions(newIdea.reaction.id)
            .then((res) => {
              res.data.items.forEach((reaction: ReactionView) => {
                if (reaction.smile.id === getReactionSymbolId("👍")) {
                  newIdea.likesCount = reaction.clickCount;
                  newIdea.likeIsPressed = reaction.pressed;
                } else if (reaction.smile.id === getReactionSymbolId("👎")) {
                  newIdea.dislikesCount = reaction.clickCount;
                  newIdea.dislikeIsPressed = reaction.pressed;
                }
              });
              newIdea.likesCount ??= 0;
              newIdea.likeIsPressed ??= false;
              newIdea.dislikesCount ??= 0;
              newIdea.dislikeIsPressed ??= false;

              dispatch(dataInitial.idea(newIdea));
              sessionStorage.setItem("idea", JSON.stringify(newIdea));
              setIdeaLoader(false);
            })
            .catch(() => {
              onIdeaError();
            });
        })
        .catch(() => {
          onIdeaError();
        });
    } else {
      fetchIdeaComments();
      return () => {
        dispatch(dataInitial.ideaComments([]));
      };
    }
    /* eslint-disable */
  }, [isPathNew]);

  useEffect(() => {
    reactionController
      .smiles()
      .then((res) => dispatch(dataInitial.smiles(res.data.items)))
      .catch(() => {
        onIdeaError();
      });
    /* eslint-disable */
  }, []);

  const fetchIdeaComments = () => {
    setCommentsLoader(true);
    ideasController
      .ideaComments(ideaData.id, currentPage.current)
      .then((result) => {
        dispatch(
          dataInitial.ideaComments([...ideaComments, ...result.data.items])
        );
        currentPage.current++;
        totalComments.current = result.data.totalItems;
      })
      .catch(() => {
        onIdeaError();
      })
      .finally(() => {
        setCommentsLoader(false);
      });
  };

  const scrollHandler = (e: any) => {
    if (
      e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100 &&
      ideaComments.length < totalComments.current
    ) {
      fetchIdeaComments();
    }
  };

  useEffect(() => {
    document
      .getElementById("main-scroll")
      ?.addEventListener("scroll", scrollHandler);
    return () => {
      document
        .getElementById("main-scroll")
        ?.removeEventListener("scroll", scrollHandler);
    };
  }, [ideaComments]);

  const comments = ideaComments
    ?.map((item, key, array) => {
      if (item.author?.id === array[key + 1]?.author?.id) {
        return { ...item, isIdenticalUser: true };
      }
      return item;
    })
    .map((item, key, array) => {
      if (
        (array[key - 1] && !("isIdenticalUser" in array[key - 1])) ||
        key === 0
      ) {
        return { ...item, lastIdenticalMessage: true };
      }
      return item;
    });

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttach = async (event: any) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    event.target.value = "";
  };

  const handleCancel = () => {
    setNewComment("");
    setSelectedFiles([]);
  };

  const handleSend = () => {
    setNewCommentLoader(true);
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: newComment,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    selectedFiles.forEach((image) => {
      formData.append("files", image);
    });
    ideasController
      .newIdeaComment(ideaData.id, formData)
      .then(() => {
        ideasController.ideaComments(ideaData.id, 0).then((result) => {
          dispatch(dataInitial.ideaComments(result.data.items));
        });
        handleCancel();
      })
      .catch(() => onIdeaError())
      .finally(() => setNewCommentLoader(false));
  };

  const handleNavigateBack = () => {
    if (ideasInitialFetchingStatus.value === "page")
      ideasInitialFetchingStatus.value = "none";
  };

  const [editCommentId, setEditCommentId] = useState<string>("");
  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const updateComments = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        ideasController.ideaComments(ideaData.id, 0).then((result) => {
          dispatch(dataInitial.ideaComments(result.data.items));
        });
        resolve();
      }, 500);
    });
  };

  const deleteMessage = (id: string) => {
    ideasController
      .deleteIdeaComment(ideaData.id, id)
      .then(() => {
        updateComments();
      })
      .catch(() => onIdeaError());
  };

  const deleteFiles = useAppSelector(
    (state) => state.manageDataReducer.deleteFiles
  );

  const editMessage =
    (comment: CommentInfo) =>
    (newContent: string, closeModal: () => void, files?: any[]) => {
      ideasController
        .editIdeaComment(ideaData.id, comment.id, { content: newContent })
        .then(() => {
          const deleteAttach = () => {
            if (deleteFiles.length > 0) {
              return ideasController.deleteIdeaCommentAttachments(
                ideaData.id,
                comment.id,
                {
                  attachmentsIds: deleteFiles,
                }
              );
            }
          };
          const addAttach = () => {
            const newFiles = files?.filter(
              (item) => item.webkitRelativePath === "" //take only new files
            );
            if (newFiles && newFiles.length > 0) {
              const formData = new FormData();
              [...newFiles].forEach((file) => {
                formData.append("files", file);
              });

              return ideasController.addIdeaCommentAttachments(
                ideaData.id,
                comment.id,
                formData
              );
            }
          };
          Promise.all([deleteAttach(), addAttach()])
            .then(() => {
              updateComments();
              closeModal();
            })
            .catch(() => onIdeaError());
        })
        .catch(() => onIdeaError());
    };

  const commentButtons = (
    id: string,
    authorId: string
  ): buttonInfo[] | undefined => {
    if (currentUser.id === authorId) {
      return [
        {
          name: "Редактировать",
          action: () => setEditCommentId(id),
        },
        {
          name: "Удалить",
          action: () => deleteMessage(id),
        },
      ];
    }
    if (currentUser.role === roles.admin) {
      return [{
        name: "Удалить",
        action: () => deleteMessage(id),
      }];
    }
  };

  const favoritesButtonClickHandler = () => {
    const newIdea = {
      ...ideaData,
      favorite: !ideaData.favorite,
    };
    dispatch(dataInitial.idea(newIdea));
    sessionStorage.setItem("idea", JSON.stringify(newIdea));
    const newIdeas = structuredClone(ideas);
    const favoriteIdeaIndex = newIdeas.findIndex(idea => idea.id === ideaData.id);
    favoriteIdeaIndex !== -1 && (
      newIdeas[favoriteIdeaIndex] = newIdea,
      dispatch(dataInitial.ideas(newIdeas))
    );
    newIdea.favorite ? (
      ideasController
        .markIdea(ideaData.id)
    ) : (
      ideasController
        .unmarkIdea(ideaData.id)
    ).catch(() => {
      onIdeaError();
    });
  };

  const buttonStyle = {
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(203, 225, 255, 0.17)",
  };

  const ideaStatusMarkStyle = {
    display: "inline-flex",
    height: "32px",
    padding: "10px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: "0",
    borderRadius: "64px",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "rgba(19, 23, 34, 1)",
    ml: "16px",
    mt: "11px",
    mb: "-8px",
  };

  return (
    <IdeasContextProvider>
      {ideaLoader ? (
        <LoadProgress />
      ) : (
        <Box>
          <Box
            ml={isMobile ? undefined : "16px"}
            display="flex"
            justifyContent="space-between"
          >
            <BackButton
              authorName={
                ideaData?.author?.name &&
                "Автор: " + ideaData.author.name + " " + ideaData.author.surname
              }
              date={
                ideaData?.date &&
                "Опубликована: " + moment(ideaData.date).format("DD MMMM YYYY")
              }
              showTextOnDesktop={true}
              handleNavigateBack={handleNavigateBack}
              iconStyle={buttonStyle}
            />
            <IdeaEditButton ideaData={ideaData} />
          </Box>
          <Box display="flex" ml="16px" gap="8px" mt={isMobile ? "16px" : "8px"} mb="8px">
            <IconButton onClick={favoritesButtonClickHandler}
              sx={{ ...buttonStyle, color: ideaData.favorite ? "rgba(24, 132, 255, 1)" : "rgba(229, 235, 242, 0.65)" }}
            >
              {ideaData.favorite ? <FavoritesIconActive /> : <FavoritesIcon />}
            </IconButton>
            <ShareIdeaPopover
              ideaData={ideaData}
              button={
                <IconButton sx={buttonStyle}>
                  <ShareIcon />
                </IconButton>
              }
            />
          </Box>
          {ideaData.status === "APPROVED" ? (
            <Box sx={{ ...ideaStatusMarkStyle, background: "rgba(55, 189, 93, 1)" }}>
              <DoneIcon sx={{ width: "24px", height: "24px" }} />
              Принята
            </Box>
          ) : ideaData.status === "DISMISSED" ? (
            <Box sx={{ ...ideaStatusMarkStyle, background: "rgba(224, 63, 72, 1)" }}>
              <NotInterestedIcon sx={{ width: "24px", height: "24px" }} />
              Отклонена
              </Box>
          ) : null}
          <IdeaContent ideaData={ideaData} />
          <CreateIdeaComment
            textMessage={newComment}
            setTextMessage={setNewComment}
            handleSend={handleSend}
            handleAttach={handleAttach}
            handleCancel={handleCancel}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
          {newCommentLoader && <LoadProgress />}
          <Box sx={{ px: 2 }}>
            {comments.map((item, index, array) => (
              <Comment
                key={index}
                commentData={item}
                anchorId={
                  index === array.length - 1 ? "back-to-bottom-anchor" : undefined
                }
                handleError={onIdeaError}
                editButtons={commentButtons(item.id, item.author.id)}
                idForEdit={editCommentId}
                setIdForEdit={setEditCommentId}
                handleEdit={editMessage(item)}
              />
            ))}
          </Box>
          {commentsLoader && <LoadProgress />}
          {!isMobile && <NavigateButton />}
        </Box>
      )}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
        message="С Идеей что-то не так, свяжитесь с администратором"
      />
    </IdeasContextProvider>
  );
};

export default IdeaComments;
