import React, { FC } from "react";

import { IconButton, IconButtonProps } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  onClick: () => void;
}

export const ExpandMore: FC<ExpandMoreProps> = ({ expand, onClick }) => {
  const theme = useTheme();

  const style = {
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  };

  return (
    <IconButton onClick={onClick} sx={style} size="small" color="secondary">
      <ExpandMoreIcon />
    </IconButton>
  );
};

export default ExpandMore;
