import { COMMUNITY } from "../constants/actions";

export const communitiesInitial = {
  communitiesByPerson: (payload: any) => {
    return { type: COMMUNITY.PERSON_COMMUNITIES, payload };
  },
  messages: (payload: any) => {
    return { type: COMMUNITY.MESSAGES, payload };
  },
  community: (payload: any) => {
    return { type: COMMUNITY.COMMUNITY, payload };
  },
  members: (payload: any) => {
    return { type: COMMUNITY.MEMBERS, payload };
  },
  communitiesList: (payload: any) => {
    return { type: COMMUNITY.COMMUNITIES_LIST, payload };
  },
};
