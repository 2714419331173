import React, { FC, useContext } from "react";

import Box from "@mui/material/Box";
import CommunityAvatar from "../../CommunityAvatar/CommunityAvatar";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import { AttachmentView } from "../../../../models/attachment.model";
import { CommunityContext } from "../../CommunityContextProvider/CommunityContextProvider";

interface AvatarSelectionProps {
  avatar?: AttachmentView | null;
}
export const AvatarSelection: FC<AvatarSelectionProps> = ({ avatar }) => {
  const { newAvatar, setNewAvatar } = useContext(CommunityContext);

  const handleNewAvatarButton = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setNewAvatar(file || null);
  };

  return (
    <Box display="flex" gap={2}>
      <CommunityAvatar
        avatar={newAvatar ? undefined : avatar}
        newAvatar={newAvatar}
      />
      <CustomButton
        color="secondary"
        text="Заменить"
        attachButton
        onChange={handleNewAvatarButton}
        acceptFile="image/*"
      />
    </Box>
  );
};

export default AvatarSelection;
