import React, { FC, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Box from "@mui/material/Box";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import EditPopover from "../../../common/Modals/EditPopover/EditPopover";
import ConfirmModal from "../../../common/Modals/ConfirmModal/ConfirmModal";
import CreateEditIdea from "../../CreateEditIdea/CreateEditIdea";
import { roles } from "../../../../constants/role";
import { AppContext } from "../../../shared/AppContextProvider";
import {
  IdeasContext,
  ideasInitialFetchingStatus,
} from "../../IdeasContext/IdeasContextProvider";
import { ideasController } from "../../../../services/ideas.controller";
import { dataInitial } from "../../../../actions/data";

import type { Idea as IdeaView } from "../../../../types/Idea.type";

interface IdeaEditButtonProps {
  ideaData: IdeaView;
}
export const IdeaEditButton: FC<IdeaEditButtonProps> = ({ ideaData }) => {
  const dispatch = useDispatch();
  const ideas: IdeaView[] = useAppSelector((state) => state.dataReducer.ideas);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const { user } = useContext(AppContext);

  const isIdeaEditButtonShown =
    user.role !== roles.user ||
    JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_KEY") ?? "{}").id ===
      ideaData.author?.id;

  const {
    pageSize,
    currentPage,
    saveIdeasSessionData,
    totalPages,
    totalItems,
    onIdeasError: handleError,
  } = useContext(IdeasContext);

  const navigate = useNavigate();

  const handleEditOpen = () => {
    setIsEditOpen(true);
  };
  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  const handleConfirmModal = () => {
    setIsConfirmOpen((prev) => !prev);
  };

  const closeConfirmModal = () => {
    setIsConfirmOpen(false);
  };

  const handleDelete = () => {
    ideasController
      .deleteIdea(ideaData.id)
      .then(() => {
        const newIdeas = structuredClone(ideas);
        const ideaIndex = newIdeas.findIndex((idea) => idea.id === ideaData.id);
        newIdeas.splice(ideaIndex, 1);
        const newPage =
          ideas.length % pageSize === 1 &&
          ideaIndex === ideas.length - 1 &&
          newIdeas.length !== 0
            ? currentPage - 1
            : currentPage;
        totalItems.current % pageSize === 1 && totalPages.current--;
        saveIdeasSessionData({
          currentPage: newPage,
          totalPages: totalPages.current,
        });
        ideasInitialFetchingStatus.value = "single";
        dispatch(dataInitial.ideas(newIdeas));
        closeConfirmModal();
      })
      .then(() => {
        navigate("/ideas");
      })
      .catch(() => {
        handleError();
        closeConfirmModal();
      });
  };

  const popoverButtons = [
    {
      name: "Редактировать",
      action: handleEditOpen,
      disabled: ideaData.status !== "NEW",
    },
    { name: "Удалить", action: handleConfirmModal },
  ];

  return (
    <>
      {isIdeaEditButtonShown && (
        <Box mr="16px" display="flex" alignItems="start">
          <EditPopover
            buttonsData={popoverButtons}
            customButton={
              <IconButton color="secondary">
                <MoreVertIcon sx={{ width: "24px", height: "24px" }} />
              </IconButton>
            }
          />
          <CreateEditIdea
            ideaData={ideaData}
            isEditOpen={isEditOpen}
            handleEditClose={handleEditClose}
          />
          <ConfirmModal
            openConfirmModal={isConfirmOpen}
            handleModal={handleConfirmModal}
            nameConfirmButton="Удалить"
            handleConfirm={handleDelete}
            text="Данная идея будет удалена."
          />
        </Box>
      )}
    </>
  );
};

export default IdeaEditButton;
