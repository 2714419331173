import React, { FC } from "react";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import UserAvatar from "../../../../../common/User/UserAvatar/UserAvatar";
import { PersonView } from "../../../../../../models/profile.models";
import gifts from "../../../../../../assets/images/gifts.png";
import celebrate from "../../../../../../assets/images/celebrate.png";
import { GroupStoriesType } from "../../../../../../models/story.model";
import Confetti from "../../../../../common/Confetti/Confetti";

interface StoryContentProps {
  author?: PersonView;
  header: string;
  content: string;
  groupType?: GroupStoriesType;
}

export const StoryContent: FC<StoryContentProps> = ({
  author,
  header,
  content,
  groupType,
}) => {
  const styleImgGifts = {
    backgroundImage: `url(${gifts})`,
    width: "100%",
    height: "200px",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    bottom: 0,
  };
  const styleImgCelebrate = {
    backgroundImage: `url(${celebrate})`,
    width: "100%",
    height: "30vh",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0,
  };
  const styleImgCelebrateAdd = {
    transform: "scale(-1, 1)",
    right: "-27px",
  };

  return (
    <>
      {author && (
        <UserAvatar
          userInfo={author}
          styles={{ width: "70px", height: "70px" }}
        />
      )}
      <Typography
        variant="h3"
        color={(theme) => theme?.palette?.text?.primary}
        align={author ? "center" : undefined}
        sx={{ overflowWrap: "break-word" }}
      >
        {header}
      </Typography>
      <Typography
        variant="body2"
        color={(theme) => theme?.palette?.text?.primary}
        sx={{ overflowWrap: "break-word" }}
      >
        {content}
      </Typography>
      {groupType === "BIRTHDAY" && <Box sx={styleImgGifts} />}
      {groupType === "WELCOME" && (
        <>
          <Box sx={styleImgCelebrate} />
          <Box
            sx={{
              ...styleImgCelebrate,
              ...styleImgCelebrateAdd,
            }}
          />
        </>
      )}
      {groupType !== "ADMIN" && <Confetti />}
    </>
  );
};

export default StoryContent;
