import React, {
  FC,
  useState,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import { reactionController } from "../../../services/reaction.controller";
import { getReactionSymbolId } from "../../../operation/getReactionSymbolId";
import { IdeasContext } from "../IdeasContext/IdeasContextProvider";
import { dataInitial } from "../../../actions/data";

import type { Idea as IdeaView } from "../../../types/Idea.type";
import type { ReactionView } from "../../../models/reaction.model";

interface IdeaLikesProps {
  ideaData: IdeaView;
}
export const IdeaLikes: FC<IdeaLikesProps> = ({
  ideaData,
}) => {
  const [isLiked, setIsLiked] = useState<boolean>(ideaData.likeIsPressed);
  const [likesCount, setLikesCount] = useState<number>(ideaData.likesCount);
  const [isDisliked, setIsDisliked] = useState<boolean>(ideaData.dislikeIsPressed);
  const [dislikesCount, setDisikesCount] = useState<number>(ideaData.dislikesCount);

  const dispatch = useDispatch();
  const ideas: IdeaView[] = useAppSelector(
    (state) => state.dataReducer.ideas
  );
  
  const { onIdeasError: handleError } = useContext(IdeasContext);

  const debounceTime = 500;
  const timeoutId = useRef<NodeJS.Timeout | undefined>();

  const handleReaction = (
    targetSymbol: "👍" | "👎",
    oppositeSymbol: "👍" | "👎",
    targetIsPressed: boolean,
    oppositeIsPressed: boolean,
    setTargetPressed: Dispatch<SetStateAction<boolean>>,
    setOppositePressed: Dispatch<SetStateAction<boolean>>,
    setTargetCount: Dispatch<SetStateAction<number>>,
    setOppositeCount: Dispatch<SetStateAction<number>>,
    targetIsPressedProp: "likeIsPressed" | "dislikeIsPressed",
    oppositeIsPressedProp: "likeIsPressed" | "dislikeIsPressed",
    targetCountProp: "likesCount" | "dislikesCount",
    oppositeCountProp: "likesCount" | "dislikesCount",
  ) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setTargetPressed(!targetIsPressed);
    clearTimeout(timeoutId.current);
    const ideaIndex = ideas.findIndex(idea => idea.id === ideaData.id);
    const newIdeas: any = ideaIndex === -1 ? { [-1]: structuredClone(ideaData) } : structuredClone(ideas);
    if (targetIsPressed) {
      setTargetCount(prevTargetCount => prevTargetCount - 1);
      newIdeas[ideaIndex][targetIsPressedProp] = false;
      newIdeas[ideaIndex][targetCountProp]--;
      timeoutId.current = setTimeout(() => {
        reactionController
          .reactions(ideaData.reaction.id)
          .then(res => {
            const deleteTargetPromise = res.data.items.some((reaction: ReactionView) => (
              reaction.smile.id === getReactionSymbolId(targetSymbol) && reaction.pressed
            )) && (
              reactionController
                .deleteReaction(ideaData.reaction.id, getReactionSymbolId(targetSymbol))
            );
            const deleteOppositePromise = res.data.items.some((reaction: ReactionView) => (
              reaction.smile.id === getReactionSymbolId(oppositeSymbol) && reaction.pressed
            )) && (
              reactionController
                .deleteReaction(ideaData.reaction.id, getReactionSymbolId(oppositeSymbol))
            );
            Promise.all([deleteTargetPromise, deleteOppositePromise])
              .catch(() => {
                handleError();
              });
          })
          .catch(() => {
            handleError();
          });
      }, debounceTime);
    } else {
      setTargetCount(prevTargetCount => prevTargetCount + 1);
      newIdeas[ideaIndex][targetIsPressedProp] = true;
      newIdeas[ideaIndex][targetCountProp]++;
      if (oppositeIsPressed) {
        setOppositePressed(false);
        setOppositeCount(prevOppositeCount => prevOppositeCount - 1);
        newIdeas[ideaIndex][oppositeIsPressedProp] = false;
        newIdeas[ideaIndex][oppositeCountProp]--;
      }
      timeoutId.current = setTimeout(() => {
        reactionController
          .reactions(ideaData.reaction.id)
          .then(res => {
            const setTargetPromise = !res.data.items.find((reaction: ReactionView) => (
              reaction.smile.id === getReactionSymbolId(targetSymbol)
            ))?.pressed && (
              reactionController
                .setReaction(ideaData.reaction.id, getReactionSymbolId(targetSymbol))
            );
            const deleteOppositePromise = res.data.items.some((reaction: ReactionView) => (
              reaction.smile.id === getReactionSymbolId(oppositeSymbol) && reaction.pressed
            )) && (
              reactionController
                .deleteReaction(ideaData.reaction.id, getReactionSymbolId(oppositeSymbol))
            );
            Promise.all([setTargetPromise, deleteOppositePromise])
              .catch(() => {
                handleError();
              });
          })
          .catch(() => {
            handleError();
          });
      }, debounceTime);
    }
    ideaIndex !== -1 && dispatch(dataInitial.ideas(newIdeas));
    sessionStorage.setItem("idea", JSON.stringify(newIdeas[ideaIndex]));
  };

  const handleLike = handleReaction(
    "👍",
    "👎",
    isLiked,
    isDisliked,
    setIsLiked,
    setIsDisliked,
    setLikesCount,
    setDisikesCount,
    "likeIsPressed",
    "dislikeIsPressed",
    "likesCount",
    "dislikesCount",
  );
  const handleDislike = handleReaction(
    "👎",
    "👍",
    isDisliked,
    isLiked,
    setIsDisliked,
    setIsLiked,
    setDisikesCount,
    setLikesCount,
    "dislikeIsPressed",
    "likeIsPressed",
    "dislikesCount",
    "likesCount",
  );

  const reactionButtonStyle = {
    backgroundColor: "rgba(203, 225, 255, 0.17)",
    color: "rgba(229, 235, 242, 0.65)",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "rgba(24, 132, 255, 1)",
    },
    width: "82px",
    height: "32px",
    borderRadius: "60px",
    border: "none",
    padding: "0px",
    gap: "8px",
  };

  const buttonTextStyle = {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    minWidth: "20px",
  };

  return (
    <>
      <ToggleButton
        sx={reactionButtonStyle}
        onChange={handleLike}
        value="like"
        selected={isLiked}
      >
        {"👍"}
        <Typography {...buttonTextStyle}>{likesCount}</Typography>
      </ToggleButton>
      <ToggleButton
        sx={reactionButtonStyle}
        onChange={handleDislike}
        value="dislike"
        selected={isDisliked}
      >
        {"👎"}
        <Typography {...buttonTextStyle}>{dislikesCount}</Typography>
      </ToggleButton>
    </>
  );
};
export default IdeaLikes;