import React, { FC, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { determineDate } from "../../../../../operation/determineDate";
import CustomButton from "../../../../common/Buttons/CustomButton/CustomButton";
import { ReactComponent as NotificationsIcon } from "../../../../../assets/icons/notifications.svg";
import { dataInitial } from "../../../../../actions/data";
import { notificationsController } from "../../../../../services/notifications.controller";

import type { NotificationView } from "../../../../../models/notifications.model";

export const NotificationsTab: FC = () => {
  const navigate = useNavigate();
  const handleShowAll = () => {
    navigate("/notifications");
  };

  const dispatch = useDispatch();
  const notifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.notifications
  );
  const liveNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.liveNotifications
  );
  const seenNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.seenNotifications
  );

  const notificationsBoxStyle = {
    "& > *": {
      padding: "12px 16px",
    },
  };

  const notificationBoxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    borderRadius: "8px",
    ":hover": {
      backgroundColor: "rgba(185, 215, 255, 0.08)",
      cursor: "pointer",
    },
    ":active": {
      backgroundColor: "rgba(51, 146, 255, 0.25)",
    },
    color: "rgba(230, 237, 245, 1)",
    textDecoration: "none",
  };

  const notificationMarkStyle = {
    width: "8px",
    height: "8px",
    backgroundColor: "rgba(24, 132, 255, 1)",
    borderRadius: "4px",
    m: "6px",
  };

  const notificationContentStyle = {
    mr: "30px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    wordBreak: "break-word",
  };

  const createNotificationsList = (notificationsList: NotificationView[], seen?: boolean) => (
    notificationsList.length > 0 ? (
      notificationsList.slice(0, 3).map((notification) => {
        const notificationBoxProps = {
          sx: notificationBoxStyle,
          component: NavLink,
          to: `${notification.link}`,
          ...!seen && { onClick: () => {
            notificationsController.markNotification(notification.id);
            dispatch(dataInitial.liveNotifications(
              liveNotifications.filter(liveNotification => liveNotification.id !== notification.id)
            ));
            dispatch(dataInitial.seenNotifications(
              [{ ...notification, seen: true }, ...seenNotifications]
            ));
            dispatch(dataInitial.notifications(
              notifications.filter(notificationItem => notificationItem.id !== notification.id)
            ));
          }},
        };
        return (
          <Box {...notificationBoxProps} key={notification.id}>
            <Typography variant="body2" sx={notificationContentStyle}>{notification.content}</Typography>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" color="rgba(229, 235, 242, 0.65)">{determineDate(notification.date)}</Typography>
              {!notification.seen && <Box sx={notificationMarkStyle} />}
            </Box>
          </Box>
        );
      })
    ) : (
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <NotificationsIcon />
        <Typography variant="body1" color="rgba(229, 235, 242, 0.65)">
          {seen ? "Нет уведомлений в архиве" : "Нет новых уведомлений"}
        </Typography>
      </Box>
    )
  );

  const liveNotificationsList = useMemo(() => {
    return createNotificationsList(liveNotifications);
    // eslint-disable-next-line
  }, [liveNotifications, seenNotifications, notifications]);

  const seenNotificationsList = useMemo(() => {
    return createNotificationsList(seenNotifications, true);
    // eslint-disable-next-line
  }, [seenNotifications]);

  return (
    <Box sx={notificationsBoxStyle}>
      <Typography variant="body1" color="rgba(229, 235, 242, 0.4)" height="40px" m="-16px -16px 0px">Непрочитанные</Typography>
      {liveNotificationsList}
      <Typography variant="body1" color="rgba(229, 235, 242, 0.4)" height="40px" mx="-16px">Прочитанные</Typography>
      {seenNotificationsList}
      <CustomButton onClick={handleShowAll} color="secondary" text="Все уведомления" styles={{ width: "100%", mt: "8px" }} />
    </Box>
  );
};

export default NotificationsTab;