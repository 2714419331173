import { useEffect, useState } from 'react'

export function useDebounce(value: string | number) {
    const DELAY = 500
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, DELAY)

        return () => {
            clearTimeout(handler)
        }
    }, [value])

    return debouncedValue
}