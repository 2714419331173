export const textSnackbars = {
  default: 'Что-то пошло не так, свяжитесь с администратором',
  changePassword: 'Пароль успешно изменен',
  blockUser: 'Пользователь заблокирован',
  unblockUser: 'Пользователь разблокирован',
  addNewUser: (email: string) => {
    return `Приглашение отправлено на ${email}`
  },
  forgotPassword: 'Проверьте почту и следуйте инструкциям',
  review: {
    create: 'Ревью успешно создано',
    edit: 'Ревью успешно изменено',
    delete: 'Ревью успешно удалено',
  },
}
