import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import UserAvatar from "../../../../../common/User/UserAvatar/UserAvatar";
import TextMessageInput from "../../../../../common/comments/TextMessageInput/TextMessageInput";
import { AchievContext } from "../../../../AchievContextProvider/AchievContextProvider";
import { achievementController } from "../../../../../../services/achievement.controller";
import { achievementsInitial } from "../../../../../../actions/achievements";
import { useAppSelector } from "../../../../../../hooks/useAppSelector";

interface CreateCommentProps {
  taskId: string;
}

export const CreateComment: FC<CreateCommentProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const { handleSnackbar } = useContext(AchievContext);
  const achievementId = sessionStorage.getItem("achievementId") || "";
  const currentUser = useAppSelector((state) => state.profileReducer.profile);

  const [comment, setComment] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = async (event: any) => {
    setSelectedFiles([...event.target.files]);
    event.target.value = "";
  };

  const clearData = () => {
    setComment("");
    setSelectedFiles([]);
  };

  const postComment = (func?: () => void) => {
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: comment,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    [...selectedFiles].forEach((image) => {
      formData.append("files", image);
    });
    achievementController
      .createComment(achievementId, taskId, formData)
      .then(() => {
        achievementController
          .getComments(achievementId, taskId)
          .then((result) => {
            dispatch(
              achievementsInitial.achievementComments(result.data.items)
            );
          });
        clearData();
        func?.();
      })
      .catch(() => handleSnackbar("error"));
  };

  return (
    <Box display="flex" gap={2}>
      <UserAvatar userInfo={currentUser} />
      <TextMessageInput
        textMessage={comment}
        setTextMessage={setComment}
        handleSent={postComment}
        handleAttach={(e) => handleAttachmentButton(e)}
        files={selectedFiles}
        setFiles={setSelectedFiles}
        fullWidth
      />
    </Box>
  );
};

export default CreateComment;
