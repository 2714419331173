import {AnyAction, combineReducers} from "redux";
import {configureStore, ThunkAction} from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { profileReducer } from "../reducer/profiles";
import { dataReducer } from "../reducer/data";
import { socketsReducer } from "../reducer/sockets";
import { calendarReducer } from "../reducer/calendar";
import { communitiesReducer } from "../reducer/communities";
import { manageDataReducer } from "../reducer/manageData";
import { achievementsReducer } from "../reducer/achievements";
import {reviewReducer} from "../reducer/review";

const rootReducer = combineReducers({
  profileReducer,
  dataReducer,
  socketsReducer,
  calendarReducer,
  communitiesReducer,
  manageDataReducer,
  achievementsReducer,
  reviewReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});

export type AppState = ReturnType<typeof rootReducer>;

export type ReturnActionsTypes<T> = T extends {
  [key: string]: (...args: any) => infer U;
}
  ? U
  : never;


export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AnyAction>