import React, { FC, useContext } from "react";

import { Box } from "@mui/material";
import AchievCard from "../../AchievCard/AchievCard";
import { AchievementView } from "../../../../models/achievement.models";
import ShowMoreButton from "../../../KnowledgeBase/ShowMoreButton/ShowMoreButton";
import { AchievContext } from "../../AchievContextProvider/AchievContextProvider";

interface ListWrapperProps {
  data: AchievementView[];
}

export const ListWrapper: FC<ListWrapperProps> = ({ data }) => {
  const { listSize, setListPage } = useContext(AchievContext);
  const showMore = listSize > data.length;
  const getMoreAchievs = () => {
    setListPage((prev) => prev + 1);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      className="ListWrapper"
    >
      {data.map((item: AchievementView, index: number) => (
        <AchievCard achivementData={item} key={index} />
      ))}
      {showMore && <ShowMoreButton onClick={getMoreAchievs} />}
    </Box>
  );
};

export default ListWrapper;
