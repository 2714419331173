import React, { FC, useContext } from "react";

import { Box, Typography } from "@mui/material";
import { TaskTagView } from "../../../../../models/achievement.models";
import CloseButton from "../../../CloseButton/CloseButton";
import { AchievContext } from "../../../AchievContextProvider/AchievContextProvider";

interface SelectedTagProps {
  tag: TaskTagView;
  handleDelete?: (item: TaskTagView) => void;
}

export const SelectedTag: FC<SelectedTagProps> = ({ tag, handleDelete }) => {
  const { userRole } = useContext(AchievContext);
  const styleBox = {
    width: "100%",
    backgroundColor: tag.color,
    borderRadius: "8px",
    p: "4px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Box sx={styleBox}>
      <Typography sx={{ wordBreak: "break-all" }}>{tag.name}</Typography>
      {handleDelete && !userRole && (
        <CloseButton handleClose={() => handleDelete(tag)} />
      )}
    </Box>
  );
};

export default SelectedTag;
