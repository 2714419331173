import React, { FC } from "react";

import { FormControlLabel } from "@mui/material";

import MemberItem from "../../layout/RightSidebar/Members/MemberItem/MemberItem";
import { PersonView } from "../../../models/profile.models";
import { PersonCommunityView } from "../../../models/community.models";
import CheckboxWrapper from "../CheckboxWrapper/CheckboxWrapper";
import { MemberItemSize } from "../../../types/UserInfo.types";

interface CheckboxMemberProps {
  userInfo: PersonView | PersonCommunityView;
  disabled?: boolean;
  checked: boolean;
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  memberInfoSize?: MemberItemSize;
}

export const CheckboxMember: FC<CheckboxMemberProps> = ({
  userInfo,
  disabled,
  checked,
  handleCheckbox,
  memberInfoSize,
}) => {
  return (
    <FormControlLabel
      control={
        <CheckboxWrapper
          onChange={handleCheckbox}
          checked={checked}
          disabled={disabled}
        />
      }
      label={
        <MemberItem
          withPopover={false}
          userInfo={userInfo}
          size={memberInfoSize}
        />
      }
    />
  );
};

export default CheckboxMember;
