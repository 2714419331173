import React, { FC } from "react";

import { TextFieldProps } from "@mui/material";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

interface InputLineProps {
  value?: any;
  endAdornment?: React.ReactNode;
  placeholder?: string;
  multiline?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  styles?: any;
  type?: string;
  error?: boolean;
  helperText?: string;
  id?: string;
  onBlur?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
}
//TODO: required field
export const InputLine: FC<InputLineProps & TextFieldProps> = ({
  value,
  endAdornment,
  placeholder,
  multiline = false,
  onChange,
  inputRef,
  onClick,
  inputProps,
  styles,
  type = "text",
  error,
  helperText,
  id,
  onBlur,
  disabled = false,
  autoFocus = false,
}) => {
  const styleInput = {
    "&:before": {
      borderBottom: "1px solid rgba(241, 247, 255, 0.12)",
    },
    "&:hover:not(.Mui-disabled):before, &:after": {
      borderBottom: "1px solid rgba(229, 235, 242, 0.65)",
    },
    "&:focus-within:before, &:after": {
      borderBottom: "1px solid rgba(24, 132, 255, 1)",
    },
    "&.Mui-error:after": {
      borderBottom: "1px solid rgba(224, 63, 72, 1)",
    },
    fontSize: "16px",
    lineHeight: "24px",
    color: error ? "rgba(224, 63, 72, 1)" : undefined,
    ...styles,
  };

  const helperStyle = {
    mx: 0,
    fontSize: "16px",
    lineHeight: "20px",
    "&.Mui-error": {
      color: "rgba(224, 63, 72, 1)",
    },
  };

  return (
    <FormControl fullWidth>
      <Input
        multiline={multiline}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        endAdornment={endAdornment}
        sx={styleInput}
        inputRef={inputRef}
        onClick={onClick}
        inputProps={inputProps}
        type={type}
        error={error}
        id={id}
        onBlur={onBlur}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {error && (
        <FormHelperText id={id} error={error} sx={helperStyle}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputLine;
