import { apiUrlPortal } from "../constants/apiUrl";
import { UpdateKbArticleRequest } from "../models/kb.models";
import jwtInterceoptor from "./jwtInterceptor";

export const kbController = {
  //section
  sections: async (page?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/kb?page=${page ?? ""}&sort=name,asc`
    );
  },
  oneSection: async (id: string): Promise<any> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/kb/${id}`);
  },
  search: async (searchPhrase: string, page?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/kb/sections/search?searchPhrase=${
        searchPhrase ?? ""
      }&page=${page ?? ""}&sort=name,asc`
    );
  },
  createSection: async (body: { name: string }): Promise<any> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/kb`, body);
  },
  deleteSection: async (sectionId: string): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/kb/${sectionId}`
    );
  },
  editSection: async (
    sectionId: string,
    body: { name: string }
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/kb/${sectionId}`,
      body
    );
  },

  //article
  articles: async (sectionId: string, page?: number): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles?page=${
        page ?? ""
      }&sort=name,asc`
    );
  },
  oneArticle: async (articleId: string, sectionId: string): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}`
    );
  },
  createArticle: async (sectionId: string, body: any): Promise<any> => {
    return await jwtInterceoptor.post(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles`,
      body
    );
  },
  editArticle: async (
    sectionId: string,
    articleId: string,
    body?: UpdateKbArticleRequest
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}`,
      body
    );
  },
  attachmentsArticle: async (
    sectionId: string,
    articleId: string,
    body?: any
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}/attachments`,
      body
    );
  },
  deleteArticle: async (articleId: string, sectionId: string): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}`
    );
  },
  moveArticle: async (
    sectionId: string,
    articleId: string,
    body: {
      kbSectionId: string;
    }
  ): Promise<any> => {
    return await jwtInterceoptor.put(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}/move`,
      body
    );
  },
};
