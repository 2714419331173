import { DATA } from "../constants/actions";

export const dataInitial = {
  feed: (payload: any) => {
    return { type: DATA.DATA_FEED, payload };
  },
  feedItem: (payload: any) => {
    return { type: DATA.DATA_FEED_ITEM, payload };
  },
  feedComments: (payload: any) => {
    return { type: DATA.DATA_FEED_COMMENTS, payload };
  },
  storyGroups: (payload: any) => {
    return { type: DATA.DATA_STORY_GROUPS, payload };
  },
  groupStories: (payload: any) => {
    return { type: DATA.DATA_GROUP_STORIES, payload };
  },
  articleSections: (payload: any) => {
    return { type: DATA.DATA_ARTICLE_SECTIONS, payload };
  },
  article: (payload: any) => {
    return { type: DATA.DATA_ARTICLE, payload };
  },
  ideas: (payload: any) => {
    return { type: DATA.DATA_IDEAS, payload };
  },
  idea: (payload: any) => {
    return { type: DATA.DATA_IDEA, payload };
  },
  ideaComments: (payload: any) => {
    return { type: DATA.DATA_IDEA_COMMENTS, payload };
  },
  smiles: (payload: any) => {
    return { type: DATA.DATA_SMILES, payload };
  },
  notifications: (payload: any) => {
    return { type: DATA.DATA_NOTIFICATIONS, payload };
  },
  liveNotifications: (payload: any) => {
    return { type: DATA.DATA_LIVE_NOTIFICATIONS, payload };
  },
  seenNotifications: (payload: any) => {
    return { type: DATA.DATA_SEEN_NOTIFICATIONS, payload };
  },
};
