import React, {useEffect} from 'react';
import InputWrapper from "../../../common/Inputs/InputWrapper/InputWrapper";
import CustomSelect from "../../../common/Inputs/CustomSelect/CustomSelect";
import {Skill} from "../../../../models/review.models";

interface IProps {
    skillList: Skill[]
    currentGrade: string
    setCurrentGrade: (grade: string) => void;
}

const GradeSelect = ({skillList, setCurrentGrade, currentGrade}: IProps) => {

    useEffect(()=>{
            setCurrentGrade(skillList[0].name)
    }, [skillList])

    return (
        <InputWrapper
            titleColor="secondary"
            flexDirection="column"
            title="Навык"
            element={
                <CustomSelect items={skillList.map(el => el.name)}
                              onChange={(event) => {
                                  setCurrentGrade(event.target.value);
                              }}
                              value={currentGrade ? currentGrade : skillList[0].name}
                />
            }
        />
    )
}

export default GradeSelect;